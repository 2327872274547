import {
    boundaryAttr,
    laneAttr,
    laneGropAttr,
    stopLineAttr,
    roadMarkAttr,
    connectivityAreaAttr,
    trafficLightAttr,
    trafficSignAttr,
    poleAttr,
    obstacleAttr,
} from "./layerAttr"
import { comMenusS, comMenusE } from "./HeaderToolConfig"
export const layers: any = [
    {
        title: 'boundary',
        key: 'boundary_1001',
        type: 1001,
        readOnly: false,
        minZoom: 16,
        relation: ['lane_group','lane'], // 手动可关联
        attribute: boundaryAttr,
        menus: [...comMenusS, 'LineString', 'Curve', 'Arc', 'ModifyPoint', 'Splitter', 'Merge', 'CopyFeature', 'Offset', 'Flip', ...comMenusE],
    },
    {
        title: 'lane_group',
        key: 'lane_grop_1008',
        type: 1008,
        readOnly: false,
        minZoom: 16,
        relation: ['boundary','lane','road_mark','stop_line'],
        attribute: laneGropAttr,
        menus: [...comMenusS, ...comMenusE],
    },
    {
        title: 'lane',
        key: 'lane_1002',
        type: 1002,
        readOnly: false,
        minZoom: 16,
        relation: ['lane','lane_group','road_mark','traffic_light','traffic_sign', 'stop_line'],
        attribute: laneAttr,
        menus: [...comMenusS, 'LineString', 'Curve', 'Arc', 'ModifyPoint', 'Splitter', 'Merge', 'CopyFeature', 'Offset' , 'Flip' , ...comMenusE],
    },
    {
        title: 'stop_line',
        key: 'stop_line_1004',
        type: 1004,
        readOnly: false,
        minZoom: 16,
        relation: ['lane_group', 'lane','connectivity_area'],
        attribute: stopLineAttr,
        menus: [...comMenusS, 'Polygon', 'Rectangle', 'ModifyPolyPoint', 'CopyFeature' , 'Sorption', ...comMenusE],
    },
    {
        title: 'road_mark',
        key: 'road_mark_1005',
        type: 1005,
        readOnly: false,
        minZoom: 16,
        relation: ['lane_group', 'lane', 'connectivity_area', 'traffic_light'],
        attribute: roadMarkAttr,
        menus: [...comMenusS, 'Polygon', 'Rectangle', 'ModifyPolyPoint', 'CopyFeature', 'Sorption', ...comMenusE],
    },
    {
        title: 'connectivity_area',
        key: 'connectivity_area_1003',
        type: 1003,
        readOnly: false,
        minZoom: 16,
        relation: ['lane_group', 'lane', 'stop_line','road_mark','traffic_light','traffic_sign'],
        attribute: connectivityAreaAttr,
        menus: [...comMenusS, 'Polygon', 'Rectangle', 'ModifyPolyPoint', 'CopyFeature' , 'Sorption', ...comMenusE],
    },
    {
        title: 'traffic_light',
        key: 'traffic_light_1006',
        type: 1006,
        readOnly: false,
        minZoom: 16,
        relation: ['lane', 'connectivity_area', 'road_mark'],
        attribute: trafficLightAttr,
        menus: [...comMenusS, 'LightGroup','ModifyPoint', ...comMenusE],
    },
    {
        title: 'traffic_sign',
        key: 'traffic_sign_1007',
        type: 1007,
        readOnly: false,
        minZoom: 16,
        relation: ['lane','connectivity_area','road_mark'],
        attribute: trafficSignAttr,
        menus: [...comMenusS, 'ModifyPoint', ...comMenusE],
    },
    {
        title: 'pole',
        key: 'pole_1011',
        type: 1011,
        readOnly: false,
        minZoom: 16,
        relation: ['lane','connectivity_area'],
        attribute: poleAttr,
        menus: [...comMenusS, 'ModifyPoint', ...comMenusE],
    },
    {
        title: 'obstacle',
        key: 'obstacle_1010',
        type: 1010,
        readOnly: false,
        minZoom: 16,
        relation: ['lane','connectivity_area'],
        attribute: obstacleAttr,
        menus: [...comMenusS, 'Polygon', 'Rectangle', 'ModifyPolyPoint', 'CopyFeature' , 'Sorption',  ...comMenusE],
    }
]

// 空中要素
export const layersAerial: number[]  = [1006, 1007, 1011]

function changeMap(keyName: string): any {
    let returnMap = [];
    for (const item of layers) {
        returnMap[item[keyName]] = item;
    }
    return returnMap
}

function getLayersType() {
    let data: any = {};
    layers.forEach((item: any) => {
        data[item.title] = item.type
    })
    return data;
}


function getLayersTitle() {
    let data: any = {};
    layers.forEach((item: any) => {
        data[item.type] = item.title
    })
    return data;
}



export const layerTitleMap: any = changeMap("title")
export const layerTypeMap: any = changeMap("type")


/**
 * @returns
 * {
 *   'boundary': 1001,
 *   'lane_group': 1008
 *    ....
 * }
 */
export const layersType: any = getLayersType();


/**
 * @returns
 * {
 *   1001: 'boundary',
 *   1008: 'lane_group',
 *   ....
 * }
 */
export const layersTitle: any = getLayersTitle();


/**
 * @returns 1001
 */
export function getLayerType(val: string) {
    return layersType[val];
}

/**
 * @returns 'boundary'
 */
export function getLayerTitle(val: string) {
    return layersTitle[val];
}

