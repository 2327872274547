import {
    lineColorMap,
    lineTypeMap,
    isolationMap,
    objectTypeMap,
    laneTypeMap,
    situationType,
    boundaryTypeMap,
    stopLineType,
    connectivityAreaType,
    roadMarkType,
    directionalArrowContent,
    speedHumpContent,
    lightTurnType,
    trafficSignType, obstacleType, poleType
} from "@/config/commonAttr"
import { checkMultiSelect } from "@/utils/validates";

export const boundaryAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "",
        type: "input",
        readOnly: true,
        isHide: false,
        rules:[]
    },
    {
        name: "边界类型",
        key: "boundaryType",
        default: "0",
        type: "select",
        enums: boundaryTypeMap,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "车道线颜色",
        key: "color",
        default: "none",
        type: "select",
        enums: lineColorMap,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "边线类型",
        key: "lineType",
        default: "virtual",
        type: "select",
        enums: lineTypeMap,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "物理隔离带类型",
        key: "isolation",
        default: "none",
        type: "select",
        enums: isolationMap,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "左侧-车道类型",
        key: "laneType",
        default: "virtual",
        type: "select",
        enums: laneTypeMap,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "左侧-车道允许行驶对象",
        key: "objectType",
        default: 'all_motor_vehicle',
        type: "multiSelect",
        enums: objectTypeMap,
        readOnly: false,
        isHide: false,
        rules: [
            {
                validator: checkMultiSelect,
                required: true,
                message: '请选择左侧-车道允许行驶对象',
            }
        ]

    }
]
export const laneAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "中心线生成类型",
        key: "type",
        default: "mark",
        type: "radio", // input
        enums: ['generate', 'mark'], // 中心线类型 由boundary算出来的中心线："generate"，标注的路口中心线:"mark"
        readOnly: true,
        isHide: true,
        rules: []
    },
    {
        name: "车道类型",
        key: "laneType",
        default: "virtual",
        type: "select",
        enums: laneTypeMap,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "车道允许行驶对象",
        key: "objectType",
        default: "all_motor_vehicle",
        type: "multiSelect",
        enums: objectTypeMap,
        readOnly: false,
        isHide: false,
        rules: [
            {
                validator: checkMultiSelect,
                required: true,
                message: '请选择左侧-车道允许行驶对象',
            }
        ]
    },
    {
        name: "左侧车道边界",
        key: "leftBoundaryId",
        default: "",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: [{
            required: true,
            message: '请选择左侧车道边界',
        }]
    },
    {
        name: "右侧车道边界",
        key: "rightBoundaryId",
        default: "",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: [{
            required: true,
            message: '请选择右侧车道边界',
            trigger: 'change'
        }]
    }
]
export const laneGropAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "道路类型",
        key: "situationType",
        default: "town",
        type: "select",
        enums: situationType,
        readOnly: false,
        isHide: false,
        rules: [
            {
                required: true,
                message: '请选择道路类型',
            }
        ]
    }
]
export const stopLineAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "停止线类型",
        key: "lineType",
        default: "solid",
        type: "select",
        enums: stopLineType,
        readOnly: false,
        isHide: false,
        rules: []
    }
]

export const roadMarkAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "地面标识类型",
        key: "type",
        default: "directional_arrow",
        type: "select",
        enums: roadMarkType,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "转向箭头类型",
        key: "content",
        default: "straight_through",
        type: "multiSelect",
        enums: directionalArrowContent,
        readOnly: false,
        isHide: true,
        rules: [{
            validator: checkMultiSelect,
            required: true,
            message: '请选择转向箭头类型',
        }],
        relationSelect: "directional_arrow",
    },
    {
        name: "减速带类型",
        key: "content",
        default: "protrusion",
        type: "select",
        enums: speedHumpContent,
        readOnly: false,
        isHide: true,
        rules: [],
        relationSelect: "speed_hump",
    },
    {
        name: "限速值",
        key: "content",
        default: 0,
        type: "input",
        enums: null,
        readOnly: false,
        isHide: true,
        rules: [],
        relationSelect: "maximum_speed_limit_sign",
    },
    {
        name: "限速值",
        key: "content",
        default: 0,
        type: "input",
        enums: null,
        readOnly: false,
        isHide: true,
        rules: [],
        relationSelect: "minimum_speed_limit_sign",
    },
    {
        name: "距离值",
        key: "content",
        default: 0,
        type: "input",
        enums: null,
        readOnly: false,
        isHide: true,
        rules: [],
        relationSelect: "keep_safe_distance_sign",
    }
]

export const connectivityAreaAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "联通区域类型",
        key: "type",
        default: "intersection",
        type: "select",
        enums: connectivityAreaType,
        readOnly: false,
        isHide: false,
        rules: []
    }
]

export const trafficLightAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "信号灯类型",
        key: "content",
        default: "forward",
        type: "select",
        enums: lightTurnType,
        readOnly: false,
        isHide: false,
        rules: []
    }
]

export const trafficSignAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "标志牌类型",
        key: "type",
        default: "general_caution_sign",
        type: "select",
        enums: trafficSignType,
        readOnly: false,
        isHide: false,
        rules: []
    },
    {
        name: "数值",
        key: "num",
        default: 0,
        type: "input",
        enums: null,
        readOnly: false,
        isHide: false,
        rules: []
    }
]



export const obstacleAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "障碍物类型",
        key: "type",
        default: "ground_traffic_light",
        type: "select",
        enums: obstacleType,
        readOnly: false,
        isHide: false,
        rules: []
    }
]


export const poleAttr: object[] = [
    {
        name: "z分层",
        key: "zlevel",
        default: "0",
        type: "input",
        readOnly: true,
        isHide: false,
        rules: []
    },
    {
        name: "杆类型",
        key: "type",
        default: "gantry",
        type: "select",
        enums: poleType,
        readOnly: false,
        isHide: false,
        rules: []
    }
]
