
import { Feature } from "ol";
import { Vue, Prop, Emit, Component } from "vue-property-decorator";
import layerDefaultAttr from "@/config/layerDefaultAttr";
import { layerTypeMap } from "@/config/layerConfig";
import { FormModel } from "ant-design-vue";

@Component
export default class FeatureModalComponent extends Vue {
    @Prop() zLevelVal?: any;

    visible: boolean = false;
    featureProperties: any[] = [];
    confirmLoading: boolean = false

    properties: any = null;
    feature: any = null;
    layerType: any = null;
    layerDefaultAttr: any = null;

    showModal(feature: Feature<any>, layer: any) {
        this.layerType = layer.type;
        this.feature = feature;

        this.$nextTick(() => {
            this.properties = layerDefaultAttr[layer.title];

            this.featureProperties = layerTypeMap[this.layerType].attribute || [];
            if (this.featureProperties.length)  {
                this.visible = true;
                if (this.layerType == 1005) {
                    this.selectChange('type', this.properties['type'])
                }
            }
        })
    }

    selectChange(key: any, value: any) {
        if (key == 'type') {
            this.properties['content'] = ""
            this.featureProperties.forEach((item: any) => {
                if (item.key == 'content') {
                    item.isHide = true
                    if (item.relationSelect == value) {
                        item.isHide = false
                        this.properties['content'] = item.default
                    }
                }
            })
        }
    }

    rewriteProperties() {
        if (this.layerType == 1005) {
            if (this.properties['content'] == 'undefined' || this.properties['content'] == undefined) {
                this.properties['content'] = ""
            }
        }
    }

    handleOk() {
        if (!this.featureProperties.length) {
            return;
        }

        this.rewriteProperties();
        
        (this.$refs.formRef as FormModel).validate((boolean: any, object: any) => {
            if (boolean || (this.layerType == 1005 && this.properties['type'] !== 'directional_arrow')) {
                this.properties.layerType = this.layerType;
                this.properties.zlevel = this.zLevelVal;
                this.feature.setProperties(this.properties);
                this.feature = null;
                this.visible = false;
                this.$emit('drawActiveChange')
            } else {
                return false;
            }
        })
    }

    @Emit("cancel")
    handleCancel() {
        this.visible = false;
        this.$emit('drawActiveChange');
        return this.feature;
    }
}
