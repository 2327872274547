
import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
@Component
export default class ToggleImgButton extends Vue {
    @Prop() icon?: any;
    @Prop() title?: any;
    @Prop() active?: any;
    @Prop() defaultChecked?: any;

    public checked: any = false;
    isShowDsc: boolean = false;
    mounted() {
        this.checked = this.defaultChecked
        this.isShowDsc = this.checked;
    }

    @Watch("defaultChecked")
    defaultCheckedChange(val: boolean) {
        this.checked = val;
    }

    // 鼠标悬停离开
    mouseOverLeave(type: String) {
        if (this.checked || type == "over") {
            this.isShowDsc = true;
        }
        if (type == "leave" && !this.checked) {
            this.isShowDsc = false;
        }
    }

    @Emit("change")
    handler() {
        this.checked = !this.checked;
        this.isShowDsc = this.checked;
        return this.checked;
    }
}
