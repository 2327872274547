import { Feature, MapBrowserEvent, Map as OlMap, View } from "ol";
import { XYZ, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, VectorImage, Vector as VectorLayer } from "ol/layer";
import { defaults as InteractionDefatults, Select as SelectInt } from "ol/interaction"
import { fromLonLat } from "ol/proj";
import { GeoJSON } from "ol/format";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import {Point, Polygon} from "ol/geom";
import { containsCoordinate } from "ol/extent";
import { linear } from "ol/easing";
import {
    apiPosList,
    featureList,
    generateCenterLine,
    generateLaneGroupApi,
    getHeightSingle,
    generateApi,
    generateGlobalApi, getReousrceId
} from "@/config/api";
import { isEmpty } from "@/utils/util";
import {center, feature, polygon as poltgonTurf} from '@turf/turf'

import {readFeatures, transform3857To4326, transform4326To3857} from "@/common/utils/projetion"
import setMapDefeultLayer from "@/common/utils/MapDeafaultLayers"


// 点云图层使用
import { Tile_layers_name } from "@/views/menus/modules/point-cloud-tile"

// 双键缓存
import DoubleKeyMaps from "@/common/utils/DoubleKeyMaps"

import { Style} from "ol/style"
// 数据
import StyleBuilder from "@/common/extend/StyleBuilder";

// interaction
import Draw from "@/common/Interactions/Draw";
import Snap from "ol/interaction/Snap";
import Select from "@/common/Interactions/Select";

import DragBoxSelect from "@/common/Interactions/DragSelect";
import SelectVertices from "@/common/Interactions/SelectVertices";
import UndoRedo from "@/common/Interactions/UndoRedo";

// 修改顶点
import Modify from "@/common/Interactions/Modify";
import ModifyPoint from "@/common/Interactions/ModifyPoint";
import ModifyPoints from "@/common/Interactions/ModifyPoints";

import Splitter from '@/common/Interactions/Splitter';
import SnapGuides from 'ol-ext/interaction/SnapGuides';
import CopyPaste from '@/common/Interactions/CopyPaste';

// 测距
import MeasureLine from '@/common/Interactions/MeasureLine';

import gcoord from "gcoord";

import { posStyle, selectVect } from '@/common/layer/layer-style-data/posStyle'
import { setState, state } from "@/store/index";


import { readFeature, writeFeatureObject } from "@/common/utils/projetion";
import { layers, layerTypeMap } from '@/config/layerConfig'
import {message} from "@/config/message";
import {unByKey} from "ol/Observable";

// 公共对象定义
interface IComObject {
    [name : string] : any
}

class MapEvent {
    private readonly observers: Map<string, Function[]> = new Map<string, Function[]>();

    on(topic: string, callback: Function) {
        let callbacks = this.observers.get(topic);
        if (!callbacks) {
            callbacks = []
        }
        callbacks.push(callback);
        this.observers.set(topic, callbacks)
    }

    remove(topic: string, callback: Function) {
        let callbacks = this.observers && this.observers.get(topic);
        if (callbacks) {
            const index = callbacks.indexOf(callback);
            if (index > -1) {
                callbacks.splice(index, 1);
                if (callbacks.length == 0) {
                    this.observers.delete(topic)
                }
            }
        }
    }

    removeAllListener(topic: string) {
        let callbacks = this.observers && this.observers.get(topic);
        if (callbacks) {
            this.observers.delete(topic)
        }
    }

    trigger(topic: string, ...args: any[]) {
        let callbacks = this.observers.get(topic) || []
        callbacks.forEach(callback => callback.call(this, ...args))
    }
}

export default class MapServices extends MapEvent {
    options: any;
    olMap: any = OlMap;

    currentSource!: VectorSource<any>;
    sourceMap: any = new Map();
    layerKeys: string[] = [];
    mapZoomLevel: any = null;
    posLayer: any = null;
    isShowPop: Boolean = false;
    selectedPos: any
    vectorLayers: any[] = []
    selectMapEvt: any = null
    heightLightFeature: any = null
    tagSource: any = null
    listenEvent: any = null

    // 确认点云显示还是隐藏
    visiblePointCloudLevels: number[] = [];
    // 双键缓存
    cacheFeatures: DoubleKeyMaps<string, number, Feature<any>> = new DoubleKeyMaps<string, number, Feature<any>>()

    // Interaction 使用
    public drawTool?: Draw;
    public pointTool?: Draw;
    public snapTool?: Snap;
    public selectTool?: Select;
    public dragBoxTool?: DragBoxSelect;
    public selectVerticesTool?: SelectVertices;
    public undoTool?: UndoRedo;
    // 修改顶点

    // 修改顶点
    public modifyTool?: Modify;
    public modifyPointTool?: ModifyPoint;
    public modifyPointsTool?: ModifyPoints;

    // 分割
    public splitterTool?: Splitter;
    // 辅助线
    public snapGuidesTool?: SnapGuides;
    // 复制
    public copyTool?: CopyPaste;

    // 测距使用
    public measureLineTool?: MeasureLine;
    public measureVector?: VectorLayer<any>;
    public measureDraw?: Draw;
    public tileLayerNames: any = []


    //记录高亮前数据，便于清理高亮后恢复
    public featureHeightBeforeData : IComObject  = {
        layerKey : "",
        layerZindex : 0,
        feature : null,
        featureStyle : null
    };


    constructor(options: any) {
        super();
        this.options = options || {};

        // 初始化地图
        this.mapinitiaize();
    }

    // 获取地图图层
    get mapDefaultLayers() {
        return [setMapDefeultLayer("OSM"), setMapDefeultLayer("Aerial"), setMapDefeultLayer("TileGrid")];
    }

    // 初始化
    mapinitiaize() {
        let defaultCenter = [108.83113485066667, 34.20564811933333]

        this.olMap = new OlMap({
            target: this.options.target,
            layers: this.mapDefaultLayers,
            keyboardEventTarget: document,
            view: new View({
                center: fromLonLat(defaultCenter),
                zoom: 16,
                maxZoom: 28
            }),
            interactions: InteractionDefatults({ doubleClickZoom: false }),
        });
        this.mapMoveEndEvent();
        this.tileLayerNames = Tile_layers_name(state.zlevel);
    }

    // 地图缩放监控
    mapMoveEndEvent() {
        this.olMap.on('click', (e: MapBrowserEvent<any>) => {
            const localCoordinate = e.coordinate
            this.trigger("positionChanged", localCoordinate)
        })

        this.olMap.on(['pointermove', 'wheel'], (e: MapBrowserEvent<any>) => {
            this.mapZoomLevel = parseInt(this.olMap.getView().getZoom());
            this.trigger("zoomLevelChanged", this.mapZoomLevel)
        })

        this.olMap.on('contextmenu', (evt: any) => {
            evt.preventDefault();
            if (this.measureDraw) {
                this.measureDraw.abortDrawing();
                this.measureLineTool?.clearOverlays();
            }
            if (this.snapGuidesTool) {
                this.snapGuidesTool.clearGuides();
            }
        })
    }

    // 添加点云图层
    addPointCloudLayer() {
        this.tileLayerNames.forEach((item: string, index: any) => {
            let layer = this.setAddPointCloudSource(item, index);
            this.olMap.addLayer(layer)
        })
    }
    setAddPointCloudSource(name: string, level: number) {
        let layer = new TileLayer({
            source: new XYZ({
                tileUrlFunction: (zxy) => {
                    let [z, x, y] = zxy
                    if (isEmpty(state) || isEmpty(state.tilesList[level])) return
                    let tiles: String[] = state.tilesList[level]
                    if (!tiles.includes(x + "_" + y)) return
                    return this.getTileUrl(x, y, z, level)
                },
                minZoom: 22,
                maxZoom: 22
            }),
            properties: {
                name: name
            },
            zIndex: level,
            minZoom: 19
        })
        return layer
    }

    getTileUrl(x: number, y: number, z: number, level: number) {
        return `/${getReousrceId()}/tile/${level}/${z}/${x}/${y}.png`
    }

    // 要素图层 - 初始化
    appendVectorLayers() {
        for (const layer of layers) {
            const [source, vectorLayer] = this.initLayerForLoader(layer)
            this.sourceMap.set(layer.key, source);
            this.vectorLayers.push(vectorLayer)
            this.olMap.addLayer(vectorLayer)
        }
    }

    transforomExtent(extent: number[]) {
        let add1 = gcoord.transform([extent[0], extent[1]], gcoord.EPSG3857,
            gcoord.EPSG4326)
        let add2 = gcoord.transform([extent[2], extent[3]], gcoord.EPSG3857,
            gcoord.EPSG4326)
        return {
            "minX": add1[0],
            "minY": add1[1],
            "maxX": add2[0],
            "maxY": add2[1]
        }
    }

    // 读写source 和 layer
    initLayerForLoader(layerItem: any) {
        const source = new VectorSource({
            format: new GeoJSON(),
            loader: async (extent) => {
                const box = this.transforomExtent(extent)
                let data: any = await featureList(state.taskId, layerItem.type, box);
                if (isEmpty(data)) return;
                const featureCollection = JSON.parse(data);

                if (featureCollection.features) {
                    this.undoTool?.setActive(false);
                    const features = readFeatures(featureCollection);
                    const Source = this.sourceMap.get(layerItem.key);
                    this.addFeatureToSource(Source, features, layerTypeMap[layerItem.type]);
                    this.undoTool?.setActive(true);
                }
            },
            strategy: bboxStrategy,
        })
        const vectorLayer = new VectorImage({
            source,
            className: 'vector-layer',
            properties: { name: layerItem.key, type: layerItem.type },
            minZoom: 14,
            style: f => StyleBuilder.DefaultStyle(f, this.olMap.getView().getZoom(), this.olMap),
            zIndex: 10
        })

        source.set('readOnly', layerItem.readOnly)
        source.set('layerType', layerItem.type)
        source.set('workLayer', true)
        return [source, vectorLayer]
    }



    // 显示和隐藏图层
    setLayerVisible(layerKeys: string[]) {
        this.layerKeys = layerKeys;
        // 设置点云图层
        this.setTilLayerVisible(layerKeys);

        this.olMap.getLayers().forEach((layer: any) => {
            const layerName = layer.get('name');
            if (layerName && layerName != 'tag') {
                const isIncludes = this.layerKeys.includes(layerName)
                layer.setVisible(isIncludes)
            }
        });
    }

    // 点云图层
    setTilLayerVisible(layeItems: string[]) {
        this.tileLayerNames.forEach((tileName: string) => {
            let zlevel: number = parseInt(tileName.split(' ')[1]);
            if (layeItems.includes(tileName)) {
                if (!this.visiblePointCloudLevels.includes(zlevel)) {
                    this.visiblePointCloudLevels.push(zlevel)
                    this.showFeaturesForPointCloud(zlevel)
                }
            } else {
                const index: number = this.visiblePointCloudLevels.indexOf(zlevel)
                if (index > -1) {
                    this.visiblePointCloudLevels.splice(index, 1)
                    this.hideFeaturesForPointCloud(zlevel)
                }
            }

        })
        if (this.visiblePointCloudLevels.length > 0) {
            const maxZLevel = Math.max(...this.visiblePointCloudLevels);
            setState('maxZLevel', maxZLevel);
        } else {
            setState('maxZLevel', 0);
        }

    }

    // 切换 当前source
    switchCurrentSource(layerName: string) {
        const source = this.sourceMap.get(layerName)
        if (source) {
            this.currentSource = source
        }
    }


    // 显示 点云feature
    showFeaturesForPointCloud(zlevel: number) {
        for (const [key, source] of this.sourceMap) {
            if (source.get('workLayer')) {
                // 直接查feature
                const features = this.cacheFeatures.getV(key, zlevel)
                if (features?.length) {
                    this.undoTool?.setActive(false);
                    source.addFeatures(features);
                    this.cacheFeatures.delete(key, zlevel)
                    this.undoTool?.setActive(true);
                }
            }
        }
    }

    // 隐藏 点云feature
    hideFeaturesForPointCloud(zlevel: number) {
        for (const [key, source] of this.sourceMap) {
            if (source.get('workLayer')) {
                source.getFeatures().forEach((f: Feature<any>) => {
                    let z = f.get('zlevel') || 0
                    if (z == zlevel) {
                        this.undoTool?.setActive(false);
                        // 把 source存入到 缓存中
                        this.cacheFeatures.set(key, zlevel, f);
                        source.removeFeature(f);
                        this.undoTool?.setActive(true);
                    }
                })
            }
        }
    }

    // feature add source
    addFeatureToSource(source: VectorSource<any>, features: Feature<any>[], layer: any) {
        const existIds = source.getFeatures().map(f => f.get('id'));
        this.undoTool?.getDeletedFeatures(source).forEach(f => {
            existIds.push(f.get('id'));
        });
        const cached = this.cacheFeatures.get(layer.key);
        const levels = cached ? Array.from(cached.values()).flat() : [];
        if (levels && levels.length > 0) {
            levels.forEach(f => existIds.push(f.get('id')));
        }
        const featureArr: Feature<any>[] = [];

        features.forEach(f => {
            f.setProperties({
                readOnly: layer.readOnly,
                layerType: layer.type,
                layerKey: layer.key,
            })

            if (!existIds.includes(f.get('id'))) {
                const z = Number(f.get('zlevel')) || 0;
                if (!this.visiblePointCloudLevels.includes(z)) {
                    this.cacheFeatures.set(layer.key, z, f);
                } else {
                    featureArr.push(f)
                }
            }
        })
        source.addFeatures(featureArr);
    }

    // 添加pos图层
    appendPosLayer() {
        this.posLayer = new VectorLayer({
            source: new VectorSource(),
            minZoom: 14,
            properties: { name: "POS" },
            zIndex: 100,
            style: posStyle.pos
        })
        this.olMap.addLayer(this.posLayer);
        this.getPosData();
        this.addPosclickChange();
    }

    getPosData() {
        this.trigger('loading', true);
        apiPosList().then(data => {
            if (data) {
                let dataT = data as object[]
                dataT.sort((a: any, b: any) => a.id - b.id);
                let features = this.initFeatures(dataT);
                this.posLayer.getSource().addFeatures(features);

                // 设置地图中心点
                if(dataT.length){
                    let mapCenter:any = dataT[0];
                    const view = this.olMap.getView();
                    view.setCenter(fromLonLat([mapCenter['lon'],mapCenter['lat']]));
                    view.setZoom(20);
                }
            }
        }).finally(() => {
            this.trigger('loading', false);
        })
    }


    addPosclickChange() {
        let featureSel: any
        let select = new SelectInt({
            layers: [this.posLayer],
            hitTolerance: 1
        })
        select.on('select', (e) => {
            select.getFeatures().clear()
            if (e.selected.length) {
                featureSel = e.selected[0]
                this.trigger('posSelChange', featureSel, this.posLayer.getSource().getFeatures())
            }

        })
        this.olMap.addInteraction(select)
    }

    initFeatures(data: object[]) {
        let features: Feature<any>[] = []
        data.forEach((item: any, index: number) => {
            let feature = new Feature({
                name: 'Point',
                geometry: new Point(fromLonLat([item.lon, item.lat])),
                id: item.id,
                index: index + 1,
                img: {
                    front: item.file_f,
                    rear: item.file_r
                },
                selected: false
            })
            features.push(feature)
        })
        return features
    }
    //    获取当前地图视口范围
    getMspViewExtent() {
        return this.olMap.getView().calculateExtent(this.olMap.getSize())
    }

    //检测当前点是否在当前视口
    public isPointInView(pointCoor: []) {
        let isNANtype = pointCoor.find(item => !item)
        if (!(isNANtype !== isNANtype)) {
            let extent = this.getMspViewExtent()
            let isInView = containsCoordinate(extent, pointCoor)
            if (!isInView) {
                this.olMap.getView().animate({
                    center: pointCoor,
                    duration: 200,
                    easing: linear,
                })
            }
        }
    }

    // 刷新Layer要素
    refreshFeatures(layers: any) {
        for (const layer of layers) {
            if (this.sourceMap.get(layer.key)) {
                const source = this.sourceMap.get(layer.key);
                source?.refresh();
            } else {
                const [source, vectorLayer] = this.initLayerForLoader(layer);
                this.sourceMap.set(layer.key, source);
                this.olMap.addLayer(vectorLayer)
            }
        }
    }

    getNotSavedFeature() {
        const size: number | undefined = this.undoTool?.getNotSavedFeatureSize()
        if (size && size > 0) {
            this.trigger('error', '请先保存要素，再进行下一步操作')
            return
        }
    }

    // 生成Lane
    async generateLaneCenterGlobal() {
        const size: number | undefined = this.undoTool?.getNotSavedFeatureSize()
        if (size && size > 0) {
            this.trigger('error', '请先保存要素，再进行下一步操作')
            return
        }
        this.trigger('loading', true);
        await generateGlobalApi().then(() => {
            this.trigger('success', 'Lane生成成功');
            this.trigger('loading', false);
            this.refreshFeatures(layers);
        }).finally(()=>{
            this.trigger('loading', false);
        })

    }

    // 生成车道中心线
    async generateLaneCenter() {
        const size: number | undefined = this.undoTool?.getNotSavedFeatureSize()
        if (size && size > 0) {
            this.trigger('error', '请先保存要素，再进行下一步操作')
            return
        }

        let length: any = this.selectTool?.getFeatures().getLength();

        if (length) {
            let laneGroupIds: string[] | undefined = this.selectTool?.getSpecifyLayerTypeFeaturesProp('lane_group', 'id')
            if (laneGroupIds && laneGroupIds.length) {
                this.trigger('loading', true);
                await generateCenterLine(laneGroupIds).then((res: any) => {

                    this.selectTool?.clearFeatures();
                    this.trigger('success', '车道中心线生成成功');
                    this.refreshFeatures(layers);
                    this.trigger('loading', false);
                }).catch(() => {
                    this.trigger('loading', false);
                })
            } else {
                this.trigger('error', '请选择lane_group图层要素，再进行下一步操作')
            }
        } else {
            this.trigger('error', '请先选择图层，再进行下一步操作')
        }
    }
    // 生成关联
    async generate(code: string, title: string) {
        const size: number | undefined = this.undoTool?.getNotSavedFeatureSize()
        if (size && size > 0) {
            this.trigger('error', '请先保存要素，再进行下一步操作')
            return
        }

        this.trigger('loading', true);
        await generateApi(code).then(() => {
            this.trigger('success',  title + message.generateSuccess);
            this.selectTool?.clearFeatures();
            this.refreshFeatures(layers);
            this.trigger('loading', false);
        }).finally(() => {
            this.trigger('loading', false);
        })
    }

    // 生成车道组
    async generateLaneGroup() {
        const size: number | undefined = this.undoTool?.getNotSavedFeatureSize()
        if (size && size > 0) {
            this.trigger('error', '请先保存要素，再进行下一步操作')
            return
        }

        let length: any = this.selectTool?.getFeatures().getLength();

        if (length) {
            const ids: string[] | undefined = this.selectTool?.getSpecifyLayerTypeFeaturesProp('boundary', 'id');

            if (ids && ids.length) {
                if (ids.length > 1) {
                    // 分类容器
                    let dxy1: string[] = [];
                    let dxy2: string[] = [];
                    let dxy3: string[] = [];
                    let dxy4: string[] = [];

                    this.selectTool?.getFeatures().forEach((feature: any) => {
                        let id = feature.get('id');
                        let line: any = writeFeatureObject(feature)
                        let coords: any = line.geometry.coordinates

                        let startCoord = coords[coords.length - 2];
                        let endCoord = coords[coords.length - 1];

                        let dx: number = endCoord[0] - startCoord[0]
                        let dy: number = endCoord[1] - startCoord[1]
                        // 使用Math.atan2函数计算方向的弧度
                        let angleRadians: number = Math.atan2(dy, dx);
                        let angleDegrees: number = angleRadians * (180 / Math.PI);

                        // 计算方向
                        if (angleDegrees >= -45 && angleDegrees < 45) {
                          dxy1.push(id)
                        } else if (angleDegrees >= 45 && angleDegrees < 135) {
                          dxy2.push(id)
                        } else if (angleDegrees >= -135 && angleDegrees < -45) {
                          dxy3.push(id)
                        } else {
                          dxy4.push(id)
                        }
                    })
                    let data: any = [];

                    if (dxy1.length > 1) {
                        data.push(dxy1)
                    }
                    if (dxy2.length > 1) {
                        data.push(dxy2)
                    }
                    if (dxy3.length > 1) {
                        data.push(dxy3)
                    }
                    if (dxy4.length > 1) {
                        data.push(dxy4)
                    }

                    if (data.length == 0) {
                        this.trigger('error', '请选择相同方向的要素')
                        return
                    }

                    this.trigger('loading', true);
                    await generateLaneGroupApi({ids: data}).then((res: any) => {
                        this.trigger('success',  'lane_group' + message.generateSuccess);
                        this.selectTool?.clearFeatures();
                        this.refreshFeatures(layers);
                        this.trigger('loading', false);
                    }).finally(() => {
                        this.trigger('loading', false);
                    })
                } else {
                    this.trigger('error', '必选选择1条以上的boundary图层要素数据')
                }
            } else {
                this.trigger('error', '请选择boundary图层要素，再进行下一步操作')
            }
        } else {
            this.trigger('error', '请先选择图层，再进行下一步操作')
        }
    }


    // 地图选择
    selectMap(layerkey: any) {
        if (this.selectTool) {
            this.olMap.removeInteraction(this.selectTool);
        }
        this.removeSelectMap();
        let layers;
        if (layerkey) {
            let layer = this.vectorLayers.find(layer => {
                if (layer.get('name') == layerkey) {
                    layer.setZIndex(1001);
                    return layer
                } else {
                    layer.setZIndex(1);
                }
            });

            layers = [layer];
        } else {
            layers = this.vectorLayers;
        }


        this.selectMapEvt = new SelectInt({
            multi: false,
            hitTolerance: 2,
            layers: layers
        })
        this.selectMapEvt.on('select', (e: any) => {
            if (e.selected.length) {
                e.selected[0].setStyle(selectVect)
                this.trigger('selectMapFea', e.selected[0])
            }
        })
        this.olMap.addInteraction(this.selectMapEvt)
    }

    removeSelectMap() {
        if (this.selectMapEvt) {
            this.vectorLayers.forEach(layer => {
                layer.setZIndex(1);
            });
            this.selectMapEvt?.getFeatures().forEach((feature: any) => {
                feature.setStyle(null);
            })

            this.trigger('selectMapFea', null)
        }
        this.olMap.removeInteraction(this.selectMapEvt)
    }

    findLayerByLayerkey (layerKey:string) : TileLayer<any>{
        let layerReturn : TileLayer<any> = new TileLayer<any>();
        this.olMap.getLayers().forEach((layer: any) => {
            const layerName = layer.get('name');
            if (layerName == layerKey) {
                layerReturn = layer
            }
        });
        return layerReturn;
    }

    //  通过ID和图层类型找要素
    // {id, type}
    featureHeightById(data: any) {
        let layerKey = layers.find((itemli: any) => data.type == itemli.type)?.key
        const isIncludes = this.layerKeys.includes(layerKey)

        if (!isIncludes){
            this.clearFeatureHeight();
            return;
        };

        // 读取对应 feature
        let features = this.sourceMap.get(layerKey)?.getFeatures()
        let feature  : Feature ;
        feature = features.find((item: Feature) => {
            if(data.id){
                return item.get('id') == data.id
            }else{
                return (item as any)['ol_uid'] == data.ol_uid
            }
        })
        this.clearFeatureHeight();//设置前先清理旧的
        if(isEmpty(feature)) return;
        this.heightLightFeature = feature
        this.featureHeightBeforeData.feature =  feature
        this.featureHeightBeforeData.featureStyle =  feature.getStyle();

        // 高亮 feature
        let styles: Style[] = [StyleBuilder.highlightStyle()];
        StyleBuilder.setArrowStyle(feature, styles, 1, this.olMap);
        this.heightLightFeature?.setStyle(styles)
        this.olMap.render();

        // 切换图层顺序
        let layerCur : TileLayer<any> = this.findLayerByLayerkey(layerKey);
        if(this.featureHeightBeforeData.laykey != layerKey){
            // 连续点击高亮 不同图层先清理之前的高亮
            this.featureHeightBeforeData.laykey = layerKey
            this.featureHeightBeforeData.layerZindex = layerCur.getZIndex()
            layerCur.setZIndex(1000)
        }

        // 定位
        this.location(this.heightLightFeature)
    }
    // 还原高亮前的图层顺序
    clearFeatureHeight(){
        // 切换feature
        if(this.featureHeightBeforeData.feature){
            let feature = this.featureHeightBeforeData.feature;
            feature.setStyle(this.featureHeightBeforeData.featureStyle)
            this.featureHeightBeforeData.feature = null
            this.featureHeightBeforeData.featureStyle = null
        }
        // 切换图层
        if(this.featureHeightBeforeData.laykey == "") return;
        let layerCur : TileLayer<any> = this.findLayerByLayerkey(this.featureHeightBeforeData.laykey);
        layerCur.setZIndex(this.featureHeightBeforeData.layerZindex)
        this.featureHeightBeforeData.laykey = ""
        this.featureHeightBeforeData.layerZindex = 0
    }

    location(data: any) {
        if (data) {
            let feature: any = writeFeatureObject(data!)
            let coordinate: number[] = center(feature.geometry).geometry.coordinates
            let coordinate3857: any = fromLonLat(coordinate)
            this.isPointInView(coordinate3857)
        }
    }

//    pos绘制矩形添加空中要素
    addTrafficFeature(data: number[][], layerInfo: any) {
        let source: any =  this.sourceMap.get(layerInfo.name);
        data?.forEach((item: any) => {
            item.push(item[0])
            let polygon = poltgonTurf([item])
            let feature = readFeature(polygon)

            feature.setProperties({
                readOnly: layerInfo.readOnly,
                layerType: layerInfo.type,
                layerKey: layerInfo.name,
                zlevel: state.maxZLevel
            })
            source.addFeature(feature);
        });
    }

//    添加标签图层
    appendTagLayer(){
        this.tagSource=new VectorSource()
        let layer=new VectorLayer({
            source:this.tagSource,
            properties: { name: 'tag' },
            minZoom: 12,
            visible:false,
            style: f => StyleBuilder.tagStyle('orange'),
            zIndex: 99
        })
        this.olMap.addLayer(layer)
    }

    getFeatures(data: any[]) {
        let features: any[] = []
        data?.forEach((item: any) => {
            let fea: any = readFeature(feature(JSON.parse((item.geom))))
            fea.set('id', item.featureFlagId)
            features.push(fea)
        })
        return features
    }

    //重置tag图层数据
    resetTagDataSource(data:any[]){
        this.tagSource.clear()
        let features=this.getFeatures(data)
        this.tagSource.addFeatures(features)
    }
    //高亮灯组
    highLightGroup(data: any) {
        let layerKey = layers.find((itemli: any) => itemli.type == data.layerType)?.key
        let features = this.sourceMap.get(layerKey)?.getFeatures()
        let selectedArr: any = this.selectTool?.getFeatures().getArray().map((item: any) => item.get('id'))
        features?.forEach((item: any) => {
            if (data.ids.includes(item.get('id'))) {
                item.setStyle(StyleBuilder.highlightStyle())
            } else if (!selectedArr?.includes(item.get('id'))) {
                item.setStyle(null)
            } else {
                this.selectTool?.setStyle(item)
            }
        })
        if (data.coordinate.length) {
            let coord: any = fromLonLat(data.coordinate)
            this.isPointInView(coord)
        }
    }

//    修改Z值
    async correctZvalue(){
        unByKey(this.listenEvent)
        this.listenEvent = this.olMap.on('click', async (e: MapBrowserEvent<any>) => {
            let coordinates:any = transform3857To4326(e.coordinate)
            let res = await getHeightSingle(coordinates)
            this.trigger('zValueChange',res)
        })
    }

    // 定位跳转
    locationCoord(xy: number[]) {
        const view = this.olMap.getView();
        view.setCenter(xy);
    }

    confirmZ(data: any) {
        data?.forEach((item: any) => {
            if (item.feature.getGeometry().getType() == 'LineString') {
                this.undoTool?.push('changeCoordinates', {
                    feature: item.feature,
                    before: item.oldCoords,
                    after: item.newCoords
                })
            } else {
                this.undoTool?.push('changeCoordinates', {
                    feature: item.feature,
                    before: [item.oldCoords],
                    after: [item.newCoords]
                })
            }

            if (item.feature.getGeometry() instanceof Polygon) {
                item.feature.getGeometry().setCoordinates([item.newCoords])
            } else {
                item.feature.getGeometry().setCoordinates(item.newCoords)
            }
        })
    }


}
