import { Feature } from "ol"
import RenderFeature from "ol/render/Feature"
import { LineString } from "ol/geom"
import { Style, Stroke } from "ol/style"

import { lineString } from "@turf/helpers"
import { lineOffset } from "@turf/turf"

import Colors from "@/common/extend/Colors"


const lineWidthNormal: number = 1;
const lineWidthSmall: number = 0.5;
const lineWidthMid: number = 1.25;

const doubleLine = (feature: Feature<any> | RenderFeature, distance: number) => {
    const line = lineString(feature.getGeometry().getCoordinates());
    const offsetLine = lineOffset(line, distance, { units: 'miles' })
    return new LineString(offsetLine.geometry.coordinates)
}


const strokeFn = (colorKey: string, lineDash: number[], width: number = lineWidthNormal) => {
    return [
        new Style({
            stroke: new Stroke({
                color: Colors[colorKey],
                width: width,
                lineDash: lineDash
            })
        })
    ]
}


const StylesFn = (colorKeys: string[], distance: number, lineDash1?: number[] | undefined, lineDash2?: number[] | undefined) => {
    return [
        new Style({
            stroke: new Stroke({
                color: Colors[colorKeys[0]],
                width: lineWidthMid,
                lineDash: lineDash1
            })
        }),
        new Style({
            stroke: new Stroke({
                color: Colors[colorKeys[1] ? colorKeys[1] : colorKeys[0]],
                width: lineWidthSmall,
                lineDash: lineDash2
            }),
            // 双线使用
            geometry: feature => doubleLine(feature, distance)
        }),
    ]
}

export default <Record<string, Function>>{
    "virtual-none": () => strokeFn('magenta', [0]),
    "other-none": () => strokeFn('magenta', [0]),
    
    "solid-white": () => strokeFn('white', [0]),
    "solid-yellow": () => strokeFn('yellow', [0]),
    "solid-blue": () => strokeFn('blue', [0]),
    "solid-other": () => strokeFn('light_green', [0]),

    "broken-white": () => strokeFn('white', [20, 20]),
    "broken-yellow": () => strokeFn('yellow', [8, 8]),
    "broken-blue": () => strokeFn('blue', [8, 8]),
    "broken-other": () => strokeFn('light_green', [8, 8]),

    "dounle_solid-white": () => StylesFn(['white'], 10),
    "dounle_solid-yellow": () => StylesFn(['yellow'], 10),
    "dounle_solid-blue": () => StylesFn(['blue'], 10),
    "dounle_solid-other": () => StylesFn(['light_green', 'light_green'], 10),

    "solid_broken-white": () => StylesFn(['white'], 10, undefined, [20, 20]),
    "solid_broken-yellow": () => StylesFn(['yellow'], 10, undefined, [20, 20]),
    "solid_broken-blue": () => StylesFn(['blue'], 10, undefined, [20, 20]),
    "solid_broken-other": () => StylesFn(['light_green', 'light_green'], 10, undefined, [20, 20]),

    "broken_solid-white": () => StylesFn(['white'], -10, undefined, [20, 20]),
    "broken_solid-yellow": () => StylesFn(['yellow'], -10, undefined, [20, 20]),
    "broken_solid-blue": () => StylesFn(['blue'], -10, undefined, [20, 20]),
    "broken_solid-other": () => StylesFn(['light_green', 'light_green'], -10, undefined, [20, 20]),
    
    "variable_direction-white": () => strokeFn('white', [0]),
    "variable_direction-yellow": () => strokeFn('yellow', [0]),

    "short_borken-white": () => strokeFn('white', [22, 25], 4.5),
    "short_borken-yellow": () => strokeFn('yellow', [22, 25], 4.5),
}