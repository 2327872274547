
import { Vue, Component, Prop,Watch } from "vue-property-decorator";
@Component
export default class SvgButton extends Vue {
  @Prop() icon?: any;
  @Prop() title?: any;
  @Prop() active?: any;
  imgSrc:string=''
  isShowDsc:boolean=false
  mounted(){
    this.getImgUrl(this.icon)
    this.isShowDsc = this.active
  }

  @Watch('active')
  activeHandle(newVal:boolean) {
    this.isShowDsc = newVal
  }
  //获取图片url
  getImgUrl(name: String) {
    this.imgSrc = require(`@/assets/icons/${name}.png`)
  }
  // 鼠标悬停离开
  mouseOverLeave(type:String){
    if (this.active || type == 'over') {
      this.isShowDsc = true
    }
    if (type == 'leave' && !this.active) {
      this.isShowDsc = false
    }
  }

}
