
import {Component, Vue, Watch, Prop, InjectReactive} from "vue-property-decorator";
import {eventBus} from '@/utils/eventBus';
import {checkErrorMessage} from '@/config/message'
import {layersTitle} from "@/config/layerConfig";

@Component
export default class CollapseList extends Vue {
  activeKey: any[] = [];
  currentClickIndex: number = 0
  errerCheckList: any[] = []
  errorPrompt: any = checkErrorMessage
  layersTitle: any = layersTitle

  @InjectReactive() checkListData!: any[]

  @Watch('checkListData', { immediate: true })
  listChange(newData: any) {
    this.errerCheckList = newData
  }

  delTag(index: number, item: any) {
    this.errerCheckList.splice(index, 1)
  }

  clickId(item: any) {
    eventBus.$emit('featureHeightById', {type: item.featureType, id: item.featureId})
  }

  allIgnore() {
    this.errerCheckList = []
    this.$emit('hideCheckList', false)

  }
}
