import { GeoJSON } from "ol/format";
import { transform } from "ol/proj";
import { Coordinate } from "ol/coordinate";

const format = new GeoJSON()


export function readFeatures(featureCollaction: any) {
    return format.readFeatures(featureCollaction, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    })
}


export function readFeature(geometry: any) {
    return format.readFeature(geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    })
}

export function writeFeatureObject(feature: any) {
    return format.writeFeatureObject(feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    })
}

export function writeFeaturesObject(features: any) {
    return format.writeFeaturesObject(features, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    })
}


export function transform4326(coord: Coordinate): Coordinate {
    return transform(coord.slice(0, 3), 'EPSG:3857', 'EPSG:4326');
}

export function transform3857(coord: Coordinate): Coordinate {
    return transform(coord.slice(0, 3), 'EPSG:4326', 'EPSG:3857');
}


export function transform3857To4326(coords: number[]) {
    return transform(coords, 'EPSG:3857', 'EPSG:4326');
}

export function transform4326To3857(coords: number[]) {
    return transform(coords, 'EPSG:4326', 'EPSG:3857');
}