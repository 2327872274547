export const isEmpty = (obj : any) => {
    if(typeof obj == "undefined") return true
    if(obj == null) return true
    if (typeof obj == "string" && obj.length <= 0){
        return true
    }
    if (!obj || Object.keys(obj).length == 0)
    {
       return true
    }
    return false
}

