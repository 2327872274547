
import { Vue, Component, Prop, Watch, Emit,Ref } from "vue-property-decorator";
import {menus} from "@/config/HeaderToolConfig";
import SvgButton from "@/components/SvgButton/SvgButton.vue";
import ImgBtn from "@/components/SvgButton/imgBtn.vue";
import DataExportPop from "@/views/headerTool/components/DataExportPop.vue";
import { layerTitleMap, layersTitle } from "@/config/layerConfig";
import ToggleImgButton from "@/components/SvgButton/ToggleImgButton.vue";
import { manulCheck } from "@/config/api";
import { state } from "@/store/index";

@Component({
    components: {
      DataExportPop,
      SvgButton ,
      ImgBtn,
      ToggleImgButton
  },
})
export default class HeaderTool extends Vue {
    @Prop() headerToolType?: any;

    toolData: any[] = [];
    selected: any = null;
    active: any = null;
    selectedIcon: string = "Drag";
    confirmLoading: boolean = false;

    // 整体操作栏
    headerBarTool: any = menus;

    get taskId(): string {
        return state.taskId
    }

    @Watch("headerToolType")
    headerToolDataChange() {
        this.initHeaderToolData();
    }

    // 初始化 headerData
    initHeaderToolData() {
        this.toolData = [];
        let have: boolean = false;
        let type = this.headerToolType || "";
        let layerMenus = layerTitleMap[type]['menus'];
        let arr: string[] = [];
        layerMenus.forEach((item: string) => {
            if (this.headerBarTool[item]) {
                arr.push(this.headerBarTool[item]);

                if (this.headerBarTool[item]['children']) {
                    this.headerBarTool[item]['children'].forEach((val: { [x: string] : string }) => {
                        if (val['name'] == this.selectedIcon) {
                            have = true
                        }
                    })
                } else {
                    if (item == this.selectedIcon) {
                        have = true
                    }
                }
            }
        });
        if (arr.length) {
            this.toolData.push(arr);
        }

        if (!have) {
            this.onSelectedIcon()
        }
    }

    // 选择header-icon
    @Emit('onBarButtonClick')
    onSelectedIcon(tool?: any) {
        //数据导出弹框
        if (tool?.name == 'DataExport') {
          (this.$refs.dataExportPop as HTMLFormElement).show()
        }
        
        
        let selected = tool ? tool : this.toolData[0][0]
        if (selected.needSelected) {
           this.selectedIcon = selected.name
        }
        this.toolData.forEach((items: any) => {
            items.forEach((item: { children: any[], icon: any, iconSelected: any, needSelected: boolean, name: string }) => {
                if (item.children && item.name !== 'Inspection' && item.name !== 'Automatic') {
                    let names = item.children.map((it: { name: string }) => it.name)
                    if (names.includes(tool?.name)) {
                      item.icon = tool?.icon
                    } else {
                      if (names.includes(this.selectedIcon)) {
                        item.icon = item.children.find((child: any) => child.name == this.selectedIcon).icon
                      } else {
                        item.icon = item.iconSelected
                      }
                    }
                }
            })
        })
        return selected
    }

    @Emit("onBarButtonClick")
    onToggle(tool: any, checked: boolean) {
      tool.checked = checked;
      return tool
    }

    handleManulCheck() {
      if (!state.taskId) {
        return;
      }
      let data = {
        taskIds: state.taskId,
        name: '',
        bias: undefined,
        num: undefined,
        other: '',
        featureCodes: Object.keys(layersTitle).join(','),
      };

      this.confirmLoading = true;
      manulCheck(data, this.$route.query.sign).then(
        (res: any) => {
          this.$message.success('操作成功');
          this.confirmLoading = false;
        },
        () => {
          this.$message.error('操作失败');
          this.confirmLoading = false;
        }
      );
    }
}
