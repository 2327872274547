// Map双键映射 Map(K, <U<T, V>>)

export default class DoubleKeyMaps<T, U, V> {
    private readonly storeMap: Map<T, Map<U, V[]>>

    constructor() {
        this.storeMap = new Map<T, Map<U, V[]>>()
    }

    get(key1: T) {
        return this.storeMap.get(key1)
    }

    getV(key1: T, key2: U) {
        const map = this.storeMap.get(key1)
        if (map && key2 !== undefined) {
            return map.get(key2)
        }
    }

    set(key1: T, key2: U, value: V) {
        let map = this.storeMap.get(key1)
        if (!map) {
            map = new Map<U, V[]>()
            this.storeMap.set(key1, map)
        }
        let arr = map.get(key2)
        if (!arr) {
            arr = new Array<V>()
            map.set(key2, arr)
        }
        arr.push(value)
    }

    delete(key1: T, key2: U) {
        let map = this.storeMap.get(key1)
        if (map) {
            map.delete(key2)
            if (map.size == 0) {
                this.storeMap.delete(key1)
            }
        }

    }
}