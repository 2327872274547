export default [
    {
        title: '要素图层组',
        key: 'Feature_Layer_Groups',
        selected: false,
        children: [],
        icon: 'featurePreIcon'
    },
    {
        title: '点云图层组',
        key: 'Point_Cloud_Layer_Group',
        selected: false,
        children: [],
        closed: true,
        tips: true,
        icon: 'posPreIcon'
    },
    {
        title: '辅助图层',
        key: 'Auxiliary_Pictures',
        selected: false,
        closed: true,
        tips: true,
        icon: 'auxiliaryPreIcon',
        children: [
            {
                title: 'POS 点',
                key: 'POS',
                name: 'POS',
                selected: false,
                readonly: true,
                hidden: true
            },
            {
                title: '地 图',
                key: 'OSM',
                name: 'OSM',
                selected: false,
                readonly: true,
                hidden: true
            },
            {
                title: '卫星图',
                key: 'Aerial',
                name: 'Aerial',
                selected: false,
                readonly: true,
                hidden: true
            },

            {
                title: '瓦片方格',
                key: 'TileGrid',
                name: 'TileGrid',
                selected: false,
                readonly: true,
                hidden: true
            },
        ],
    },
]
