import { Style, Icon } from "ol/style"
import { Feature } from "ol"
import { getCenter } from "ol/extent";
import { Point } from "ol/geom";

const no_entry_sign = [
    "general_caution_sign",
    "animal_crossing_sign",
    "children_crossing_sign",
    "obstacle_ahead_sign",
    "crosswind_sign",
    "traffic_signal_sign",
    "accident_prone_section_sign",
    "deceleration_yield_sign",
    "meeting_yield_sign"
]

const do_not_enter_sign = [
    "no_entry_sign",
    "do_not_enter_sign",
    "no_motor_vehicles_sign",
    "no_cargo_vehicles_sign",
    "no_tricycles_sign",
    "no_large_or_small_buses_sign",
    "no_trailers_sign",
    "no_non_motorized_vehicles_sign",
    "no_motorcycles_sign",
    "no_left_or_right_turn_sign",
    "no_straight_through_sign",
    "no_left_and_right_turn_sign",
    "no_straight_through_or_left_turn_sign",
    "no_straight_through_or_right_turn_sign",
    "no_u_turn_sign",
    "no_parking_sign",
    "no_pedestrians_sign"
]

const other = [
    "sharp_turn_sign",
    "steep_hill_sign",
    "narrow_road_sign",
    "tunnel_sign",
    "height_restriction_sign",
    "left_and_right_turn_sign",
    "straight_and_left_turn_sign",
    "straight_and_right_turn_sign",
    "straight_through_and_turn_sign",
    "keep_right_or_keep_left_sign",
    "directional_arrow_sign",
    "roundabout_sign",
    "lane_control_sign",
    "one_way_street_sign",
    "end_of_no_overtaking_sign",
    "other" 
]

export default function TrafficSignStyle (feature: Feature<any>, zoom: number) {
    let src: any = null;
    const type = feature.get('type');
    
    try {
        if (no_entry_sign.includes(type)) {
            src = require(`@/assets/icons/sign/no_entry_sign.png`);
        } else if (do_not_enter_sign.includes(type)) {
            src = require(`@/assets/icons/sign/do_not_enter_sign.png`);
        } else if (other.includes(type)) {
            src = require(`@/assets/icons/sign/other.png`);
        } else {
            src = require(`@/assets/icons/sign/${type}.png`);
        }
    } catch (error) {
        src = require(`@/assets/icons/sign/other.png`)
    }
    
    return [
        new Style({
            image: new Icon({
                src: src,
                scale: zoom < 18 ? 0.1 : 0.6,
                rotateWithView: true,
            }),
            geometry: (f: any) => new Point(getCenter(feature.getGeometry().getExtent()))
        })
    ]
}
