import LAYER_DEFAULT_PROP from "@/config/layerDefaultAttr";
import { layerTypeMap } from "@/config/layerConfig"
import { Feature } from "ol";
import VectorSource from "ol/source/Vector";


const LineString = [
    'boundary',
    'lane'
]
const Polygon = [
    'road_mark',
    'stop_line',
    'connectivity_area'
]

export default new class {
    destinationFeatures(features: Feature<any>[], currentSource: VectorSource<any>) {
        const layerType: number = currentSource.get('layerType');
        const props: string | any = LAYER_DEFAULT_PROP[layerTypeMap[layerType].title];
        features.forEach(feature => {
            const originProps: string | any = LAYER_DEFAULT_PROP[layerTypeMap[feature.get('layerType')].title];
            for (const key in originProps) {
                feature.unset(key);
            }
            for (const key in props) {
                feature.set(key, LAYER_DEFAULT_PROP[layerTypeMap[layerType].title][key]);
            }
        })
    }


    // 筛选图层信息，目标图层不一定有相关类型图形的权限
    pasteFilter(features: Feature<any>[], currentSource: VectorSource<any>): boolean {
        const layerType: number = currentSource.get('layerType');
        return features.every(feature => {
            const type: string = feature.getGeometry().getType();
            if (type == 'LineString') {
                return LineString.includes(layerTypeMap[layerType].title)
            } else if (type == 'Polygon') {
                return Polygon.includes(layerTypeMap[layerType].title)
            }
            return false;
        })
    }
}()