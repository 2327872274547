
import { Vue, Component, Emit } from "vue-property-decorator";

// 导航栏 - layer生成
import MenuList from "@/views/menus/modules/layers-tree";
import { layers as MenuFeaturesLayerGroups } from "@/config/layerConfig";
import { state } from "@/store/index";

// 浏览器缓存写入
import Storage from "@/common/utils/Storage";

// 生成点云数据
import { Tile_Layers as pointCloud } from "@/views/menus/modules/point-cloud-tile";

@Component
export default class Menus extends Vue {
    [x: string]: any;
    menuData: any[] = [];
    activeKey: string[] = [];
    visibleLayers: string[] = [];

    selectedLayer: string = ""; // 选中 menu - key
    visibleLayerNames: string[] = [];
    pointCloudNames: string[] = [];

    created() {
        this.visibleLayerNames = Storage.getLocal("localVisibleKeys") || [];
    }

    get SelectedItem() {
        return (item: { name: string }) => {
            return this.selectedLayer == item.name ? "selected-item" : "";
        };
    }

    // 显示高亮
    get EyeIconStatus() {
        return (data: { children: any[] }) => {
            return data.children?.find((layer: { name: string }) =>
                this.visibleLayers.includes(layer.name)
            )
                ? "eyeShow"
                : "eyeDis";
        };
    }

    initMenuList() {
        this.menuData = [];
        MenuList.forEach((e: any) => {
            if (e.key == "Feature_Layer_Groups") {
                e.children = MenuFeaturesLayerGroups.map((it: any) => ({
                    title: it.title, // boundary
                    name: it.key, // boundary_1001
                    type: it.type, // 1001
                    readOnly: it.readOnly, // false / true
                }));
            } else if (e.key == "Point_Cloud_Layer_Group") {
                e.children = pointCloud(state.zlevel);
                this.pointCloudNames = e.children.map((item: any) => item.name);
            }
            this.menuData.push(e);
        });

        this.activeKey = ["Feature_Layer_Groups"];
        this.selectedLayer = MenuFeaturesLayerGroups[0].key; // 默认选中menu
        this.onSelectMenuItem(this.menuData[0].children[0]);
        this.visibleLayers = this.defaultLayerShow();
    }

    onSelectMenuItem(layer: any) {
        if (layer.selected == false) {
            return false;
        }
        this.selectedLayer = layer.name;
        this.$emit('onSelectLayer', layer);
    }

    // 显示、隐藏
    @Emit("onLayerVisibleKeys")
    onLayerVisibleChange(layer: any) {
        let index: number = this.visibleLayers.indexOf(layer.name);
        if (index > -1) {
            this.visibleLayers.splice(index, 1);
        } else {
            this.visibleLayers.push(layer.name);
        }

        Storage.setLocal("localVisibleKeys", this.visibleLayers);
        return this.visibleLayers;
    }

    // 默认 显示、隐藏
    @Emit("onLayerVisibleKeys")
    defaultLayerShow() {
        let names: string[] = [];
        let children = this.menuData
            .map((item: { children: any }) => item.children)
            .flat();

        if (this.visibleLayerNames.length) {
            names = children
                .filter((item: any) => this.visibleLayerNames.includes(item.name))
                .map((item: { name: string }) => item.name);
        } else {
            let defaultHiddenPointCloudNames = this.pointCloudNames.slice(1);
            names = children.map((item: { name: string }) => item.name).filter((item: any) => !defaultHiddenPointCloudNames.includes(item));
        }
        return names;
    }

    
    // 显示、隐藏 - ul
    @Emit("onLayerVisibleKeys")
    onParsetVisibleClick(menu: any, type: string) {
        let names: string[] = menu.children.map((item: { name: string }) => item.name);

        if (type == "eyeShow") {
            names.forEach((name: string) => {
                let index: number = this.visibleLayers.indexOf(name);
                if (index > -1) {
                    this.visibleLayers.splice(index, 1);
                }
            });
        } else {
            this.visibleLayers.push(...names);
        }
        return this.visibleLayers;
    }
}
