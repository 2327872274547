import { Map as olMap, Feature } from "ol"
import { Point, MultiPoint, Polygon } from "ol/geom"
import { Style, Stroke, Fill, Circle, RegularShape } from "ol/style"

import bboxClip from "@turf/bbox-clip"
import { BBox, lineString } from "@turf/helpers"
import { getCoords, getType } from "@turf/invariant"
import RenderFeature from "ol/render/Feature"
import { getLayerType } from "@/config/layerConfig";

import Colors from "@/common/extend/Colors";

import layerStyleUtil from "@/common/utils/LayerStyleUtil"

const LayerStyleUtil = new layerStyleUtil()
const MAP_MIN_ZOOM: number = 18

const isShowPoint: number[] = [
    getLayerType('boundary'),
    getLayerType('lane')
]

const FreedGeomType: string[] = ['Polygon', 'MultiLineString']
const includesPoint: any = [
    getLayerType('lane_group'),
    getLayerType('stop_line'),
    getLayerType('road_mark'),
    getLayerType('connectivity_area')
]



export default class StyleBuilder {
    // 初始颜色
    public static DefaultStyle(feature: Feature<any> | RenderFeature, zoom: number, map: olMap) {
        let layerType: number = feature.get('layerType');
        let styles: any = LayerStyleUtil.getConfigStyle(layerType, feature, zoom, map)

        if (zoom > MAP_MIN_ZOOM && isShowPoint.includes(layerType)) {
            StyleBuilder.setArrowStyle(feature, styles, 1, map);
            StyleBuilder.setPointStyle(feature, styles);
        }
        // 圆点
        if (zoom > MAP_MIN_ZOOM && includesPoint.includes(layerType)) {
            StyleBuilder.setPointStyle(feature, styles);
        }
        return styles
    }

    // 箭头
    public static setArrowStyle(feature: Feature<any> | RenderFeature, styles: Style[], size: number = 1, map: olMap) {
        let color = styles[0].getStroke().getColor();
        const geometry = feature.getGeometry();
        const geomType = geometry.getType();
        if (geomType == 'LineString') {
            const extent = map.getView().calculateExtent(map.getSize());
            const coordinates = geometry.getCoordinates();
            const line = lineString(coordinates);
            const clipped: any = bboxClip(line, extent as BBox);
            let newGeomType = getType(clipped);
            let newCoordinates = getCoords(clipped);
            let newGeomLastCoords = newCoordinates[newCoordinates.length - 1];
            newCoordinates = (newGeomType == 'MultiLineString' && newCoordinates.length > 0) ? newGeomLastCoords : newCoordinates;

            if (coordinates.length > 1 && newCoordinates.length > 1) {
                const n = coordinates.length - 1;
                const dx = coordinates[n][0] - coordinates[n - 1][0];
                const dy = coordinates[n][1] - coordinates[n - 1][1];
                const oldRotation = Math.atan2(dy, dx);

                const m = newCoordinates.length - 1;
                const Dx = newCoordinates[m][0] - newCoordinates[m - 1][0];
                const Dy = newCoordinates[m][1] - newCoordinates[m - 1][1];
                const newRotation = Math.atan2(Dy, Dx);

                const rotation = clipped ? newRotation : oldRotation;

                const point = clipped ? new Point(newGeomLastCoords) : new Point(geometry.getLastCoordinate());

                const style = new Style({
                    geometry: point,
                    image: new RegularShape({
                        fill: new Fill({ color }),
                        stroke: new Stroke({ color, width: size }),
                        points: 3,
                        radius: 6,
                        displacement: [0, -20],
                        rotateWithView: true,
                        rotation: Math.PI / 2 - rotation,
                        angle: 0
                    })
                })
                styles.push(style)
            }
        }
    }

    // 圆头
    public static setPointStyle(feature: Feature<any> | RenderFeature, styles: Style[]) {
        const color = styles[0].getStroke().getColor();
        const geometry = feature.getGeometry();
        const geomType = geometry.getType();
        let coords: any = [];
        let radius = 3;
        if (geomType == 'LineString') {
            radius = 5;
            const firstCoords = geometry.getFirstCoordinate();
            const lastCoords = geometry.getLastCoordinate();
            coords = [firstCoords, lastCoords];
        } else if (geomType == 'Polygon') {
            const coordinates = geometry.getCoordinates();
            coords = coordinates[0];
        }

        const style = new Style({
            image: new Circle({
                radius: radius,
                fill: new Fill({ color })
            }),
            geometry: new MultiPoint(coords)
        })
        styles.push(style)
    }

    // 鼠标放置、选择
    public static BrowerEventStyle(feature: Feature<any> | RenderFeature, map: olMap) {
        let geom = feature.getGeometry();
        let geomType = geom.getType();
        let coords = FreedGeomType.includes(geomType) ? geom?.getCoordinates().flat() : geom?.getCoordinates();
        let radius: number = 3;
        if (geomType == 'Point') {
            radius = 6;
        }

        const styles = [
            new Style({
                stroke: new Stroke({
                    color: Colors['select_blue'],
                    width: 3
                }),
                fill: new Fill({ color: Colors['transparent_white'] }),
                image: new Circle({
                    radius: radius,
                    fill: new Fill({ color: Colors['select_blue'] })
                }),
                zIndex: 10
            }),

            new Style({
                image: new RegularShape({
                    fill: new Fill({ color: Colors['orange'] }),
                    stroke: new Stroke({ color: Colors['orange'], width: 2 }),
                    points: 4,
                    radius: 6,
                    radius2: 0,
                    angle: Math.PI / 4
                }),
                geometry: new MultiPoint(coords),
                zIndex: 10
            })
        ]
        StyleBuilder.setArrowStyle(feature, styles, 1, map);
        return styles
    }

    // 画线 + 画点
    public static DrawStyle(feature: Feature<any> | RenderFeature): Style[] {
        // 图标 + 线条
        const style = [
            new Style({
                stroke: new Stroke({
                    color: Colors['rose_blue'],
                    width: 2,
                    lineDash: [0]
                }),
                image: new Circle({
                    radius: 2,
                    fill: new Fill({ color: Colors['rose_blue'] })
                })
            })
        ]
        StyleBuilder.setPointStyle(feature, style)
        return style;
    }

    // Select 选中
    public static SelectDefault(): Style {
        return new Style({
            stroke: new Stroke({
                color: Colors['select_blue'],
                width: 3,
                lineDash: [0]
            }),
            fill: new Fill({ color: Colors['transparent_white'] }),
            image: new Circle({
                radius: 3,
                fill: new Fill({ color: Colors['select_blue'] })
            }),
            zIndex: 10
        })
    }

    // Select - Point 选中顶点
    public static SelectVertices(isHigh: boolean): Style {
        return new Style({
            image: new Circle({
                fill: new Fill({ color: Colors[isHigh ? 'yellow' : 'orange'] }),
                radius: 6
            })
        })
    }

    // 框选
    public static polygonSelectStyle() {
        return new Style({
            fill: new Fill({ color: Colors['transparent_white'] }),
            stroke: new Stroke({
                color: Colors['select_yellow'],
                width: 2,
                lineDash: [10, 5]
            }),
        })
    }

    // 移动顶点
    public static moveVertexStyle() {
        return new Style({
            image: new Circle({
                stroke: new Stroke({ color: Colors['orange'], width: 1.5 }),
                radius: 6,
            }),
            fill: new Fill({ color: Colors['transparent_white'] }),
            stroke: new Stroke({ color: Colors['orange'], width: 1.5 })
        })
    }

    // snapGuides
    public static snapGuidesStyle(): Style[] {
        return [new Style({
            stroke: new Stroke({
                color: Colors['yellow'],
                lineDash: [8, 5],
                width: 1
            })
        })]
    }

    // splitter
    public static splitterStyle() {
        return StyleBuilder.drawStyle([1, 1])
    }

    public static drawStyle(lineDash: number[], color?: string) {
        return [
            new Style({
                fill: new Fill({ color: Colors['transparent_white'] }),
                stroke: new Stroke({
                    color: color ?? '#096dd9',
                    width: 2,
                    lineDash: lineDash
                }),
                image: new Circle({
                    radius: 5,
                    fill: new Fill({ color: '#096dd9' })
                })
            }),
            new Style({
                image: new Circle({
                    radius: 3,
                    fill: new Fill({ color: '#096dd9' })
                })
            })
        ]
    }

    // pointerHover
    public static pointerHoverStyle(): Style[] {
        return [
            new Style({
                fill: new Fill({ color: Colors['transparent_white'] }),
                stroke: new Stroke({ color: Colors['rose_blue'], width: 2 }),
                image: new RegularShape({
                    fill: new Fill({ color: Colors['transparent_white'] }),
                    stroke: new Stroke({ color: Colors['rose_blue'], width: 2 }),
                    points: 4,
                    radius: 9,
                    angle: Math.PI / 4
                })
            })
        ]
    }

    // 矩形
    public static drawPolygonStyle(feature: Feature<any>): Style[] {
        return [
            new Style({
                stroke: new Stroke({
                    color: Colors['rose_blue'],
                    width: 2
                }),
                fill: new Fill({
                    color: Colors['transparent_gray']
                }),
                image: new Circle({
                    radius: 6,
                    stroke: new Stroke({ color: Colors['white'], width: 2 }),
                    fill: new Fill({ color: Colors['rose_blue'] })
                }),
                geometry: feature.getGeometry().getType() == 'LineString' ? new Polygon([]) : undefined
            })
        ]
    }

    // 测距 - layer 使用
    public static measureLayerStyle(feature: Feature<any> | RenderFeature): Style[] {
        return [
            new Style({
                fill: new Fill({
                    color: Colors['select_yellow']
                }),
                stroke: new Stroke({
                    color: Colors['select_yellow'],
                    width: 2
                }),
                image: new Circle({
                    radius: 7,
                    fill: new Fill({
                        color: Colors['select_yellow']
                    })
                })
            }),
            new Style({
                image: new Circle({
                    radius: 3,
                    fill: new Fill({
                        color: Colors['select_yellow']
                    })
                }),
                geometry: new MultiPoint(feature.getGeometry().getCoordinates())
            })
        ]
    }

    public static measureSketchStyle(): Style {
        return new Style({
            fill: new Fill({
                color: Colors['transparent_white']
            }),
            stroke: new Stroke({
                color: Colors['select_yellow'],
                width: 2,
                lineDash: [10, 10]
            }),
            image: new Circle({
                radius: 5,
                fill: new Fill({
                    color: Colors['select_yellow']
                }),
                stroke: new Stroke({
                    color: Colors['select_yellow']
                })
            })
        })
    }


    // 几何高亮
    public static highlightStyle(): Style {
        return new Style({
            fill: new Fill({color: Colors['transparent_white']}),
            stroke: new Stroke({
                color: Colors['red'],
                width: 3,
            }),
            image: new Circle({
                radius: 5,
                fill: new Fill({
                    color: Colors['light_green']
                })
            }),
            zIndex: 100,
        })
    }

    //  tag星号
    public static tagStyle(color: string) {
        const styles = [
            new Style({
                image: new RegularShape({
                    fill: new Fill({color: Colors[color]}),
                    points: 5,
                    radius1: 8,
                    radius2: 4
                }),
            })
        ]
        return styles
    }
}
