import { MapBrowserEvent, Map as olMap, Collection, Feature, PluggableMap } from "ol";

import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import RenderFeature from "ol/render/Feature";

import Event from "ol/events/Event";
import { Style } from "ol/style";
import { LineString, Point } from "ol/geom";
import { shiftKeyOnly } from "ol/events/condition";

import { ol_coordinate_dist2d, getNearestCoord } from "@/common/utils/CommonUtil";
import BoxPointer from "@/common/Interactions/BoxPointer";
import StyleBuilder from "@/common/extend/StyleBuilder"

class MovePointEvent extends Event {
    features?: Feature<any>[];

    constructor(type: string, features: Feature<any>[]) {
        super(type);
        if (features) {
            this.features = features;
        }
    }
}


export default class ModifyPoint extends BoxPointer {
    private readonly _filter: Function;
    private readonly _hitTolerance: number;
    private readonly _hoverStyle: Style | Style[] | ((_: Feature<any> | RenderFeature) => Style | Style[]);

    private readonly _overlayLayer: VectorLayer<VectorSource<any>>;
    private readonly _layout: string;
    private readonly _onRemoveFeatureEvent: Function;

    private dragIndex: number;
    private _moveSelected: Feature<any>[];
    private _isMoving: boolean | null;
    private _selectedFeatures: any[];
    private _sources: VectorSource<any>[];

    private _newPoints: any[];
    private _points: any[];
    private _isOnlyLine: boolean;
    private _assistLineF: Feature<any> | null;
    private _bound: any | undefined;

    constructor(options: any) {
        if (!options) {
            options = {};
        }
        super(options);

        this._bound = options.bound || undefined;

        this._sources = options.sources ? (options.sources instanceof Array) ? options.sources : [options.sources] : [];

        this._filter = options.filter || (() => true);
        this._hitTolerance = options.hitTolerance || 10;
        this._hoverStyle = options.hoverStyle || StyleBuilder.moveVertexStyle;

        this._layout = "XYZM";

        this.dragIndex = -1;
        this._points = [];
        this._isMoving = null;
        this._isOnlyLine = true;
        this._assistLineF = null;

        this._selectedFeatures = [];
        this._moveSelected = [];
        this._newPoints = [];

        this._overlayLayer = new VectorLayer({
            source: new VectorSource({ useSpatialIndex: false }),
            style: StyleBuilder.moveVertexStyle
        })

        this.on;
        this._onRemoveFeatureEvent = this.onRemoveFeature.bind(this);
        this._sources.forEach((source: any) => source?.on('removefeature', this._onRemoveFeatureEvent));
    }

    setMap(map: olMap) {
        if (this.getMap()) {
            this._sources.forEach((source: any) => source?.un("removefeature", this._onRemoveFeatureEvent))
            this.getMap()?.removeLayer(this._overlayLayer);
        }
        super.setMap(map);
        this._overlayLayer.setMap(map);
    }

    private getFeaturesMap() {
        const map = new Map();
        this._selectedFeatures.forEach((data: { feature: any, coord: number[] }) => {
            if (map.has(data.feature)) {
                map.get(data.feature).push(data.coord);
            } else {
                map.set(data.feature, [data.coord]);
            }
        })
        return map
    }

    private onRemoveFeature(data: { feature: Feature<any> | RenderFeature }): void {
        const feature = this._selectedFeatures.find(item => item.feature == data.feature);

        if (feature) {
            const MAP = this.getFeaturesMap();
            const coords = MAP.get(data.feature);
            const removes: any[] = [];

            this._overlayLayer.getSource()?.getFeatures().forEach((f: Feature<any>) => {
                const p = f.getGeometry().getFirstCoordinate();
                for (const coord of coords) {
                    if (p.toString() == coord.toString()) {
                        removes.push(f);
                    }
                }
            })
            if (removes.length > 0) {
                removes.forEach(f => this._overlayLayer.getSource()?.removeFeature(f));
            }

            const index = this._selectedFeatures.indexOf(feature);
            this._selectedFeatures.splice(index, 1);
        }
    }

    public setSources(sources: VectorSource<any>[] | VectorSource<any>): void {
        this._sources.forEach((source: any) => source?.un("removefeature", this._onRemoveFeatureEvent))
        this._sources = sources instanceof Array ? sources : [sources];
        this._sources.forEach((source: any) => source?.on("removefeature", this._onRemoveFeatureEvent))
    }

    private getClosestFeature(mapBrowserEvent: any) {
        let snapDistance: number = this._hitTolerance + 1;
        let closestFeature: any = null;
        let NPoint: any = null;

        this._sources.forEach(source => {
            const feature = source.getClosestFeatureToCoordinate(mapBrowserEvent.coordinate);
            if (feature && this._filter(feature)) {
                const point = feature.getGeometry().getClosestPoint(mapBrowserEvent.coordinate);
                const line = new LineString([mapBrowserEvent.coordinate, point]);
                const dist = line.getLength() / mapBrowserEvent.frameState.viewState.resolution;
                if (dist < snapDistance) {
                    snapDistance = dist;
                    closestFeature = feature;
                    NPoint = point;
                }
            }
        });

        if (snapDistance > this._hitTolerance) {
            return false;
        }

        if (closestFeature !== null) {
            const coords = getNearestCoord(NPoint, closestFeature?.getGeometry());
            if (coords) {
                const p: any = this.getMap()?.getPixelFromCoordinate(coords);
                if (ol_coordinate_dist2d(mapBrowserEvent.pixel, p) < this._hitTolerance) {
                    const map = mapBrowserEvent.map;
                    const features: Feature<any>[] = [];
                    map.forEachFeatureAtPixel(mapBrowserEvent.pixel, (f: Feature<any>) => {
                        const index = f.getGeometry().getCoordinates().findIndex((e: number[]) => {
                            return e[0] == coords[0] && e[1] == coords[1]
                        })
                        if (index > -1 && (index == 0 || index == f.getGeometry().getCoordinates().length - 1)) {
                            features.push(f);
                        }
                    }, {
                        hitTolerance: this._hitTolerance + 1,
                        layerFilter: (layer: VectorLayer<any>) => {
                            return this._sources.includes(layer.getSource());
                        }
                    })
                    if (features.length) {
                        return { coord: coords, features: features }
                    }
                    return false;
                }
            }
        }
        return false;
    }

    private onLine() {
        let k;
        this._isOnlyLine = true;
        for (let i = 0; i < this._points.length - 1; i++) {
            let j = 0;
            if (this._points[i + 1][1] - this._points[i][1] !== 0) {
                j = ((this._points[i + 1][1] - this._points[i][1]) / (this._points[i + 1][0] - this._points[i][0]));
                j = Math.floor(j * 10) / 10;
            }
            if (i > 0 && k !== j) {
                this._isOnlyLine = false;
                break;
            }
            k = j;
        }
    }

    private addVertex(data: any) {
        if (this._selectedFeatures.find((e: any) => JSON.stringify(e.coord) === JSON.stringify(data.coord))) {
            return;
        }
        const point = new Feature(new Point(data.coord));
        this._overlayLayer.getSource()?.addFeature(point);
        this._points.push(data.coord);
        this._selectedFeatures.push(data);
    }

    private clearVertex() {
        this._overlayLayer.getSource()?.clear();
        this._points = [];
        this._assistLineF = null;
        this._selectedFeatures = [];
        this._newPoints = [];
        this.dragIndex = -1;
        this._isMoving = false;
    }

    protected handleDownEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        const data: any = this.getClosestFeature(mapBrowserEvent);
        const drag = this._selectedFeatures.findIndex((e: any) => {
            return data && e.coord[0] == data.coord[0] && e.coord[1] == data.coord[1]
        })
        if (!shiftKeyOnly(mapBrowserEvent) && drag == -1) {
            this.clearVertex();
        }

        if (data) {
            this.addVertex(data);
            this.onLine();
            this.createAssisLine();
            return true;
        }
        return super.handleDownEvent(mapBrowserEvent);
    }

    protected handleDragEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
        super.handleDragEvent(mapBrowserEvent);
        if (this._selectedFeatures.length) {
            this.moveDrag(mapBrowserEvent);
        }
    }

    protected handleUpEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        super.handleUpEvent(mapBrowserEvent);
        if (this._newPoints.length) {
            this._selectedFeatures.forEach((selected: { features: Feature<any>[], coord: number[] }, index: number) => {
                if (this._newPoints.length) {
                    selected.features.forEach((feature, i) => {
                        const coordinate = feature.getGeometry().getCoordinates();
                        const startIndex = coordinate.findIndex((e: any[]) => {
                            return e[0] == selected.coord[0] && e[1] == selected.coord[1]
                        })
                        const featuresIndex = this._newPoints[index].moveInfo.featuresIndex;
                        
                        if (startIndex == 0) {
                            coordinate.splice(startIndex, featuresIndex[i] + 1);
                            coordinate.unshift(this._newPoints[index].coord);
                        } else if (startIndex > 0 && coordinate.length > 2) {
                            coordinate.splice(featuresIndex[i], startIndex + 1 - featuresIndex[i]);
                            coordinate.push(this._newPoints[index].coord);
                        } else {
                            coordinate[startIndex] = this._newPoints[index].coord;
                        }
                        feature?.getGeometry().setCoordinates(coordinate, this._layout);
                        feature.set('tag', 3);
                    })
                }
            })
            let features: Feature<any>[] = [];
            this._selectedFeatures.map((item: { features: Feature<any>[] }) => features = [...features, ...item.features]);
            this.dispatchEvent(new MovePointEvent('movepointend', features));
            this.clearVertex();
        }
        return false;
    }

    protected handleMoveEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
        let feature: any;

        this._sources.forEach(source => {
            feature = mapBrowserEvent.map.forEachFeatureAtPixel(mapBrowserEvent.pixel, (f: any, layer: any) => {
                if (source == layer?.getSource()) {
                    return f
                } else {
                    return false
                }
            }, { hitTolerance: 10 });
        })


        if (feature) {
            feature.setStyle(this._hoverStyle);
            this._moveSelected.push(feature);
        } else {
            this._moveSelected.forEach(f => f.setStyle(undefined));
            this._moveSelected = [];
        }
    }

    private moveDrag(mapBrowserEvent: MapBrowserEvent<any>) {
        if (!this._isMoving) {
            let features: Feature<any>[] = [];
            this._selectedFeatures.map((item: any) => features = [...features, ...item.features]);
            this.dispatchEvent(new MovePointEvent('movepointstart', features));
        }
        this._isMoving = true;
        const l = this.getL(mapBrowserEvent);
        this._selectedFeatures.forEach((item: { features: Feature<any>[]; coord: number[] }, index: number) => {
            let coordinate = mapBrowserEvent.coordinate;
            if (this.dragIndex > -1) {
                this._selectedFeatures[this.dragIndex].coord;
                const x = coordinate[0] - (this._selectedFeatures[this.dragIndex].coord[0] - item.coord[0])
                const y = coordinate[1] - (this._selectedFeatures[this.dragIndex].coord[1] - item.coord[1])
                coordinate = [x, y];
            }
            const pullAngle = this.getAngle(coordinate, item.coord);

            let moveInfo = this.getMoveAngle(item, pullAngle, index);
            if (moveInfo.moveAngle !== null) {
                const overFeature: any = this._overlayLayer.getSource()?.getFeatures().filter((item: Feature<any>) => item.getGeometry() instanceof Point)[index];
                moveInfo = {
                    ...moveInfo,
                    overFeature: overFeature
                }
                const newp = this.getMoveCoordinates(moveInfo, l);
                this._newPoints[index] = {
                    coord: newp,
                    moveInfo
                }
                this._points[index] = newp;
            }
        })
        this.createAssisLine();
    }

    private createAssisLine() {
        if (!this._isOnlyLine && this._assistLineF) {
            this._assistLineF.getGeometry().setCoordinates([]);
        }
        if (this._points.length > 1 && this._isOnlyLine) {
            const dx = this._points[0][0] - this._points[1][0];
            const dy = this._points[0][1] - this._points[1][1];
            const d1 = 1 / Math.sqrt(dx * dx + dy * dy);
            const generateLine = (loopCond: number) => {
                return [
                    this._points[0][0] + dx * d1 * loopCond,
                    this._points[0][1] + dy * d1 * loopCond,
                ]
            }
            const coords = [generateLine(-500), generateLine(500)];
            if (this._assistLineF) {
                this._assistLineF.getGeometry().setCoordinates(coords);
            } else {
                this._assistLineF = new Feature(new LineString(coords));
                this._overlayLayer.getSource()?.addFeature(this._assistLineF);
            }
        }
    }

    private getL(mapBrowserEvent: MapBrowserEvent<any>) {
        const data = this.getClosestFeature(mapBrowserEvent);
        this.dragIndex = this.dragIndex > -1 ? this.dragIndex : this._selectedFeatures.findIndex((e: { coord: number[] }) => {
            return data && e.coord[0] == data.coord[0] && e.coord[1] == data.coord[1]
        })
        if (this.dragIndex == -1) {
            this.dragIndex = 0
        }
        const pullAngle = this.getAngle(mapBrowserEvent.coordinate, this._selectedFeatures[this.dragIndex].coord)
        const moveAngle = this.getMoveAngle(this._selectedFeatures[this.dragIndex], pullAngle, this.dragIndex).moveAngle;
        const overFeature: any = this._overlayLayer.getSource()?.getFeatures().filter((item: Feature<any>) => item.getGeometry() instanceof Point)[this.dragIndex];
        const coordinate = overFeature.getGeometry().getCoordinates();

        let l = (mapBrowserEvent.coordinate[0] - coordinate[0]) / Math.abs(Math.cos(moveAngle)) / 50;
        if ((moveAngle > 45 && moveAngle < 135) || (moveAngle > 225 && moveAngle < 315)) {
            l = (mapBrowserEvent.coordinate[1] - coordinate[1]) / Math.abs(Math.sin(moveAngle)) / 50;
        }
        return l;
    }

    private getMoveCoordinates(moveInfo: any, l: number) {
        const p = moveInfo.overFeature.getGeometry().getCoordinates();
        const x: any = this.getPointX(l, p[0], moveInfo);
        const y: any = this.getPointY(l, p[1], moveInfo);
        moveInfo.overFeature.getGeometry().setCoordinates([x, y])
        return [x, y, 0];
    }

    private getPointX(l: number, x0: number, moveInfo: { moveAngle: number }) {
        const cos = Math.cos(moveInfo.moveAngle * Math.PI / 180);
        if ((moveInfo.moveAngle > 135 && moveInfo.moveAngle < 315 && cos < 0 && l < 0)
            || (moveInfo.moveAngle > 135 && moveInfo.moveAngle < 270 && cos < 0 && l > 0)
            || (moveInfo.moveAngle > 270 && moveInfo.moveAngle < 315 && cos > 0 && l < 0)
            || (moveInfo.moveAngle > 270 && moveInfo.moveAngle < 315 && cos > 0 && l > 0)
        ) {
            l = -l;
        }
        const x = l * cos + x0;
        return x;
    }

    private getPointY(l: number, y0: number, moveInfo: { moveAngle: number }) {
        const sin = Math.sin(moveInfo.moveAngle * Math.PI / 180);
        if ((moveInfo.moveAngle > 180 && moveInfo.moveAngle < 315 && sin < 0 && l < 0)
            || (moveInfo.moveAngle > 180 && moveInfo.moveAngle < 315 && sin < 0 && l > 0)
            || (moveInfo.moveAngle > 135 && moveInfo.moveAngle < 180 && sin < 0 && l < 0)
            || (moveInfo.moveAngle > 135 && moveInfo.moveAngle < 180 && sin > 0 && l > 0)
        ) {
            l = -l;
        }
        const y = l * sin + y0;
        return y;
    }

    private changeMoveAngle(newPointInfo: { moveInfo: any, coord: number[] }) {
        let isStart = false;
        let isEnd = false;
        if (!newPointInfo) {
            return { isStart, isEnd }
        }
        const startP = newPointInfo.moveInfo.startP;
        const endP = newPointInfo.moveInfo.endP;

        const p = newPointInfo.coord;
        if (startP[0] < endP[0]) {
            isStart = p[0] < startP[0]
            isEnd = p[0] > endP[0]
        } else {
            isStart = p[0] > startP[0]
            isEnd = p[0] < endP[0]
        }
        return { isStart, isEnd }
    }

    private getStartEnd(changeMoveInfo: any, feature: Feature<any>, selected: { features: Feature<any>[], coord: number[] }, index: number, featuresIndex: number[], i: number): any {
        const coordinates = feature.getGeometry().getCoordinates();
        const startIndex = coordinates.findIndex((e: number[]) => {
            return e[0] == selected.coord[0] && e[1] == selected.coord[1]
        })
        let startP = null;
        let endP = null;
        if (startIndex == 0) {
            featuresIndex[i] = startIndex;
            if (changeMoveInfo.inStart && this._newPoints[index].moveInfo.featuresIndex[i] > 0) {
                featuresIndex[i] = this._newPoints[index].moveInfo.featuresIndex[i] - 1;
            }
            if (changeMoveInfo.isEnd && this._newPoints[index].moveInfo.featuresIndex[i] > coordinates.length - 2) {
                featuresIndex[i] = this._newPoints[index].moveInfo.featuresIndex[i] + 1;
            }
            if (changeMoveInfo.isEnd && this._newPoints[index] && this._newPoints[index].moveInfo.featuresIndex[i] >= coordinates.length - 2) {
                featuresIndex[i] = this._newPoints[index].moveInfo.featuresIndex[i];
            }
            startP = coordinates[featuresIndex[i]];
            endP = coordinates[featuresIndex[i] + 1];
        } else {
            featuresIndex[i] = startIndex;
            if (changeMoveInfo.inStart && this._newPoints[index].moveInfo.featuresIndex[i] < startIndex) {
                featuresIndex[i] = this._newPoints[index].moveInfo.featuresIndex[i] + 1;
            }
            if (changeMoveInfo.isEnd && this._newPoints[index].moveInfo.featuresIndex[i] > 1) {
                featuresIndex[i] = this._newPoints[index].moveInfo.featuresIndex[i] - 1;
            }
            if (changeMoveInfo.isEnd && this._newPoints[index] && this._newPoints[index].moveInfo.featuresIndex[i] <= 1) {
                featuresIndex[i] = this._newPoints[index].moveInfo.featuresIndex[i];
            }
            startP = coordinates[featuresIndex[i]];
            endP = coordinates[featuresIndex[i] - 1];
        }
        return { startP, endP, featuresIndex }
    }

    private getMoveAngle(selected: { features: Feature<any>[], coord: number[] }, pullAngle: number, index: number): any {
        const changeMoveInfo = this.changeMoveAngle(this._newPoints[index]);
        if (this._newPoints[index] && !changeMoveInfo.isStart && !changeMoveInfo.isEnd) {
            return this._newPoints[index].moveInfo
        }
        let moveAngle = null;
        let featuresIndex: number[] = [];
        const options: { endP: number[], startP: number[] }[] = [];

        selected.features.forEach((feature, i) => {
            const startEndInfo = this.getStartEnd(changeMoveInfo, feature, selected, index, featuresIndex, i);
            featuresIndex = startEndInfo.featuresIndex;
            options.push({
                startP: startEndInfo.startP,
                endP: startEndInfo.endP
            })
        })
        let a: number | null = null;
        let startP = null;
        let endP = null;
        let featureIndex = null;

        options?.forEach((item, i) => {
            const angle = this.getAngle(item.endP, item.startP);
            if (options.length == 1) {
                moveAngle = angle
                startP = item.startP
                endP = item.endP
                featureIndex = i
            }
            let inCludesAngle = angle - pullAngle;

            const length = selected.features[i].getGeometry().getCoordinates().length;

            const mabey = !this._newPoints[index]
                || (this._newPoints[index] && (changeMoveInfo.isStart || changeMoveInfo.isEnd))
                || (this._newPoints[index] &&
                    (
                        featuresIndex[this._newPoints[index].moveInfo.featureIndex] > 0
                        || this._newPoints[index].moveInfo.featuresIndex[this._newPoints[index].moveInfo.featureIndex] > 0
                    )
                    && (
                        featuresIndex[this._newPoints[index].moveInfo.featureIndex] < length - 1
                        || featuresIndex[this._newPoints[index].moveInfo.featureIndex] >= length - 1
                    )
                    && this._newPoints[index].moveInfo.featureIndex == i);


            if (options.length > 1
                && mabey
                && (
                    (inCludesAngle > 0 && inCludesAngle < 90)
                    || (inCludesAngle < 0 && inCludesAngle > -90)
                    || (inCludesAngle > 270 && inCludesAngle < 360)
                    || (inCludesAngle < -270 && inCludesAngle > -360))) {
                if (inCludesAngle < 0 && inCludesAngle > -90) {
                    inCludesAngle = -inCludesAngle
                } else if (inCludesAngle > 270 && inCludesAngle < 360) {
                    inCludesAngle = 360 - inCludesAngle
                } else if (inCludesAngle < -270 && inCludesAngle > -360) {
                    inCludesAngle = 360 + inCludesAngle
                }
                if (a && inCludesAngle > a) {
                    return
                }
                a = inCludesAngle
                moveAngle = angle
                endP = item.endP
                startP = item.startP
                featureIndex = i
            }

        })
        return {
            moveAngle,
            startP,
            endP,
            featuresIndex,
            featureIndex
        }
    }

    private getAngle(p: number[], center: number[]): number {
        const x = p[0] - center[0]
        const y = p[1] - center[1]
        const angle = Math.atan(y / x) * 180 / Math.PI;
        return angle;
    }

    protected onBoxEnd(_: MapBrowserEvent<any>): void {
        const extent = this.getGeometry().getExtent();
        this._sources.forEach((vector: VectorSource<any>) => {
            vector.forEachFeatureIntersectingExtent(extent, feature => {
                let coords = [];
                let type = feature.getGeometry().getType();
                if (type == 'LineString') {
                    coords = feature.getGeometry().getCoordinates();
                }
                if (type == 'Polygon') {
                    coords = feature.getGeometry().getCoordinates().flat();
                }
                coords.forEach((coord: number[]) => {
                    if (this.getGeometry().intersectsCoordinate(coord)) {
                        const i = feature.getGeometry().getCoordinates().findIndex((e: number[]) => {
                            return coord[0] == e[0] && e[1] == coord[1];
                        })
                        if (i === -1 || (i !== 0 && i !== feature.getGeometry().getCoordinates().length - 1)) {
                            return
                        }
                        const selectedFeature = this._selectedFeatures.find((item: any) => JSON.stringify(item.coord) === JSON.stringify(coord));
                        if (selectedFeature) {
                            selectedFeature.features.push(feature);
                            return
                        }
                        this.addVertex({ coord, features: [feature] })
                    }
                })

            })
        })
        this.onLine();
        this.createAssisLine();
    }
}