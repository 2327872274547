
import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import { Feature } from "ol";
import {layerTypeMap} from "@/config/layerConfig";
import { message } from "@/config/message";
import { eventBus } from "@/utils/eventBus";
import {FormModel} from "ant-design-vue";
import {isEmpty} from "@/utils/util";

@Component
export default class CollapseList extends Vue {
    @Prop() currentFeatures!: any[];

    activeKey: any[] = [];
    featureList: any[] = [];
    layerTypeMap: any = layerTypeMap;


    @Watch("currentFeatures")
    change(features: any[]) {
        if (features.length <= 0) return;
        let featureList: any[] = [];
        features.forEach((item: Feature, index: number) => {
            // featureIndex 记录featureIndex 用于重置数据时，找原数据
            let properties: any = {
                ...item.getProperties(),
                featureIndex: index,
                ol_uid: (item as any)['ol_uid']
            };
            // 这里不能给默认，不然用户不知道选没选值
            this.defaultVlue(properties);
            this.multiSelectValue(properties);
            featureList.push(properties);
        });
        this.featureList = featureList;
    }

    multiSelectValue(properties:  any){
      // 对多选属性处理
      if (properties.hasOwnProperty("objectType") && typeof properties["objectType"] == "string" && properties["objectType"].indexOf("[") != -1) {
        properties["objectType"] = JSON.parse(properties["objectType"]);
      }
      if (properties.hasOwnProperty("content") && typeof properties["content"] == "string" && properties["content"].indexOf("[") != -1) {
        properties["content"] = JSON.parse(properties["content"]);
      }
    }

    defaultVlue(obj:any){
        let attribute : object[] = layerTypeMap[obj.layerType].attribute
        attribute.forEach((item : any )=>{
          if(item.default != "" && isEmpty(obj[item.key])){
            obj[item.key] = item.default
          }
        })
    }

  // roadmark 联动切换清除默认选中
  selectChange(feature: any, key : string){
      // roadmark 类型切换，清空默认值
      if(feature['layerType'] == "1005"){
        if(key == 'type'){
          feature['content'] =  feature[key] == 'directional_arrow' ? [] : "";
        }
      }

  }

    formReset(feature: any, index: number) {
        let featureOld: Feature = this.currentFeatures[feature.featureIndex];
        let properties: any  =  {
          ...featureOld.getProperties(),
          featureIndex: feature.featureIndex,
          ol_uid: (featureOld as any)['ol_uid']
        }
        this.multiSelectValue(properties);
        this.$set(this.featureList, index, properties);
    }

    saveData(feature: any, index: number) {
      let formRef =  this.$refs['formRef_'+index] as FormModel[]
      formRef[0].validate((boolean, object) => {
          if (boolean) {
              let featureOld: Feature = this.currentFeatures[feature.featureIndex];
              let properties = {...feature}
              delete properties['featureIndex'];
              delete properties['ol_uid'];
              this.$message.success(message.changeFeatureProperties);
              eventBus.$emit("onFeatureChange", featureOld, properties);
          } else {
             this.$message.error(message.inputError)
             return false;
          }
      });
    }
    handleDelClick(feature: any) {
        let featureOld: Feature = this.currentFeatures[feature.featureIndex];
        eventBus.$emit("onDelFeatureSelectd", featureOld);
    }
    handleIdClick(feature: any) {
         eventBus.$emit('featureHeightById', {id: feature.id, type: feature.layerType, ol_uid: feature.ol_uid})
         this.$emit("selectedFeatureId", feature);
    }
}
