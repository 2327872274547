import {post, get} from '@/utils/request'
import { state } from "@/store"

// 给任务id 指定 defalutReousrceId
export const getReousrceId : any = () => {
    let taskids : any = {
        '10000': "30000",
        '10001': "30000",
        '10002': "30000",
        '30000': "30000",
        '30001': "30000",
        '30002': "30000"
    };
    let reousrceId : string = taskids.hasOwnProperty(state.taskId) ? taskids[state.taskId] : state.taskId
    return reousrceId;
}

export const apiTileList: any = () => {
    return get(`/${getReousrceId()}/tile/tile.json`)
}

export const apiPosList = () => {
    return get(`/${getReousrceId()}/camera/pos_camera.json`)
}

export const autoImportFeature = (taskId: string) => {
    return post(`/api/v1/feature/${taskId}/autoImportFeature`, {})
}

export const hasAutoImportFeature = (taskId: string) => {
    return post(`/api/v1/feature/${taskId}/hasAutoImportFeature`, {})
}

export const featureList = (taskId: string, featureId: string, box: object) => {
    return post(`/api/v1/feature/${taskId}/${featureId}/list`, box)
}

export const saveFeature: any = (data: object) => {
    return post(`/api/v1/feature/saveFeature`, data)
}

// 生成中心道路线  要使用body 传递字符串，需要 JSON.stringify()，以及设置 Content-Type
export const generateCenterLine: any = (data:any) => {
    return post(`/api/v1/feature/generateLaneCenter`, data)
}

// 生成lane_group
export const generateLaneGroupApi: any = (data: any) => {
    return post(`/api/v1/feature/generateLaneGroup`, data)
}

// 获取导出路径
export const getDownloadFileApi: any = (data: any) => {
    return post(`/api/v1/feature/downloadFeatureFile`, data)
}

// 质检通过
export const manulCheck: any = (data: any, token: any) => {
    return post(
        `/api/v1/feature/manulCheck`,
        data,
        token
          ? {
              headers: { Authorization: `Bearer ${token}` },
            }
          : {}
    );
}
//生成关联关系
export const saveFeatureRef: any  = (data : object) => {
    return post(`/api/v1/featureRef/saveFeatureRef`, data)
}

//获取关联关系列表
export const getFeatureRef: any = (featureId: string) => {
    return get(`/api/v1/featureRef/getFeatureRefData?featureId=${featureId}`)
}

//删除关联关系
export const delFeatureRef: any = (data: object) => {
    return post(`/api/v1/featureRef/delFeatureRef`, data)
}

// 获取高高程，获取多个点
export const getHeightMulti: any = (data: any) => {
    let zlevel: number = state.maxZLevel  || 0;
    return post(`/api/v1/height/multi/4326/${state.taskId}/${zlevel}`, data)
}

// 获取高高程，获取单个点
export const getHeightSingle: any = (data: any) => {
    let zlevel: number = state.maxZLevel  || 0;
    return post(`/api/v1/height/single/4326/${state.taskId}/${zlevel}`, data)
}

//获取空中要素信息
export const getAerialFeature: any = (data: object) => {
    // return post(`https://a31q670240.yicp.fun:443/box_to_gps`, data)
    return post(`/box_to_gps`, data)
}

//保存灯组
export const saveLightGroup: any = (data: any) => {
    return post(`/api/v1/trafficLightGroup/saveTrafficLightGroup`, data)
}

// 四个生成
export const generateApi: any = (code: any) => {
    return post(`api/v1/featureRef/${state.taskId}/${code}/saveFeatureRefGlobal`, {})
}

//灯组列表
export const getLightGroupList: any = () => {
    return get(`/api/v1/trafficLightGroup/${state.taskId}/list`)
}

//删除灯组
export const delLightGroup: any = (id: any) => {
    return post(`/api/v1/trafficLightGroup/delTrafficLightGroup`, id)
}

//联通检查、空中要素
export const checkList: any = (type: any) => {
    return get(`/api/v1/feature/${state.taskId}/${type}/featureCheck`)
}
// 生成Lane
export const generateGlobalApi: any = () => {
    return post(`/api/v1/feature/${state.taskId}/generateLaneCenterGlobal`, {})
}

//添加标记
export const addTag: any = (data: any) => {
    return post(`/api/v1/featureFlag/saveFeatureFlag`, data)
}
//删除标记
export const delTag: any = (id: any) => {
    return post(`/api/v1/featureFlag/delFeatureFlag`, id)
}
//获取标记列表
export const getTagList: any = () => {
    return get(`/api/v1/featureFlag/${state.taskId}/list`)
}


// 批量修改属性
export const batchEditFeaturePropertyApi: any = (params: any) => {
    return post(`api/v1/feature/batchEditFeatureProperty`, params)
}

// 导入
export const importFeatureApi: any = (params: any, zlevel: number) => {
    return post(`api/v1/feature/${state.taskId}/${zlevel || 0}/importFeature`, params)
}
