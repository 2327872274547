import axios from 'axios'
import { message } from 'ant-design-vue'
import {message as ms} from "@/config/message"
const request = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // API 请求的默认前缀
    timeout: 30000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error: any) => {
    console.log("errorHandler", error)
    if (error.response) {
        const data = error.response.data

        //请求数据
        // let requestData = JSON.parse(error.response.config.data || '{}');

        if (error.response.status === 403) {
            message.destroy();
            message.error({
                content: data.message
            })
            return Promise.reject(error)
        }

        if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            message.destroy();
            message.error({
                content: 'Authorization verification failed'
            })
            return Promise.reject(error)
        }

        if(data['code'] ===  500) {
            message.destroy();
            message.error({
                content: data['message']
            })
            return Promise.reject(error)
        }

        if (error.response.status === 500) {
            message.destroy();
            message.error({
                content: ms.error500
            })
            return Promise.reject(error)
        }

        if (error.response.config.data) {
            if (error.response.data.code === 500) {
                message.destroy();
                message.error({
                    content: error.response.data.message
                })
                return Promise.reject(error)
            }
        }
        return Promise.reject(error)

    } else {
        return Promise.reject(error)
    }


}

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
request.interceptors.request.use((config: any) => {
    config.headers['Access-Token'] = '111111'
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    if (config.url.indexOf("importFeature") !== -1) {
        config.headers['Content-Type'] = "multipart/form-data"
    }
    return config
}, errorHandler)

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
request.interceptors.response.use((response: any) => {
    let data = response.data;

    // 如果data 不包含code ,则直接认为 请求成功
    if (data.code == undefined ) {
        return data
    }

    // 如果data 包含 code， 且code为200，请求成功
    if (data.code != undefined && data.code == 200) {
        return data.result
    }

    //如果code不为200，说明请求出错，进入错误处理
    return errorHandler({ response: response });

}, errorHandler)

// 封装get和post请求
export function get(url: string, headers: object = {}) {
    url = addTime(url);
    return new Promise(function (resolve, reject) {
        request.get(url, { headers: headers }).then((data: any) => {
            resolve(data);
        }).catch((error: any) => {
            reject(error)
            // console.log("error", error)
        })
    })
}

export function post(url: string, params: any, config: object = {}) {
    url = addTime(url);
    return new Promise(function (resolve, reject) {
        request.post(url, params, config).then((data: any) => {
            resolve(data);
        }).catch((error: any) => {
            reject(error)
            // console.log("error", error)
        })
    })
}

function addTime(url : string) : string{
    if(url.indexOf(".json")!=-1){
        return url;
    }
    if(url.indexOf("?")==-1){
        url = url + "?";
    }
    url = url + "&t=" + new Date().getTime();
    return url;
}

export default request

/**
 import {post,get} from '@/utils/request'

 // 异步
 getdata (){
      get('baoming_serverCode/public/admin/zconfig/getConfig').then(data=>{
        console.log("data",data)
      })

      post('baoming_serverCode/public//api/index/getSchool',{erweima:"--38212345538|879057011863"}).then(data=>{
        console.log("data",data)
      }).catch(error=>{
        console.log("error",error)
      })
 }

 // 同步
 async getdata (){
    let data = await post('baoming_serverCode/public//api/index/getSchool',{erweima:"38212345538|879057011863"})
    console.log(data)
}
 */
