import { Coordinate } from "ol/coordinate"

export default new class {
    public getCoordinates(coordinates: Coordinate[], length: number): Coordinate[] {
        coordinates.map(coord => {
            coord[2] = coord[2] ? coord[2] : 0;
            coord[3] = 0;
        })

        if (coordinates.length < 3) {
            return coordinates;
        }

        let arr: Coordinate[] = coordinates;

        this.getArr(length, [...coordinates], [], (e: Coordinate[]) => {
            arr = e
        })
        return arr
    }

    private getArr(length: number, coordinates: Coordinate[], arr: any[], callback: { (e: Coordinate[]): void }) {
        if (length == 3) {
            arr = arr.concat(this.getArc(coordinates));
            callback(arr);
            return;
        }
        if (coordinates.length <= 3) {
            arr = coordinates;
        } else if (coordinates.length > 3) {
            arr = coordinates.splice(0, 1);
            arr = arr.concat(this.getArc(coordinates));
            coordinates.splice(0, 3);
            arr = arr.concat(coordinates);
        }
        callback(arr);
    }

    private getArc(coordinates: Coordinate[]): Coordinate[] {
        const p1: Coordinate = coordinates[0];
        const p2: Coordinate = coordinates[1];
        const p3: Coordinate = coordinates[2];
        const center: number[] = this.getCenter(p1, p2, p3);

        if (center[0]) {
            const r: number = Math.sqrt(Math.pow(center[0] - p1[0], 2) + Math.pow(center[1] - p1[1], 2));
            const a1: number = this.getAngle(p1, center);
            const a2: number = this.getAngle(p2, center);
            const a3: number = this.getAngle(p3, center);

            const isClockwise: boolean = this.isClockwise(a1, a2);
            return this.getNewCoordinates(a1, a3, r, center, isClockwise);
        } else {
            return coordinates;
        }
    }

    private getCenter(p1: Coordinate, p2: Coordinate, p3: Coordinate): number[] {
        const a = p1[0] - p2[0];
        const b = p1[1] - p2[1];

        const c = p1[0] - p3[0];
        const d = p1[1] - p3[1];

        const e = (Math.pow(p1[0], 2) - Math.pow(p2[0], 2) + Math.pow(p1[1], 2) - Math.pow(p2[1], 2)) / 2;
        const f = (Math.pow(p1[0], 2) - Math.pow(p3[0], 2) + Math.pow(p1[1], 2) - Math.pow(p3[1], 2)) / 2;

        const det = b * c - a * d;
        const x0 = -(d * e - b * f) / det;
        const y0 = -(a * f - c * e) / det;

        return [x0, y0]
    }


    private getAngle(p: Coordinate, center: number[]): number {
        const x: number = p[0] - center[0];
        const y: number = p[1] - center[1];
        let angle = Math.atan(y / x) * 180 / Math.PI;
        if (x > 0 && y < 0) {
            angle = 360 - Math.atan(-y / x) * 180 / Math.PI;
        }
        if (x < 0 && y < 0) {
            angle = Math.atan(y / x) * 180 / Math.PI + 180;
        }
        if (x < 0 && y > 0) {
            angle = 180 - Math.atan(-y / x) * 180 / Math.PI;
        }
        return angle;
    }

    private isClockwise(a1: number, a2: number): boolean {
        if (a1 < 180 && a2 > a1 && a2 < a1 + 180) {
            return false;
        }
        if (a1 >= 180 && ((a2 > 0 && a2 < (a1 - 180) || (a2 > a1 && a2 <= 360)))) {
            return false;
        }
        return true;
    }

    private getXY(angle: number, r: number, center: number[], m = 1): number[] {
        const x = center[0] + Math.cos(Math.PI / 180 * angle) * r;
        const y = center[1] + Math.sin(Math.PI / 180 * angle) * r;
        return [x, y, 0, m]
    }


    private getNewCoordinates(a1: number, a2: number, r: number, center: number[], isClockwise: boolean): Coordinate[] {
        const arr = [this.getXY(a1, r, center, 0)];
        const angle = 10;
        if (isClockwise && a1 > a2) {
            let num = a1;
            for (let i = 0; num > a2; i++) {
                num = (num - angle) > a2 ? num - angle : a2;
                arr.push(this.getXY(num, r, center));
            }
        } else if (isClockwise && a1 < a2) {
            let num = a1;
            for (let i = 0; num > 0; i++) {
                num = (num - angle) > 0 ? num - angle : 0;
                arr.push(this.getXY(num, r, center));
            }
            let num2 = 360;
            for (let i = 0; num2 > a2; i++) {
                num2 = (num2 - angle) > a2 ? num2 - angle : a2;
                arr.push(this.getXY(num2, r, center));
            }
        } else if (!isClockwise && a1 > a2) {
            let num = a1;
            for (let i = 0; num < 360; i++) {
                num = (num + angle) < 360 ? num + angle : 360;
                arr.push(this.getXY(num, r, center));
            }
            let num2 = 0;
            for (let i = 0; num2 < a2; i++) {
                num2 = (num2 + angle) < a2 ? num2 + angle : a2;
                arr.push(this.getXY(num2, r, center));
            }
        } else if (!isClockwise && a1 < a2) {
            let num = a1;
            for (let i = 0; num < a2; i++) {
                num = (num + 10) < a2 ? num + 10 : a2;
                arr.push(this.getXY(num, r, center));
            }
        }
        return arr;
    }
}()
