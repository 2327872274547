
import { Vue, Component } from "vue-property-decorator";
import MovableViewModal from "@/components/MovableView/MovableViewModal.vue"

@Component({
    components: { MovableViewModal }
})
export default class LineOffsetComponent extends Vue {
    public direction: boolean = true;
    public offsetVal: number = 0;

    visible: boolean = false;
     
    showModal() {
        this.visible = true;
        this.offsetVal = 0;
    }

    handleCancel(isSelfClosing: boolean) {
        this.visible = false;
        this.$emit("closeLineOffset", isSelfClosing)
    }

    handleOk() {
        if (this.offsetVal <= 0) {
            this.$message.warn("移动距离过近");
            return;
        } else if (this.offsetVal >= 50) {
            this.$message.warn("移动距离过远");
            return;
        }

        this.$emit("onOffset", this.direction, Number(this.offsetVal));
    }

    openMeasure() {
        this.$emit("lineMeasure");
    }
}
