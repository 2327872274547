import MapServices from "@/views/olMap/service/MapServices";
import Interaction from "ol/interaction/Interaction";


import { Feature, MapBrowserEvent, Collection} from "ol";
import RenderFeature from "ol/render/Feature";
import { LineString, Polygon } from "ol/geom";
import { singleClick } from "ol/events/condition";
import VectorSource, { VectorSourceEvent } from "ol/source/Vector";
import { Layer, VectorImage, Vector as VectorLayer } from "ol/layer";


import lineIntersect from '@turf/line-intersect';
import transformRotate from '@turf/transform-rotate';
import { buffer, lineString, nearestPoint, point, polygonToLine } from "@turf/turf";
import explode from '@turf/explode'
import TLength from '@turf/length'
import TSimplify from '@turf/simplify'


import StyleBuilder from "@/common/extend/StyleBuilder";

// interaction
import Draw from "@/common/Interactions/Draw";
import Snap from "ol/interaction/Snap";
import Select from "@/common/Interactions/Select";
import { DrawEvent } from "ol/interaction/Draw";
import DragBoxSelect from "@/common/Interactions/DragSelect";
import SelectVertices from "@/common/Interactions/SelectVertices";
import UndoRedo from "@/common/Interactions/UndoRedo";
import FillAttribute from "ol-ext/interaction/FillAttribute";
import { noSame } from "@/common/utils/CommonUtil";
import drawFn from "@/common/extend/drawFn";
// 移动工具
import { Translate } from "ol/interaction";

// 修改顶点
import Modify from "@/common/Interactions/Modify";
import ModifyPoint from "@/common/Interactions/ModifyPoint";
import ModifyPoints from "@/common/Interactions/ModifyPoints";
// 打断
import Splitter, { AfterSplitEvent, BeforeSplitEvent } from '@/common/Interactions/Splitter';
import SnapGuides from 'ol-ext/interaction/SnapGuides';

// 合并
import Merge from "@/common/extend/MergeFn";

// 复制
import CopyPaste from '@/common/Interactions/CopyPaste';
import CopyPasteFn from '@/common/extend/CopyPasteFn';

// 测距
import MeasureLine from '@/common/Interactions/MeasureLine';
import { transform3857To4326, transform4326To3857 } from "@/common/utils/projetion";

import { getHeightMulti } from "@/config/api"
import { layersAerial } from "@/config/layerConfig";

export default class InternalService extends MapServices {

    public selectedToolName: string = '';

    // snapTool
    protected snapCollaction!: Collection<Feature<any>>;
    protected onSnapAddFeatureEvent!: (_: VectorSourceEvent<any>) => void;
    protected onSnapRemoveFeatureEvent!: (_: VectorSourceEvent<any>) => void;

    // filter
    protected _filter!: (_: Feature<any> | RenderFeature, layer: Layer<any>) => boolean;

    // selectTool
    private isSelectCurrentLayer: boolean = true;

    // selectVercesTool
    protected selectVerticesList: any = [];
    private isSelecteTool: boolean = false;


    public fillAttr!: FillAttribute;

    // 移动工具
    public translateTool?: Translate;

    // 全图吸附使用
    private isGlobal: boolean = false;

    constructor(options: any) {
        super(options)

        this.initSelectOptions();
        this.initUnRedo();
        this.addFillAttribute();
    }


    // 显示和隐层layer
    setLayerVisible(layer: string[]) {
        super.setLayerVisible(layer);
        this.resetSnapTool();
    }

    // 确认显示和隐藏
    public LayerVisibleChange(layer: any) {
        const source = this.sourceMap.get(layer.key);
        if (this.currentSource == source) {
            if (layer.isVisible) {
                if (!this.snapTool) {
                    this.resetSnapTool()
                }
            } else {
                this.removeSnap();
            }
        }
    }

    // 清空所有的 Interactions
    public clearInteraction() {
        [
            this.drawTool,
            this.selectTool,
            this.dragBoxTool,
            this.selectVerticesTool,
            this.modifyTool,
            this.modifyPointTool,
            this.modifyPointsTool,
            this.splitterTool,
            this.snapGuidesTool,
            this.translateTool,
            this.copyTool,
            this.pointTool
        ].forEach(action => this.removeInteraction(action));
        (this.dragBoxTool as any)?.box_.setMap(null);
        this.removeSnap();
        this.clearSelectVertices();
        this.clearSelectedPoints();
        this.clearMeasureLine();
        if (![
            'Drag',
            'SelectOnlyFeature',
            'SelectAllFeature',
            'SelectOnlyPoint',
            'SelectAllPoint',
            'Splitter',
            'Measure',
            'AddLightGroup',
            'HideLightGroup'
        ].includes(this.selectedToolName)) {
            this.clearSelected();
        }
    }

    // 删除指定interaction
    public removeInteraction(action: Interaction | undefined) {
        if (action) {
            this.olMap.removeInteraction(action)
        }
    }

    // tool切换当前source
    public toolUpdateSource(): void {
        const interactions = this.olMap.getInteractions().getArray().slice(0)
        interactions.forEach((interaction: Interaction) => {
            // 绘画
            if (interaction == this.drawTool) {
                this.drawTool.setSource(this.currentSource)
            }
            // 吸附
            if (interaction == this.snapTool) {
                this.resetSnapTool()
            }
            // 选择行点
            if (interaction == this.selectVerticesTool) {
                this.selectVerticesTool?.setSources(this.currentSource);
            }
            // 拖拽
            if (interaction == this.modifyTool) {
                this.removeInteraction(this.modifyTool);
                this.addModify();
            }
            // 拖拽 线段所有 点
            if (interaction == this.modifyPointsTool) {
                this.modifyPointsTool?.setSources(this.currentSource)
            }

            // 拖拽 线 顶/尾点
            if (interaction == this.modifyPointTool) {
                this.modifyPointTool?.setSources(this.currentSource)
            }

            // 分割
            if (interaction == this.splitterTool) {
                this.removeSplitInteraction();
                this.initSplitter();
            }
            // 复制
            if (interaction == this.copyTool) {
                this.copyTool?.copyClear();
                this.copyTool?.setDestination(this.currentSource);
                this.clearSelected();
            }
        })
    }

    /***************Drag  START ****************** */
    public drag() {
        this.clearInteraction(); // 清空其他工具
    }
    /***************Drag  END ****************** */


    /*************** Draw START ****************** */
    protected initDraw(options: any) {
        this.drawTool = new Draw({
            source: this.currentSource,
            ...options
        });
        this.drawTool.on('drawstart', (e: any) => {
            if (options.drawStart) {
                options.drawStart(e)
            }
        })

        this.drawTool.on('drawend', (e: any) => {
            if (options.drawEnd) {
                let layerType = this.currentSource.get('layerType');
                e.feature.set('layerType', layerType);
                options.drawEnd(e)
            }
        })

        this.olMap.addInteraction(this.drawTool);
        this.initSnap(); // 开启吸附
    }

    protected initTag(options: any) {
        options.source = this.tagSource
        this.pointTool && this.removeInteraction(this.pointTool)
        this.pointTool = new Draw(options);
        this.pointTool.on('drawstart', (e: any) => {
            if (options.drawStart) {
                options.drawStart(e)
            }
        })
        this.pointTool.on('drawend', (e: any) => {
            if (options.drawEnd) {
                options.drawEnd(e)
            }
        })
        this.olMap.addInteraction(this.pointTool);
    }

    /*************** Draw END ****************** */


    /*************** DrawLineString 画线 START ****************** */
    // 画线
    public drawLineString() {
        this.initDraw({
            type: 'LineString',
            drawStart: (event: DrawEvent) => event.feature.setStyle(StyleBuilder.DrawStyle),
            drawEnd: (e: DrawEvent) => {
                e.feature.setStyle(undefined);
                e.feature.set('tag', 0);
                this.trigger("drawEnd", e.feature);
            }
        })

    }
    /*************** DrawLineString 画线 END ****************** */


    /*************** drawPolygon 画面 END ****************** */
    // 画面
    public drawPolygon() {
        this.initDraw({
            type: 'Polygon',
            drawEnd: (e: DrawEvent) => this.trigger("drawEnd", e.feature),
            style: StyleBuilder.drawPolygonStyle
        })

    }
    /*************** drawPolygon 画面 END ****************** */


    /*************** geometryBox 画矩形 END ****************** */
    // 画矩形
    geometryBox() {
        this.initDraw({
            type: 'Polygon',
            geometryFunction: drawFn.createBox,
            style: StyleBuilder.drawPolygonStyle,
            drawEnd: (e: DrawEvent) => this.trigger("drawEnd", e.feature),
            maxPoints: 3,
        })
    }

    // 矩形调整
    polygonModify() {
        this.select(true);
        this.initDragBoxTool();
        this.addModify();
        this.snapGuidesTool = new SnapGuides({
            vectorClass: VectorImage as any
        })
        this.modifyTool && this.snapGuidesTool.setModifyInteraction(this.modifyTool);
        this.olMap.addInteraction(this.snapGuidesTool);
        this.initSnap();
    }
    /*************** geometryBox 画矩形 END ****************** */


    /***************** drawCurve 曲线 START ******************************* */
    drawCurve() {
        this.initDraw({
            type: 'LineString',
            drawStart: (event: DrawEvent) => event.feature.setStyle(StyleBuilder.DrawStyle),
            drawEnd: (e: DrawEvent) => {
                drawFn.curve(e.feature);
                e.feature.setStyle(undefined);
                e.feature.set('tag', 7);
                this.trigger("drawEnd", e.feature);
            }
        })

    }

    /***************** drawCurve 曲线 END ******************************* */



    /****************************** drawArc 圆弧 START  ************************** */
    drawTwoArc() {
        this.initDraw({
            type: 'LineString',
            maxPoints: 2,
            drawStart: (event: DrawEvent) => event.feature.setStyle(StyleBuilder.DrawStyle),
            drawEnd: (event: DrawEvent) => {
                drawFn.drawTwoArc(this.currentSource, event.feature);
                event.feature.setStyle(undefined);
                this.trigger("drawEnd", event.feature);
            },
        })
    }

    drawArc(length: number) {
        this.initDraw({
            type: 'LineString',
            maxPoints: length,
            geometryFunction: (coords: any, geom: any) => drawFn.arc(coords, geom, length),
            drawStart: (event: DrawEvent) => event.feature.setStyle(StyleBuilder.DrawStyle),
            drawEnd: (e: DrawEvent) => {
                e.feature.setStyle(undefined);
                e.feature.set('tag', 6);
                this.trigger("drawEnd", e.feature);
            },
        })

    }


    /****************************** drawArc 圆弧 END  ************************** */



    /*************** Snap START ****************** */
    initSnap() {
        this.snapCollaction = new Collection([], { unique: false });

        this.onSnapAddFeatureEvent = this._onSnapAddFeatureEvent.bind(this);
        this.onSnapRemoveFeatureEvent = this._onSnapRemoveFeatureEvent.bind(this);

        this.isOpenSnap(true);  // 开启

        this.snapTool = new Snap({
            features: this.snapCollaction,
            pixelTolerance: 8
        })
        this.olMap.addInteraction(this.snapTool);
    }

    private _onSnapAddFeatureEvent(e: VectorSourceEvent<any>): void {
        if (e.feature && !this.snapCollaction.getArray().includes(e.feature)) {
            this.snapCollaction.push(e.feature)
        }
    }

    private _onSnapRemoveFeatureEvent(e: VectorSourceEvent<any>): void {
        if (e.feature) {
            this.snapCollaction.remove(e.feature)
        }
    }

    // 重置snap
    private resetSnapTool() {
        this.removeSnap();
        this.initSnap();
    }

    // 清空
    private removeSnap() {
        if (!this.snapTool) {
            return
        }

        this.snapCollaction.clear();
        this.olMap.removeInteraction(this.snapTool);
        this.snapTool = undefined;

        this.isOpenSnap(false);
    }

    private isOpenSnap(isOpen: boolean) {
        if (this.isGlobal) {
            for (const [key, source] of this.sourceMap) {
                if (this.layerKeys.length) {
                    if (this.layerKeys.includes(key)) {
                        this.sourceOnUnSnapEvent(isOpen, source);
                    }
                } else {
                    this.sourceOnUnSnapEvent(isOpen, source);
                }
            }
        } else {
            this.sourceOnUnSnapEvent(isOpen, this.currentSource);
        }
    }

    // source 添加删除 Event
    private sourceOnUnSnapEvent(isOn: boolean, source: VectorSource<any>) {
        if (isOn && source) {
            this.snapCollaction.extend(source.getFeatures());
            source.on('addfeature', this.onSnapAddFeatureEvent)
            source.on('removefeature', this.onSnapRemoveFeatureEvent)
        } else {
            source.un('addfeature', this.onSnapAddFeatureEvent)
            source.un('removefeature', this.onSnapRemoveFeatureEvent)
        }
    }



    // 全图吸附
    fullImageAdsorption(isGlobal: boolean) {
        this.removeSnap();
        this.isGlobal = isGlobal;
        this.initSnap();
    }

    /*************** Snap END ****************** */


    /*************** Select 选择  START ****************** */
    initSelectOptions(): void {
        this._filter = ((feature: Feature<any> | RenderFeature, layer: Layer<any>): any => {
            if (!layer?.getVisible()) {
                return false;
            }
            if (this.isSelectCurrentLayer) {
                return this.currentSource == layer.getSource();
            } else {
                return true
            }
        })
    }

    // 选择要素
    select(isSelected: boolean) {
        this.isSelectCurrentLayer = isSelected;
        if (this.selectTool) {
            this.olMap.addInteraction(this.selectTool);
        } else {
            this.initSelect();
        }
        this.initDragBoxTool();
        this.clearSelectVertices();
        this.isSelecteTool = false;
    }

    // 鼠标点选
    initSelect() {
        this.selectTool = new Select({
            multi: true,
            hitTolerance: 4,
            style: feature => StyleBuilder.BrowerEventStyle((feature as Feature<any>), this.olMap),
            filter: this._filter,
            condition: singleClick
        })
        this.selectTool.on('select', (e: any) => {
            if (this.dragBoxTool && e.selected.length) {
                this.dragBoxTool.addFeature(e.selected[0]);
            }
            this.trigger('onSelect', this.selectTool?.getFeatures())
        })
        this.olMap.addInteraction(this.selectTool);
    }

    // 鼠标框选
    initDragBoxTool() {
        if (this.dragBoxTool) {
            this.olMap.addInteraction(this.dragBoxTool);
            return
        }

        this.dragBoxTool = new DragBoxSelect({
            sources: Array.from(this.sourceMap.values()),
            filter: this._filter
        })
        this.olMap.addInteraction(this.dragBoxTool);

        this.dragBoxTool.on('boxSelect', () => {
            const features = this.dragBoxTool?.selectedFeatures.getArray() || [];
            this.selectTool?.setFeatures(features);
        })
        this.dragBoxTool.on('clearFeatures', () => {
            this.clearSelected();
        })

        this.dragBoxTool?.on('boxend', () => {
            this.modifyTool?.setActive(true);
            this.snapTool?.setActive(true)
        })

        this.dragBoxTool?.on('boxdrag', () => {
            this.modifyTool?.setActive(false);
            this.snapTool?.setActive(false);
        })
    }

    // 清空选中
    clearSelected() {
        this.clearFeatureHeight();
        this.selectTool?.clearFeatures()
    }
    /*************** Select 选择 END ****************** */


    /*************** selectVertices 选择形点 START ****************** */
    selectVertices(isCurrentLayer: boolean) {
        this.isSelectCurrentLayer = isCurrentLayer;
        this.clearSelected();
        this.selectVerticesTool = new SelectVertices({
            sources: this.isSelectCurrentLayer ? [this.currentSource] : Array.from(this.sourceMap.values()),
            hoverStyle: (feature: Feature<any>) => StyleBuilder.BrowerEventStyle(feature, this.olMap),
            filter: this._filter
        })

        this.selectVerticesTool.on('afterSelectVertices', (e: any) => {
            this.selectVerticesList = e.selectVerticesList;
            this.trigger('onselectVertices', e.selectVerticesList);
        })

        this.olMap.addInteraction(this.selectVerticesTool);


        this.undoTool?.define('modifyHeight', (Evt: any) => {
            Evt.feature.getGeometry().setCoordinates(Evt.before);
        }, (Evt: any) => {
            Evt.feature.getGeometry().setCoordinates(Evt.after);
        })
    }

    // 清空顶点
    clearSelectVertices() {
        if (this.selectVerticesTool) {
            this.selectVerticesTool.clearVertex();
            this.selectVerticesList = [];
            this.trigger('onselectVertices', []);
        }
    }
    /*************** selectVertices END ****************** */

    /*************** UnRedoTool START ****************** */
    async coordsinateAddZ(feature: any) {
        const geomType: string = feature.getGeometry().getType();
        let coords: any = feature.getGeometry().getCoordinates();
        let params = null;

        if (geomType == 'Polygon') {
            params = coords[0].map((coord: number[]) => transform3857To4326(coord));
        } else {
            params = coords.map((coord: number[]) => transform3857To4326(coord));
        }
        let result = await getHeightMulti(params);

        if (result) {
            let newCoords3857 = result.map((coord: number[]) => transform4326To3857(coord));
            feature.getGeometry().setCoordinates(geomType == 'Polygon' ? [newCoords3857]: newCoords3857 );
        }
    }

    initUnRedo() {
        this.undoTool = new UndoRedo({
            filter: (source: VectorSource<any>) => Array.from(this.sourceMap.values()).includes(source) && !source.get('readOnly')
        })

        // 修改
        this.undoTool.on('featureChanged', async (e: any) => {
            if (!layersAerial.includes(e.feature.get('layerType'))) {
                this.coordsinateAddZ(e.feature);
            }
        })

        // 添加
        this.undoTool.on('addfeature', (e: any) => {
            if (!layersAerial.includes(e.feature.get('layerType'))) {
                this.coordsinateAddZ(e.feature);
            }
        })

        // 改变坐标
        this.undoTool.define('changeCoordinates', (e: any) => {
            this.trigger("changeCoordinates", e);
            e.feature.getGeometry().setCoordinates(e.before);
        }, (e: any) => {
            e.feature.getGeometry().setCoordinates(e.after);
        })

        this.olMap.addInteraction(this.undoTool);
    }

    addFillAttribute() {
        this.fillAttr = new FillAttribute({ active: false }, {})
        this.olMap.addInteraction(this.fillAttr)
    }

    // undo 上一步，撤消
    undo() {
        this.undoTool?.undo();
    }

    // 还原下一步
    redo() {
        this.undoTool?.redo();
    }
    /*************** UnRedoTool END ****************** */





    /*************** 单点拖拽 - 线条 / 多边形 initModify START ****************** */
    initModify() {
        this.select(true);
        this.addModify();
        this.initSnap();
    }

    addModify() {
        this.modifyTool = new Modify({
            features: this.selectTool?.getFeatures(),
            insertVertexCondition: (e: MapBrowserEvent<any>) => e.originalEvent.ctrlKey
        })

        this.modifyTool.on('modifystart', () => {
            this.modifyTool?.getModifedFeatures().forEach(f => {
                f.set('tag', 4);
                this.onSnapRemoveFeatureEvent({ feature: f } as VectorSourceEvent<any>)
            })
        })

        this.modifyTool.on('modifyend', () => {
            this.modifyTool?.getModifedFeatures().forEach(f => {
                this.onSnapAddFeatureEvent({ feature: f } as VectorSourceEvent<any>)
            })
        })

        this.olMap.addInteraction(this.modifyTool)
    }

    /*************** initModify END ****************** */


    /*************** initModifyPoints START ****************** */
    initModifyPoints() {
        this.modifyPointsTool = new ModifyPoints({
            sources: this.currentSource,
            hoverStyle: (feature: Feature<any>) => StyleBuilder.BrowerEventStyle(feature, this.olMap),
        })
        this.olMap.addInteraction(this.modifyPointsTool)
    }


    private clearSelectedPoints() {
        if (this.modifyPointsTool) {
            this.modifyPointsTool.clearPoints();
        }
    }
    /*************** initModifyPoints END ****************** */


    /*************** tModifyPoint 移动顶点 START ****************** */
    initModifyPoint() {
        this.modifyPointTool = new ModifyPoint({
            sources: this.currentSource,
            hoverStyle: (feature: Feature<any>) => StyleBuilder.BrowerEventStyle(feature, this.olMap),
        })
        this.olMap.addInteraction(this.modifyPointTool);
    }
    /*************** ModifyPoint 移动顶点 END ****************** */


    /************************* Splitter 分割 START ***************/
    initSplitter() {
        if (!this.isSelecteTool) {
            this.isSelecteTool = !this.selectTool || this.selectTool.getFeatures().getArray().length == 0;
        }
        this.initDraw({
            type: 'LineString',
            drawStart: (event: DrawEvent) => {
                event.feature.setStyle(StyleBuilder.splitterStyle());
                if (!this.isSelecteTool) {
                    this.clearSelected();
                }
                this.isSelecteTool = true;
            }
        })
        this.splitterTool = new Splitter({
            source: this.currentSource,
            filter: (feature: Feature<any>) => this.selectTool == undefined || this.selectTool.getFeatures().getArray().length == 0 || this.selectTool.getFeatures().getArray().includes(feature)
        });
        this.splitterTool.setDrawInteraction(this.drawTool);
        this.olMap.addInteraction(this.splitterTool);

        this.snapGuidesTool = new SnapGuides({
            vectorClass: VectorImage as any
        })
        this.snapGuidesTool.setDrawInteraction(this.drawTool as Draw);
        this.olMap.addInteraction(this.snapGuidesTool);

        (this.currentSource as any).on('beforesplit', (e: BeforeSplitEvent) => {
            e.feature.getGeometry().getExtent();
            this.undoTool?.blockStart();
        });
        (this.currentSource as any).on('aftersplit', (e: AfterSplitEvent) => {
            this.selectTool?.setFeatures(e.featureAdded);
            this.undoTool?.blockEnd();
            this.removeInteraction(this.splitterTool)
            this.trigger('splitEnd');
        });
    }

    removeSplitInteraction() {
        if (this.splitterTool) {
            this.olMap.removeInteraction(this.splitterTool);
            this.removeSnap();
        }
        if (this.snapGuidesTool) {
            this.olMap.removeInteraction(this.snapGuidesTool);
        }
    }
    /*********************** Splitter 分割 END ***************/


    /************************* Merge 合并 START ***************/
    initMerge() {
        if (!this.selectTool || !this.selectTool?.getFeatures()) {
            return;
        }

        for (const [_, source] of this.sourceMap) {
            const features: Feature<any>[] = [];
            this.selectTool.getFeatures().forEach(feature => {
                if (source.hasFeature(feature)) {
                    features.push(feature);
                }
            });
            if (features.length > 0) {
                try {
                    Merge.mergeSelectedFeatures(source, features, this.undoTool as UndoRedo, evt => {
                        this.selectTool?.setFeatures(evt.getArray());
                        this.trigger('success', '合并成功');
                    })
                } catch (error) {
                    this.trigger('error', '合并的元素属性不一致');
                }
            }
        }
    }
    /************************* Merge 合并 END ***************/



    /************************* Translate START*************************** */
    initTranslate() {
        this.translateTool = new Translate({
            features: this.selectTool?.getFeatures(),
            hitTolerance: 10
        })
        this.olMap.addInteraction(this.translateTool);
    }
    /************************* Translate END*************************** */

    /****************** CopyPaste 复制 START ************************ */
    initCopyPaste() {
        this.select(true);
        this.initDragBoxTool();
        this.initTranslate();
        this.copyTool = new CopyPaste({
            destination: this.currentSource,
            features: this.selectTool?.getFeatures(),
            filter: (features: Feature<any>[]) => CopyPasteFn.pasteFilter(features, this.currentSource),
            style: StyleBuilder.drawStyle([5, 5])
        })
        this.olMap.addInteraction(this.copyTool);

        (this as any).copyTool.on('sourceChanged', (e: any) => {
            CopyPasteFn.destinationFeatures(e.features, this.currentSource)
        });

        (this as any).copyTool.on('paste', (e: { type: string, features: Feature<any>[] }) => {
            if (e.features.length !== 0) {
                e.features.forEach((f: Feature<any>) => {
                    f.set('tag', 8);
                    f.setStyle(undefined);
                })
            }
        });
    }
    /****************** CopyPaste 复制 END ************************ */


    /*************** Measure 偏移测距 测距 START ************************* */
    initMeasureDistance(measureMode: string, isOrdinary?: boolean) {
        this.clearMeasureLine();

        const source = new VectorSource();
        this.measureVector = new VectorLayer({
            source: source,
            style: StyleBuilder.measureLayerStyle
        })
        this.olMap.addLayer(this.measureVector);

        this.measureDraw = new Draw({
            source: source,
            type: 'LineString',
            style: StyleBuilder.measureSketchStyle
        })
        this.olMap.addInteraction(this.measureDraw);

        this.measureLineTool = new MeasureLine({
            draw: this.measureDraw,
            measureMode: measureMode,
            isOrdinary: isOrdinary
        })
        if (isOrdinary) {
            this.measureLineTool.on('change', (e: any) => {
                this.trigger('MeasureLineChange', e.target)
            })
        }

        this.olMap.addInteraction(this.measureLineTool);

        this.initSnap();
    }

    clearMeasureLine() {
        this.measureVector && this.olMap.removeLayer(this.measureVector);
        this.measureDraw && this.removeInteraction(this.measureDraw);
        this.measureLineTool && this.removeInteraction(this.measureLineTool);
    }
    /*************** Measure 测距 END ************************* */

    /***************************** Offset 偏移 START *************************** */
    initLineOffset(isLeft: boolean, distance: number) {
        const features: any = this.selectTool?.getFeatures().getArray();

        if (!features || !features.length) {
            this.trigger('error', '没有选中要素');
            return;
        }
        let falg: boolean = true;
        features.forEach((feature: Feature<any>) => {
            const geometry = feature.getGeometry();
            const coordinates = geometry.getCoordinates();
            const coords4326 = coordinates.map((coord: number[]) => transform3857To4326(coord));
            const turf_lineString = lineString(isLeft ? coords4326 : coords4326.reverse());
            let turf_length = TLength(turf_lineString, { units: "meters" });

            if (turf_length < distance) {
                falg = false;
                this.trigger('error', '偏移距离过远')
                return false
            }

            const startPoint: number[] = turf_lineString.geometry.coordinates[0];
            const endPoint: number[] = turf_lineString.geometry.coordinates[turf_lineString.geometry.coordinates.length - 1];

            const lsl = transformRotate(turf_lineString, -90, { pivot: startPoint });
            const lel = transformRotate(turf_lineString, 90, { pivot: endPoint });

            const lineBuffer = buffer(turf_lineString, distance, { units: 'meters' });

            const bufferToLine: any = polygonToLine(lineBuffer);
            const points: any = explode(bufferToLine);

            // 相交点
            const intersectPoinArr: any = [];
            const lslIntersect = lineIntersect(lsl, bufferToLine).features;
            const lelIntersect = lineIntersect(lel, bufferToLine).features;

            if (!lelIntersect.length && !lelIntersect.length) {
                falg = false;
                this.trigger('error', '偏移距离过远')
                return false
            }

            const sp: any = { distances: null, coords: [] };
            const ep: any = { distances: null, coords: [] };

            lslIntersect.forEach((f: any) => {
                sp.distances = distance;
                sp.coords = f.geometry.coordinates;
            })

            lelIntersect.forEach((f: any) => {
                ep.distances = distance;
                ep.coords = f.geometry.coordinates;
            });

            intersectPoinArr.push(point(sp.coords));
            intersectPoinArr.push(point(ep.coords));

            const intersectionIndexs: number[] = [];
            intersectPoinArr.forEach((coord: any) => {
                const nearest = nearestPoint(coord, points);
                const index = nearest.properties.featureIndex;
                intersectionIndexs.push(index);
            })

            const isNormal: boolean = intersectionIndexs[0] > intersectionIndexs[1];
            let newLineCoords: number[][] = [];
            if (isNormal) {
                newLineCoords = lineBuffer.geometry.coordinates[0].filter((coord: number[], index: number) => {
                    if (index <= intersectionIndexs[0] && index >= intersectionIndexs[1]) {
                        return coord;
                    }
                })
            } else {
                const points1: number[][] = [];
                const points2: number[][] = [];
                lineBuffer.geometry.coordinates[0].forEach((coord: number[], index: number) => {
                    if (index <= intersectionIndexs[0]) {
                        points1.push(coord);
                    }
                    if (index >= intersectionIndexs[1]) {
                        points2.push(coord);
                    }
                })
                newLineCoords = points2.concat(points1);
            }

            const coords3857: number[][] = newLineCoords.map((coord: number[]) => transform4326To3857(coord));

            const newCoords: number[][] = this.simplifyCoordinates(coords3857);
            const geom3857: LineString = new LineString(isLeft ? newCoords.reverse() : newCoords);
            const newFeature = new Feature({ geometry: geom3857 });

            feature.setStyle(StyleBuilder.DefaultStyle(feature, this.olMap.getView().getZoom(), this.olMap));
            const orgProps = feature.getProperties();
            const newProps: any = {};
            for (const prop in orgProps) {
                if (prop !== 'id' && prop !== 'geometry' && prop !== 'tag') {
                    newProps[prop] = orgProps[prop];
                }
            }
            newFeature.setProperties(newProps);
            newFeature.setStyle(StyleBuilder.DefaultStyle(newFeature, this.olMap.getView().getZoom(), this.olMap));

            this.currentSource.addFeature(newFeature);
        })
        falg && this.trigger('success', '偏移成功');
        falg && this.selectTool?.clearFeatures();
    }
    private simplifyCoordinates(coords: any[]): any[] {
        const length = coords.length;
        if (length == 2) {
            return coords;
        }
        return TSimplify(lineString(coords), { tolerance: 0.05, highQuality: false }).geometry.coordinates
    }
    /***************************** Offset 偏移 END *************************** */

    /***************** Flip 翻转  START ************************* */
    initFlip() {
        if (!this.selectTool || this.selectTool?.getFeatures().getLength() == 0) {
            return;
        }

        const features = this.selectTool.getFeatures();

        features.forEach(feature => {
            const orgCoords = feature.getGeometry().clone().getCoordinates();
            const newCoords = feature.getGeometry().getCoordinates().reverse();

            feature.getGeometry().setCoordinates(newCoords);

            this.undoTool?.push('changeCoordinates', {
                feature: feature,
                before: orgCoords,
                after: newCoords
            })
        })
        this.clearSelected();
        this.trigger('success', '翻转成功')
    }
    /***************** Flip 翻转 END ************************* */


    /*************** Feature Change START ****************** */
    // 删除选中要素
    deleteSelectedFeatures() {
        const features = this.selectTool?.getFeatures();
        if (features) {
            features?.forEach(f => this.deleteFeature(f))
        }
    }

    // 删除单个要素
    deleteFeature(feature: Feature<any>) {
        for (const [_, source] of this.sourceMap) {
            if (source.hasFeature(feature) && !source.get('readOnly')) {
                source.removeFeature(feature)
            }
        }
    }

    // 删除选中顶点
    deleteSelectedPoint() {
        if (this.selectVerticesList) {
            this.selectVerticesList.forEach((item: any) => {
                const data = this.confirmDeletePoint(item.coord, item.feature);
                if (data) {
                    this.clearSelectVertices();
                    this.undoTool?.push('changeCoordinates', data);
                }
            })
        }
    }

    // 删除选中顶点
    private confirmDeletePoint(coords: number[], feature: Feature<any>) {
        const type = feature?.getGeometry().getType();
        const coordArr = type == 'Polygon' ? feature.getGeometry().getCoordinates()[0] : feature.getGeometry().getCoordinates();

        const length = coordArr.length;
        const originCoordArr = type == 'Polygon' ? [[...coordArr]] : [...coordArr];

        const isDeleted = noSame(coords, coordArr[0]) && noSame(coords, coordArr[length - 1]) && ((type == 'Polygon' && length > 4) || (type == 'LineString' && length > 2));

        if (isDeleted) {
            const index = coordArr.findIndex((i: number[]) => JSON.stringify(i) === JSON.stringify(coords));
            coordArr.splice(index, 1);
            let newCoords = coordArr;
            if (type == 'Polygon') {
                newCoords = [coordArr];
                const newPoly = new Polygon(newCoords);
                const area = newPoly.getArea();
                if (area <= 0) {
                    return;
                }
            }
            feature.getGeometry().setCoordinates(newCoords);
            const res = {
                feature: feature,
                before: originCoordArr,
                after: newCoords
            }
            return res
        }
    }

    // 添加tag
    tag() {
        this.clearInteraction()
        this.hideOrVisTag(true)
        this.initTag({
            type: 'Point',
            drawStart: (event: DrawEvent) => event.feature.setStyle(StyleBuilder.tagStyle('orange')),
            drawEnd: (e: DrawEvent) => {
                this.trigger("drawTagEnd", e.feature);
            }
        })
    }

    //   移除标签
    removeTagFeature(feature: any) {
        this.tagSource.removeFeature(feature)
    }

    removeOrSelTagFeature(data: any) {
        this.tagSource.getFeatures().forEach((it: any) => {
            it.setStyle(null)
            if (it.get('id') == data.id) {
                if (data.type == 'del') {
                    this.tagSource.removeFeature(it)
                } else {
                    it.setStyle(StyleBuilder.tagStyle('red'))
                    this.location(it)
                }

            }
        })
    }

    // 隐藏标签
    hideOrVisTag(type: boolean) {
        this.olMap.getLayers().getArray().find((item: any) => item.get('name') == 'tag')?.setVisible(type)
    }
}
