import { OSM, BingMaps, TileDebug } from "ol/source";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ"

const BingMapKey: string = "AjA3nhN_67Db3ndNVHITRMWky6SdKfGIc-x_1hZt7nqCaJbe1vwdcsohqGCasQOA";
const tiandituKey: string = "131763113c2d0e654cccc55318d798b9";

const setMapDefeultLayer = (name: string) => {
    let source: any = null;
    let layerName : string = "";
    let zIndex = 0;
    if (name == "OSM") {
        source = new OSM();
        layerName = "OSM"
        // source = new XYZ({
        //     url: `http://t0.tianditu.com/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=${tiandituKey}`,
        //     crossOrigin: "anonymous",
        // })
    } else if (name == "Aerial") {  // 卫星
        source = new BingMaps({
            key: BingMapKey,
            imagerySet: "Aerial",
            culture: "zh-cn"
        });
        layerName = "Aerial"
    } else if (name == 'TileGrid') {
        source = new TileDebug()
        layerName = "TileGrid"
        zIndex = 100
    }
    let layer = new TileLayer({
        source,
        zIndex,
        properties: { name : layerName }
    });
    layer.setVisible(false);
    return layer;
};

export default setMapDefeultLayer
