
import {Vue, Component, Prop, Ref, Watch, InjectReactive} from "vue-property-decorator";
import {FormModel} from "ant-design-vue";
import {checkMultiSelect} from "@/utils/validates";
import MovableViewModal from "@/components/MovableView/MovableViewModal.vue"
import {eventBus} from "@/utils/eventBus";


@Component({
  components: { MovableViewModal }
})
export default class LightGroupPop extends Vue {
  visible: boolean = false
  currentFeature: any = null
  okLoading: boolean = false
  formDate: any = {
    name: ''
  }
  validatorRules: any = {
    name: [
      {required: true,  message: '请输入灯组名称!'}
    ]
  }

  @InjectReactive() confirmLoading!: boolean

  @Watch('confirmLoading')
  loadinChange(newVal: boolean) {
    this.okLoading = newVal
  }


  handleOk() {
    let formRef = this.$refs.formRef as FormModel
    formRef.validate((boolean, object) => {
      if (boolean) {
        this.okLoading = true
        eventBus.$emit('lightGroupName', this.formDate)
        this.visible = false
        this.formDate.name = ''
      }
    })
  }

  handleCancel() {
    (this.$refs.formRef as FormModel).resetFields()
    this.visible = false
  }

}
