
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class CollapseComponent extends Vue {
    @Prop() title?: string;
    @Prop() icon?: string;
    @Prop() isShow?: boolean;
    @Prop() activeKey?: any;
    @Prop() collKey?: string;
    activeKeys?: any = [];

    @Watch('activeKey', { deep: true, immediate: true })
    change(val: any) {
        this.activeKeys = val;
    }

    activeChange(keys: any) {
        return keys
    }
}
