import { Collection, Feature } from "ol";
import VectorSource from "ol/source/Vector";
import { Coordinate } from "ol/coordinate";
import { LineString } from "ol/geom";
import UndoRedo from "@/common/Interactions/UndoRedo";
import { uniqueCoordinates } from "@/common/utils/CommonUtil"
import { getLayerType } from "@/config/layerConfig";


const FEATURE_PROPS: { [key: string]: string[] } = {
    [getLayerType('boundary')]: [
        'boundaryType',
        'color',
        'lineType',
        'isolation',
        'laneType',
        'layerType',
    ],
    [getLayerType('lane')]: [
        'type',
        'laneType',
        'objectType',
        'leftBoundaryId',
        'rightBoundaryId',
        'layerType',
    ]
}


export default new class {
    mergeSelectedFeatures(source: VectorSource<any>, features: Feature<any>[], undoTool: UndoRedo | null, callback: { (evt: any): void }) {
        const types: any[] = [];
        features.forEach(f => {
            let type = f.getGeometry().getType();
            if (types.indexOf(type) < 0) {
                types.push(type)
            }
        });

        if (types.length == 1) {
            const prop = this.getFirstFeatureProp(features);
            let geometry = null;
            undoTool?.blockStart();
            if (types[0] === 'LineString') {
                const coordinates = [features[0].getGeometry().getCoordinates()];
                if (
                    this.isAllowMerge(
                        features,
                        features[0].getGeometry().getFirstCoordinate(),
                        features[0].getGeometry().getLastCoordinate(),
                        coordinates,
                        []
                    )
                ) {
                    if (!this.isSameProPerties(features)) {
                        throw new Error("Can not merge different features");
                    }
                    features.forEach(feature => {
                        source.removeFeature(feature);
                    })
                    const result = uniqueCoordinates(coordinates.flat());
                    geometry = new LineString(result)
                }
            }

            if (geometry) {
                const f = new Feature({ geometry: geometry });
                f.setProperties(prop);
                f.set('tag', 2);
                source.addFeature(f);

                const features = new Collection([f]);
                callback(features);
            }
            undoTool?.blockEnd();
        }
    }

    private getFirstFeatureProp(features: Feature<any>[]): any {
        const prop = features[0].clone().getProperties();
        delete prop.geometry;
        return prop;
    }



    private isAllowMerge(features: Feature<any>[], firstCoordinate: number[], lastCoordinate: number[], coordinates: Coordinate[], used: number[]): boolean {
        if (features.length == coordinates.length) {
            return true;
        }
        for (let i = 1; i < features.length; i++) {
            if (used.includes(i)) {
                continue;
            }
            const firstXY = features[i].getGeometry().getFirstCoordinate();
            const lastXY = features[i].getGeometry().getLastCoordinate();
            const coords = features[i].getGeometry().clone().getCoordinates();

            if (this.isCoordinateEqual(firstXY, firstCoordinate)) {
                used.push(i);
                coords[0] = firstCoordinate;
                coordinates.unshift(coords.reverse());
                return this.isAllowMerge(features, lastXY, lastCoordinate, coordinates, used);
            }

            if (this.isCoordinateEqual(lastXY, lastCoordinate)) {
                used.push(i);
                coords[coords.length - 1] = lastCoordinate;
                coordinates.push(coords.reverse());
                return this.isAllowMerge(features, firstCoordinate, firstXY, coordinates, used);
            }

            if (this.isCoordinateEqual(firstXY, lastCoordinate)) {
                used.push(i);
                coords[0] = lastCoordinate;
                coordinates.push(coords);
                return this.isAllowMerge(features, firstCoordinate, lastXY, coordinates, used);
            }

            if (this.isCoordinateEqual(lastXY, firstCoordinate)) {
                used.push(i);
                coords[coords.length - 1] = firstCoordinate;
                coordinates.unshift(coords);
                return this.isAllowMerge(features, firstXY, lastCoordinate, coordinates, used);
            }
        }
        return false;
    }

    
    private isSameProPerties(features: Feature<any>[]): boolean {
        const first = features[0];
        const hasProps = FEATURE_PROPS[first.get('layerType')];
        for (let i = 1; i < features.length; i++) {
            for (const prop of hasProps) {
                if (features[i].get(prop) !== first.get(prop)) {
                    return false;
                }
            }
            
        }
        return true;
    }
    
    private isCoordinateEqual(first: number[], end: number[]): boolean {
        return Math.sqrt(Math.pow(first[0] - end[0], 2) + Math.pow(first[1] - end[1], 2)) < 0.5;
    }
}()