
import {Vue, Component, Prop, Ref,Watch} from "vue-property-decorator";
import {Feature} from "ol";
import axios from "axios";
import {posStyle} from "@/common/layer/layer-style-data/posStyle";
import {fabric} from 'fabric'
import {state} from "@/store/index";
import {getAerialFeature} from "@/config/api";
import {layersAerial} from "@/config/layerConfig";

@Component
export default class PosPopup extends Vue {
  showImages: any = {'front': "", 'rear': ""};
  showImgType: string = 'front';
  index: Number = 0
  startPoint: any = {x: 0, y: 0}
  endPoint: any = {x: 0, y: 0}
  rect: any = null
  canvas: any = null
  isShowOkBtn: boolean = false
  isDrawing: Boolean = false
  isShowCanvas: boolean = false
  spinning: boolean = false
  confirming: boolean = false
  currentLightFeature: any = null
  imageName: any = null
  @Prop() isShowPop?: {
    type: Boolean,
    default: false,
  }
  @Prop() selectedPosFea?: Feature
  @Prop() posLayFeatures?: []
  @Prop() selectMenuItem?: any

  @Watch('selectedPosFea')
  posSelChange(newSelPos: Feature) {
    this.currentLightFeature?.setStyle(null)
    this.currentLightFeature = newSelPos
    this.currentLightFeature.setStyle(posStyle.posSel)
    this.loadImg(newSelPos)
  }
  @Watch('showImgType')
  typeChange(newVal:string){
    this.setCanvasBackImg(this.showImages[newVal])
  }

  get isShowDraw() {
    return layersAerial.includes(this.selectMenuItem?.type)
  }

  mounted() {
    this.$nextTick(() => {
    //  初始化canvas
      this.initCanvas()
      window.addEventListener('resize',this.resizeCanvas)
    })
  }

  resizeCanvas(){
    this.canvas.setDimensions({width: window.innerWidth * 0.8, height: window.innerHeight * 0.9});
    this.setCanvasBackImg(this.showImages[this.showImgType])
  }

  initCanvas() {
    this.canvas = new fabric.Canvas('canvas', {
      selection: true,
      selectionColor:'transparent',
      selectionBorderColor:'transparent'
    })
    this.canvas.on('mouse:down',this.canvasMouseDown)
    this.canvas.on('mouse:up',this.canvasMouseUp)
    this.canvas.on('object:moving',this.objectMoving)
  }

  closePop() {
    this.currentLightFeature?.setStyle(null)
    this.$emit('closePop', false)
  }

  //上一张下一张
  LRChange(type: number) {
    let index = this.currentLightFeature?.get('index') + type
    this.currentLightFeature?.setStyle(null)
    if (index < 1) {
      index = this.posLayFeatures!.length
    } else if (index > this.posLayFeatures!.length) {
      index = 1
    }
    let fea:any = this.posLayFeatures!.find((item:Feature) => item?.get('index') == index)
    this.currentLightFeature = fea
    let point=fea.getGeometry().getCoordinates()
    this.currentLightFeature.setStyle(posStyle.posSel)
    this.$emit('currentViewFea',point)
    this.loadImg(fea)
  }

  async loadImg(feature:Feature) {
    let that=this
    let index = feature.get('index')
    this.imageName=feature.get('img')
    this.spinning = true
    await axios.get(`/${state.taskId}/camera/front/${this.imageName.front}`,{responseType:'arraybuffer'}).then((res: any) => {
      this.showImages.front=this.photoBase64(res);
    }).finally(() => {
      axios.get(`/${state.taskId}/camera/rear/${this.imageName.front}`,{responseType:'arraybuffer'}).then((res: any) => {
        this.showImages.rear=this.photoBase64(res);
      }).finally(() => {
        this.spinning = false;
        this.setCanvasBackImg(this.showImages[this.showImgType])
      })
    })
  }
  //给canvas设置背景图片
  setCanvasBackImg(imgUrl:string){
    fabric.Image.fromURL(imgUrl,(image:any)=>{
      image.scaleX=this.canvas.width/image.width
      image.scaleY=this.canvas.height/image.height
      this.canvas.setBackgroundImage(image)
      this.canvas.renderAll()
    })
  }

  //图片转base64
  photoBase64(res:any){
    return (
        "data:image/png;base64," +
        btoa(
            new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
            )
        )
    )
  }
  // 弹框拖拽
  mouseDown(e: MouseEvent) {
    let that = this
    e.stopPropagation()
    let divEle = (this.$refs.posPopup as HTMLDivElement)
    let disX = e.clientX - divEle.offsetLeft
    let disY = e.clientY - divEle.offsetTop

    document.addEventListener('mousemove', move)

    function move(e: MouseEvent) {
      let left = e.clientX - disX
      let top = e.clientY - disY
      divEle.style.left = left + 'px'
      divEle.style.top = top + 'px'
    }

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', move)
    })

  }
  fullScreen(type:string){
    this.resizeCanvas()
    this.isShowCanvas=true
    this.isDrawing = type == 'paint'
    this.isShowOkBtn = type == 'paint'
    if(type=='paint'){
      this.canvas.selectionBorderColor='red'
    }else{
      this.canvas.selectionBorderColor='transparent'
    }
  }
  closeFullScreen(){
    this.isShowCanvas=false
    this.clearCanvas()
  }

  canvasMouseDown(evt:fabric.IEvent<MouseEvent>){
    this.startPoint=evt.absolutePointer
    if(this.isShowOkBtn){
      this.isDrawing = this.canvas.getActiveObject() ? false : true
    }
  }
  canvasMouseUp(evt:fabric.IEvent<MouseEvent>){
    this.endPoint=evt.absolutePointer
    if(this.isShowOkBtn && this.isDrawing){
      this.initRect()
      this.startPoint=null
      this.endPoint=null
    }
  }

  objectMoving(evt: fabric.IEvent<MouseEvent>) {
    let seleRect = this.canvas.getActiveObject()
    let rectBoundingRect = seleRect.getBoundingRect()
    if (Math.round(rectBoundingRect.left) < 0) {
      seleRect.left = 0
    } else if (Math.round(rectBoundingRect.left + rectBoundingRect.width) > this.canvas.width) {
      seleRect.left = this.canvas.width - rectBoundingRect.width
    }
    if (Math.round(rectBoundingRect.top) < 0) {
      seleRect.top = 0
    } else if (Math.round(rectBoundingRect.top + rectBoundingRect.height) > this.canvas.height) {
      seleRect.top = this.canvas.height - rectBoundingRect.height
    }
    this.canvas.renderAll()
  }

//  初始化矩形
  initRect(){
    if (!this.startPoint || !this.endPoint ||(this.startPoint && this.endPoint && this.startPoint == this.endPoint)) {
      return
    }
    this.endPoint.x = this.endPoint.x > this.canvas.width ? this.canvas.width : this.endPoint.x < 0 ? 0 : this.endPoint.x
    this.endPoint.y = this.endPoint.y > this.canvas.height ? this.canvas.height : this.endPoint.y < 0 ? 0 : this.endPoint.y
    let rect=new fabric.Rect({
        top:Math.min(this.startPoint.y, this.endPoint.y),
        left:Math.min(this.startPoint.x, this.endPoint.x),
        width:Math.abs(this.startPoint.x - this.endPoint.x),
        height:Math.abs(this.startPoint.y - this.endPoint.y),
        fill:'transparent',
        stroke:'red',
        borderColor:'#4562E3'
    })
    rect.setControlsVisibility({
      ml:false,
      mb:false,
      mr:false,
      mt:false,
      mtr:false,
      bl:false,
      br:false,
      tl:false,
      tr:false
    })
    this.canvas.add(rect)
  }

  okHandle(){
    this.isDrawing = true
    let rectArr = this.canvas.getObjects()
    let coordinatesArr: number[][] = []
    if (!rectArr.length) {
      this.$message.warning('请先绘制矩形')
      return
    }
    rectArr.forEach((item: any) => {
      coordinatesArr.push(this.getRectInImgPostion(item))
    })
    let params = {
      imgName: this.imageName[this.showImgType],
      // rootDir: "./data",
      scenePath: "/home/lthpc/maptool/html/" + this.$route.query.taskId,
      boxes: coordinatesArr
    }
    this.confirming = true
    getAerialFeature(params).then((res: any) => {
      if (res.msg == 'success') {
        let num = 0
        res?.data.forEach((item: any, index: number) => {
          if (!item.length) {
            num++
            this.canvas.remove(rectArr[index])
          }
        })
        if (num > 0) {
          this.$message.warning(`当前 ${num} 个矩形无点云数据！`)
          return
        }
        this.$message.success('添加成功')
        this.$emit('AerialFeature', res?.data)
        this.clearCanvas()
        this.isShowCanvas = false
      }
    }).finally(()=> this.confirming = false)
  }

//  获取矩形在原图片的位置信息
  getRectInImgPostion(rect: any) {
    let imageInfo = this.canvas.backgroundImage
    let rectInImgLeft = rect.left / imageInfo.scaleX
    let rectInImgTop = rect.top / imageInfo.scaleY
    let rectInImgW = rect.width / imageInfo.scaleX
    let rectInImgH = rect.height / imageInfo.scaleY
    return [rectInImgLeft, rectInImgTop, rectInImgLeft + rectInImgW, rectInImgTop + rectInImgH]
  }

//  清空画布
  clearCanvas(){
    this.canvas.remove(...this.canvas.getObjects())
  }
  clearCanBtn(){
    let selectedRect = this.canvas.getActiveObject()
    if (selectedRect) {
      this.canvas.remove(selectedRect)
    } else {
      this.clearCanvas()
    }
    this.isDrawing = true
  }


}
