class Storage { 
    setLocal(key: string, value: any) {
        window.localStorage.setItem(key, JSON.stringify(value))
    }
    getLocal(key: string) {
        let value = window.localStorage.getItem(key);
        if (value) {
            return JSON.parse(value)
        }
        return ''
    }
}

export default new Storage()