import { PluggableMap, Feature, Collection, MapBrowserEvent } from "ol";
import { SelectEvent } from "ol/interaction/Select";
import Interaction, { InteractionOnSignature } from "ol/interaction/Interaction";

import RenderFeature from "ol/render/Feature";
import { Layer } from "ol/layer";

import { singleClick } from "ol/events/condition";
import { EventsKey } from "ol/events";
import { Style } from "ol/style";

import StyleBuilder from "@/common/extend/StyleBuilder"
import { getLayerType } from "@/config/layerConfig"

interface SelectOptions {
    hitTolerance?: number;
    style?: Style | Style[] | ((_: Feature<any> | RenderFeature) => Style | Style[]);
    filter?: (_: Feature<any> | RenderFeature, layer: Layer<any>) => boolean;
    multi?: boolean;
    condition?: (_: MapBrowserEvent<any>) => boolean;
    unHighLight?: boolean;
}

type SelectOnSignature<Return> = InteractionOnSignature<EventsKey> & ((type: 'select' | ('select')[], listener: (event: SelectEvent) => any) => Return)

export default class Select extends Interaction {
    private readonly _hitTolerance: number;
    private readonly _unHighLight: boolean;
    private readonly _style: Style | Style[] | ((_: Feature<any> | RenderFeature) => Style | Style[]);

    private readonly _filter: (_: Feature<any> | RenderFeature, layer: Layer<any>) => boolean;
    private readonly _condition: (_: MapBrowserEvent<any>) => boolean;
    private readonly _onMapClick: (_: MapBrowserEvent<any>) => void;

    protected readonly _selectedFeatures: Collection<Feature<any>> = new Collection<Feature<any>>();

    declare on: SelectOnSignature<EventsKey>

    constructor(options: SelectOptions) {
        super();

        this._hitTolerance = options.hitTolerance || 4;
        this._style = options.style || StyleBuilder.SelectDefault;
        this._unHighLight = options.unHighLight || false;

        this._filter = options.filter || (() => true);
        this._condition = options.condition || singleClick;
        this._onMapClick = this.onMapClick.bind(this)
    }

    setMap(map: PluggableMap) {
        if (this.getMap()) {
            this.getMap()?.un('singleclick', this._onMapClick);
        }
        if (map) {
            map.on('singleclick', this._onMapClick);
        }
        super.setMap(map);
    }

    onMapClick(mapBrowserEvent: MapBrowserEvent<any>) {
        if (this._condition(mapBrowserEvent)) {
            const selected: Feature<any>[] = [];
            this.getMap()?.forEachFeatureAtPixel(mapBrowserEvent.pixel, (f, layer) => {
                const feature = f as Feature<any>;
                if (this._filter(feature, layer) && !this.isInCludes(feature)) {
                    if (!this._unHighLight) {
                        feature.setStyle(this._style)
                    }
                    this._selectedFeatures.push(feature);
                    selected.push(feature);
                } else if (mapBrowserEvent.originalEvent.shiftKey) {
                    this.removeFeature(feature);
                }
            }, { hitTolerance: this._hitTolerance, layerFilter: () => true })
            // 创建选择功能
            this.dispatchEvent(new SelectEvent('select', selected, [], mapBrowserEvent));
        }
    }

    removeFeature(feature: Feature<any>) {
        this._selectedFeatures.remove(feature);
        feature.setStyle(undefined);
        this.dispatchEvent(new SelectEvent('select', [], [feature], <any>{}));
    }

    addFeature(...features: Feature<any>[]) {
        features = features.filter((f: Feature<any>) => !this.isInCludes(f));
        if (!this._unHighLight) {
            features.forEach((f: Feature<any>) => f.setStyle(this._style));
        }
        this._selectedFeatures.extend(features);
        this.dispatchEvent(new SelectEvent('select', features, [], <any>{}));
    }

    setFeatures(features: Feature<any>[]) {
        this._selectedFeatures.forEach((f: Feature<any>) => f.setStyle(undefined));
        this._selectedFeatures.clear();
        if (!this._unHighLight) {
            features.forEach((f: Feature<any>) => f.setStyle(this._style));
        }
        this._selectedFeatures.extend(features);
        this.dispatchEvent(new SelectEvent('select', features, [], <any>{}));
    }

    getFeatures(): Collection<Feature<any>> {
        return this._selectedFeatures
    }


    clearFeatures(): void {
        const dfs = this._selectedFeatures.getArray().slice();
        this._selectedFeatures.forEach((f: Feature<any>) => f.setStyle(undefined));
        this._selectedFeatures.clear();
        this.dispatchEvent(new SelectEvent('select', [], dfs, <any>{}));
    }

    setStyle(feature: Feature<any>) {
        if (!this._unHighLight && this.isInCludes(feature)) {
            feature.setStyle(this._style);
        } else {
            feature.setStyle(undefined);
        }
    }


    isInCludes(f: Feature<any>): boolean {
        return this._selectedFeatures.getArray().includes(f)
    }

    // 获取要素指定属性
    getFeaturesProp(key: string): string[] {
        return this._selectedFeatures.getArray().map((feature: Feature<any>) => feature.get(key)) || []
    }

    // 获取指定属性
    getSpecifyLayerTypeFeaturesProp(layerTitle: string, key: string): string[] {
        let data: string[] = []
        this._selectedFeatures.forEach((feature: Feature<any>) => {
            if (feature.get('layerType') == getLayerType(layerTitle)) {
                data.push(feature.get(key))
            }
        })
        return data
    }

}