import MapService from "@/views/olMap/service/InternalService"

export const menus : object = {
    'Drag': {
        name: 'Drag',
        icon: 'tool-drag',
        desc: '移动',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.drag()
    },
    'Select': {
        name: 'Select',
        icon: 'tool-select',
        iconSelected: 'tool-select',
        desc: '选择',
        needSelected: true,
        children: [
            {
                name: 'SelectOnlyFeature',
                icon: 'tool-current-layer',
                desc: '当前图层要素',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.select(true)
            },
            {
                name: 'SelectAllFeature',
                icon: 'tool-all-layer',
                desc: '所有图层要素',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.select(false)
            },
            {
                name: 'SelectOnlyPoint',
                icon: 'tool-currentLayer-point',
                desc: '当前图层顶点',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.selectVertices(true)
            },
            {
                name: 'SelectAllPoint',
                icon: 'tool-currentLayer-allPoint',
                desc: '所有图层顶点',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.selectVertices(false)
            }
        ]
    },
    'ModifyPoint': {
        name: 'ModifyPoint',
        icon: 'tool-smooth',
        iconSelected: 'tool-smooth',
        desc: '形点',
        needSelected: true,
        children: [
            {
                name: 'OnlyModifyPoint',
                icon: 'tool-singlePoint',
                desc: '形点工具（单点）',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.initModify(),
            },
            {
                name: 'MoreModifyPoint',
                icon: 'tool-multiPoint',
                desc: '形点工具（多点）',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.initModifyPoints(),
            },
            {
                name: 'MoveModifyPoint',
                icon: 'tool-movePoint',
                desc: '移动顶点',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.initModifyPoint(),
            },
        ]
    },

    'ModifyPolyPoint': {
        name: 'ModifyPoint',
        icon: 'tool-smooth',
        iconSelected: 'tool-smooth',
        desc: '形点',
        needSelected: true,
        children: [
            {
                name: 'OnlyModifyPoint',
                icon: 'tool-singlePoint',
                desc: '形点工具（单点）',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.initModify(),
            },
            {
                name: 'MoreModifyPoint',
                icon: 'tool-multiPoint',
                desc: '形点工具（多点）',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.initModifyPoints(),
            }
        ]
    },
    'LineString': {
        name: 'LineString',
        icon: 'tool-line',
        desc: '画线',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.drawLineString()
    },
    'Curve': {
        name: 'Curve',
        icon: 'tool-curve',
        desc: '曲线',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.drawCurve()
    },
    'Arc': {
        name: 'Arc',
        icon: 'tool-arc',
        iconSelected: 'tool-arc',
        desc: '圆弧',
        needSelected: true,
        children: [
            {
                name: 'TwoArc',
                icon: 'tool-art-At-2-point',
                desc: '2点绘图',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.drawTwoArc()
            },
            {
                name: 'ThreeArc',
                icon: 'tool-art-At-3-point',
                desc: '3点绘图',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.drawArc(3)
            },
            {
                name: 'FiveArc',
                icon: 'tool-art-At-5-point',
                desc: '5点绘图',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.drawArc(5)
            }
        ]
    },
    'Splitter': {
        name: 'Splitter',
        icon: 'tool-splitter',
        desc: '分割工具',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.initSplitter()
    },
    'Polygon': {
        name: 'Polygon',
        icon: 'tool-paint',
        desc: '画面',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.drawPolygon()
    },
    "Rectangle": {
        name: 'Rectangle',
        icon: 'tool-rect',
        iconSelected: 'tool-rect',
        desc: '矩形工具',
        needSelected: true,
        children: [
            {
                name: 'ThreeRectangle',
                icon: 'tool-rect3Point',
                desc: '矩形工具（三点矩形）',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.geometryBox()
            },
            {
                name: 'AmendRectangle',
                icon: 'tool-rectAdjustment',
                desc: '矩形调整',
                needSelected: true,
                function: (_self: any, mapService: MapService) => mapService.polygonModify()
            }
        ]
    },
    'Merge': {
        name: 'Merge',
        icon: 'tool-merge',
        desc: '合并',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.initMerge()
    },
    'CopyFeature': {
        name: 'CopyFeature',
        icon: 'tool-copyFeature',
        desc: '复制要素',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.initCopyPaste(),
    },
    'Offset': {
        name: 'Offset',
        icon: 'tool-offset',
        desc: '偏移',
        needSelected: true,
        function: (_self: any) => _self.openLineOffset()
    },
    'Flip': {
        name: 'Flip',
        icon: 'tool-overturn',
        desc: '翻转',
        needSelected: false,
        function: (_self: any, mapService: MapService) => mapService.initFlip()
    },
    'Label': {
        name: 'Label',
        icon: 'tool-label',
        iconSelected: 'tool-label',
        desc: '标签',
        children: [
            {
                id: 1,
                name: 'ShowLabel',
                icon: 'tool-show-label',
                desc: '查看标签',
                needSelected: true,
                function: (_self: any, mapService: MapService) => _self.hideOrViewTag(true)
            },
            {
                id: 0,
                name: 'AddLabel',
                icon: 'tool-add-label',
                desc: '添加标签',
                needSelected: true,
                function: (_self: any, mapService: MapService) => _self.hideOrViewTag(true, 'add')
            },
            {
                id: 2,
                name: 'HideLabel',
                icon: 'tool-hide-label',
                desc: '隐藏标签',
                needSelected: true,
                function: (_self: any, mapService: MapService) => _self.hideOrViewTag(false)
            },
        ]
    },
    'Measure': {
        name: 'Measure',
        icon: 'tool-measure',
        iconSelected: 'tool-measure',
        desc: '测距',
        needSelected: true,
        function: (_self: any, mapService: MapService) => mapService.initMeasureDistance('length'),
    },
    'Batch': {
        name: 'Batch',
        icon: 'tool-batch',
        desc: '批量',
        signs: ['提醒：请确保已保存要素', '自动化工具仅对已保存要素生效'],
        needSelected: false,
        function: (_self: any) => _self.bach()
    },
    'Sorption': {
        name: 'Sorption',
        icon: 'tool-sorption',
        iconSelected: 'tool-sorption',
        desc: '吸附',
        checked: false,
        toggle: true,
        needSelected: false,
        function: function (_self: any, mapService: MapService) {
            mapService.fullImageAdsorption(this.checked)
        }
    },
    'LightGroup': {
        name: 'LightGroup',
        icon: 'tool-lightGroup',
        iconSelected: 'tool-lightGroup',
        desc: '灯组',
        children: [
            {
                id: 1,
                name: 'ShowLightGroup',
                icon: 'tool-lightGroup-show',
                desc: '查看灯组',
                needSelected: true,
                function: (_self: any) => _self.viewLightGroup(true)
            },
            {
                id: 0,
                name: 'AddLightGroup',
                icon: 'tool-lightGroup-add',
                desc: '添加灯组',
                needSelected: true,
                function: (_self: any) => _self.addLightGroup()

            },

            {
                id: 2,
                name: 'HideLightGroup',
                icon: 'tool-lightGroup-hide',
                desc: '隐藏灯组',
                needSelected: true,
                function: (_self: any) => _self.viewLightGroup(false)
            }
        ]
    },
    'Automatic': {
        name: 'Automatic',
        icon: 'tool-automatic',
        iconSelected: 'tool-automatic',
        desc: '生成',
        signs: ['提醒：请确保已保存要素', '自动化工具仅对已保存要素生效'],
        needSelected: false,
        children: [
            {
                id: 0,
                name: 'GenerateLineSequence',
                icon: 'tool-generate-line-sequence',
                desc: '生成车道组',
                needSelected: false,
                function: (_self: any, mapService: MapService) => mapService.generateLaneGroup(),
            },
            {
                id: 1,
                name: 'GenerateLine',
                icon: 'tool-generate-line',
                desc: '生成车道中心线',
                needSelected: false,
                function: (_self: any, mapService: MapService) => mapService.generateLaneCenter(),
            },
            {
                id: 2,
                name: 'GenerateLineGlobal',
                icon: 'tool-generate-lane',
                desc: '生成 Lane',
                needSelected: false,
                function: (_self: any, mapService: MapService) => mapService.generateLaneCenterGlobal(),
            },
            {
                id: 3,
                name: 'GenerateLineRelationship',
                icon: 'tool-generate-line-relationship',
                desc: '生成 lane_group_ref',
                needSelected: false,
                function: (_self: any, mapService: MapService) => mapService.generate("10081", 'lane_group_ref'),
            },
            {
                id: 4,
                name: 'GenerateGroundSignageRelationship',
                icon: 'tool-generate-groundSignage-relationship',
                desc: '生成 lane_ref',
                needSelected: false,
                function: (_self: any, mapService: MapService) => mapService.generate("10021", 'lane_ref'),

            },
            {
                id: 5,
                name: 'GenerateTrafficLightRelationship',
                icon: 'tool-generate-trafficLight-relationship',
                desc: '生成 connectivity_area_ref',
                needSelected: false,
                function: (_self: any, mapService: MapService) => mapService.generate("10031", 'connectivity_area_ref'),

            },
            {
                id: 6,
                name: 'GenerateConnectedRegionRelationship',
                icon: 'tool-generate-connectedRegion-relationship',
                desc: '生成 roadmark_ref',
                needSelected: false,
                function: (_self: any, mapService: MapService) => mapService.generate("10051", 'roadmark_ref'),
            }
        ]
    },
    'Inspection': {
        name: 'Inspection',
        icon: 'tool-lnspection',
        iconSelected: 'tool-lnspection',
        desc: '检查',
        needSelected: false,
        children: [
            {
                id: 0,
                name: 'UnicomInspection',
                icon: 'tool-lnspection-unicom',
                desc: '联通检查',
                needSelected: false,
                function: (_self: any) => _self.check('floor'),
            },
            {
                id: 1,
                name: 'AerialElements',
                icon: 'tool-lnspection-arial',
                desc: '空中要素',
                needSelected: false,
                function: (_self: any) => _self.check('air')
            }
        ]
    },
    'DataExport': {
        name: 'DataExport',
        icon: 'tool-dataExport',
        desc: '导出',
        needSelected: false,
    },
    'DataDmport': {
        name: 'DataDmport',
        icon: 'tool-dataDmport',
        desc: '导入',
        needSelected: false,
        function: (_self: any) => _self.dataDmportShow()
    },

}
export const comMenusS : string[] = ['Drag', 'Select']
export const comMenusE : string[] = ['Label', 'Measure', 'Batch', 'Automatic', 'Inspection', 'DataExport', 'DataDmport']
