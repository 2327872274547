import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { LineString, Point, Polygon } from "ol/geom"

import simplify from "@turf/simplify"
import { lineString } from "@turf/helpers"

import smooth from 'chaikin-smooth'
import arcLine from "@/common/extend/ArcLineFn"
import { writeFeatureObject } from "@/common/utils/projetion";
import { getCoord, getCoords } from "@turf/invariant"
import { transform } from "ol/proj"
import lineIntersect from "@turf/line-intersect"


export default new class {
    public drawTwoArc(currentSource: any, f: Feature<any>) {
        const geometry: any = f.getGeometry();
        const coordinates: any[] = geometry.getCoordinates();
        const transformCoords: any[] = coordinates.map((coord: any) => transform(coord, 'EPSG:3857', 'EPSG:4326'))
        const firstPoint: any = new Point(coordinates[0]);
        const lastPoint: any = new Point(coordinates[1]);
        const firstExtent: any = firstPoint.getExtent();
        const lastExtent: any = lastPoint.getExtent();
        const firstInterFeature: any = this.intersectingExtent(currentSource, firstExtent);
        const lastInterFeature: any = this.intersectingExtent(currentSource, lastExtent);


        let firstLine: any = null;
        firstInterFeature.forEach((item: any) => {
            if (transformCoords[0][0] == item.lastCoords[0]) {
                let lastCoordinate: any = item.originCoords[item.originCoords.length - 1];
                let secondLastCoordinate: any = item.originCoords[item.originCoords.length - 2];
                const direction: any[] = this.directionVector(lastCoordinate, secondLastCoordinate);

                const newStartCoordinate = [
                    item.originCoords[item.originCoords.length - 1][0] + direction[0] * 100,
                    item.originCoords[item.originCoords.length - 1][1] + direction[1] * 100
                ];

                item.originCoords.push(newStartCoordinate);
                let newCoords: any = item.originCoords.map((coord: any) => transform(coord, 'EPSG:3857', 'EPSG:4326'))
                firstLine = lineString(newCoords);
            }
        })

        let lastLine: any = null;
        lastInterFeature.forEach((item: any) => {
            if (transformCoords[1][0] == item.firstCoords[0]) {

                let lastCoordinate: any = item.originCoords[0]
                let secondLastCoordinate: any = item.originCoords[1]
                const direction: any[] = this.directionVector(lastCoordinate, secondLastCoordinate);

                const newStartCoordinate = [
                    item.originCoords[0][0] + direction[0] * 100,
                    item.originCoords[0][1] + direction[1] * 100
                ];

                item.originCoords.unshift(newStartCoordinate);
                let newCoords: any = item.originCoords.map((coord: any) => transform(coord, 'EPSG:3857', 'EPSG:4326'))
                lastLine = lineString(newCoords);
            }
        })

        if (!firstLine || !lastLine) return;

        // 交点
        const interPoint: any[] = lineIntersect(firstLine, lastLine).features;

        if (!interPoint.length) return;
        const pointCoord: any[] = getCoord(interPoint[0]);
        transformCoords.splice(1, 0, pointCoord);

        let newLineCoords: any[] = transformCoords.map((coord: any) => transform(coord, 'EPSG:4326', 'EPSG:3857'));
        let numInteractions = 3;
        while (numInteractions > 0) {
            newLineCoords = smooth(newLineCoords);
            numInteractions--;
        }
        if (newLineCoords.length > 4) {
            newLineCoords.splice(1, 1);
            newLineCoords.splice(newLineCoords.length - 2, 1)
        }
        geometry.setCoordinates(newLineCoords);
    }

    // 计算方向向量（差值）
    public directionVector(coord1: any[], coord2: any[]) {
        const direction = [
            coord1[0] - coord2[0],
            coord1[1] - coord2[1]
        ];
        return direction;
    }

    public intersectingExtent(currentSource: any, exent: any): any[] {
        let features: any = [];
        currentSource.forEachFeatureIntersectingExtent(exent, (feature: any) => {
            const geom: any = writeFeatureObject(feature);
            const transformCoords: any[] = getCoords(geom);
            const coordinates: any = feature.getGeometry().getCoordinates();
            features.push({
                feature: feature,
                originCoords: coordinates,
                firstCoords: transformCoords[0],
                lastCoords: transformCoords[transformCoords.length - 1],
                geometry: geom
            })
        });
        return features
    }

    public curve(f: Feature<any>) {
        const geometry = f.getGeometry();
        let coords = geometry.getCoordinates();
        let numInteractions = 3;
        while (numInteractions > 0) {
            coords = smooth(coords);
            numInteractions--;
        }
        if (coords.length > 4) {
            coords.splice(1, 1);
            coords.splice(coords.length - 2, 1)
        }
        const options = {
            tolerance: 0.03,
            highQuality: false
        }
        const line = lineString(coords);

        const simp = simplify(line, options);
        const OldCoords = geometry.getCoordinates();
        const newCoords = simp.geometry.coordinates;
        newCoords[0][2] = OldCoords[0][2];
        newCoords[newCoords.length - 1][2] = OldCoords[OldCoords.length - 1][2];
        geometry.setCoordinates(newCoords);
    }

    public createBox(coords: Coordinate[][], geometry: Polygon): Polygon {
        if (!geometry) {
            geometry = new Polygon([]);
        }
        geometry.setCoordinates(coords);

        if (coords[0].length == 3) {
            const p1 = coords[0][0];
            const p2 = coords[0][1];
            const p3 = coords[0][2];
            const fm = (p2[0] - p1[0]) == 0 ? 0.000001 : (p2[0] - p1[0]);
            const k = (p2[1] - p1[1]) / fm;
            const f = (p: number[]) => {
                const x = (p[0] * (k * k + 1) - k * k * (p[0] - p3[0]) + (p[1] - p3[1]) * k) / (k * k + 1);
                const y = x * k + p3[1] - p3[0] * k;
                return [x, y]
            }
            geometry.setCoordinates([[p1, p2, f(p2), f(p1), p1]]);
        }
        return geometry;
    }


    public arc(coords: Coordinate[], geometry: LineString, length: number): LineString {
        if (!geometry) {
            geometry = new LineString([])
        }
        geometry.setCoordinates(arcLine.getCoordinates(coords, length));
        return geometry;
    }
}()