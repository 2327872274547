import BoundaryStyle from "@/common/layer/layer-style-data/BoundaryStyle"
import TrafficSignStyle from "@/common/layer/layer-style-data/TrafficSignStyle"
import defaultStyle, { Icon_Style, LaneStyle, StopLineStyle } from "@/common/layer/layer-style-data/Default"

export default {
    'boundary': BoundaryStyle,
    'connectivity_area': defaultStyle,
    'lane': LaneStyle,
    'lane_group': defaultStyle,
    'stop_line': StopLineStyle,
    'road_mark': defaultStyle,
    'traffic_light': Icon_Style,
    'traffic_sign': TrafficSignStyle,
    'default': defaultStyle,
    'pole': Icon_Style,
    'obstacle': Icon_Style,

    
    // 1001: BoundaryStyle,
    // 1002: LaneStyle,
    // 1004: StopLineStyle,
    // 'traffic_light': Icon_Style,


    // 'lane_group': defaultStyle,
    // 'road_mark': defaultStyle,
    // 'traffic_sign': Icon_Style,
    // 'default': defaultStyle,
    // 'pole': Icon_Style,
    // 'obstacle': Icon_Style,


    // 1008: defaultStyle,

}
