
import { Vue, Component } from "vue-property-decorator";
import { layerTitleMap } from "@/config/layerConfig";
import { batchEditFeaturePropertyApi } from "@/config/api"

@Component
export default class PosPopup extends Vue {
  layerType: any = null
  visible: boolean = false
  currentLayerInfo: any = null
  confirmLoading: boolean = false
  layerTitleMap: any = null
  attributeList: any[] = [];
  originAttrList: any[] = [];
  featureIdList: any[] = [];

  valueList: any = null;
  disAttr: string [] = ['leftBoundaryId', 'rightBoundaryId']
  formData: any = {
    "column": "",
    "columnName": "",
    "columnValue": "",
    "columnName2": "",
    "columnValue2": ""
  }

  isInput: boolean = false
  currentKey: string = ''
  title: string = ''

  get isAssociation() {
    return [1005].includes(this.layerType)
  }

  // 切换属性值
  attributeChange(value: any) {
    const currentValue: any = JSON.parse(value);
    const key: string = currentValue.key;
    const type: string = currentValue.type;
    this.valueList = null;
    if (['select', 'multiSelect'].includes(type)) {
      this.isInput = false;
      this.currentKey = type == 'multiSelect' ? 'multiple' : '';
      this.formData.columnName = currentValue.key

      if (this.layerType == 1005) {
        this.valueList = this.originAttrList.find((item: any) => item.relationSelect == currentValue.value)?.enums
      } else {
        this.valueList = this.attributeList.find((item: any) => item.key == key)?.enums;
      }

      this.formData.columnValue = Object.keys(this.valueList)[0];
    } else {
      this.isInput = true;
      this.formData.columnValue = currentValue.default;
      
      if (this.isAssociation && currentValue.default !== 0) {
        this.isInput = false;
      }
    }

    if (this.isAssociation) {
      this.formData.columnName2 = 'type'
      this.formData.columnValue2 = currentValue.value; //当前的值
    }
    return value
  }

 
  show(layerInfo: any, featureIds: string[]) {
    this.featureIdList = featureIds
    this.layerType = layerInfo.type; // number 1001
    
    this.visible = true
    this.title = layerInfo.title;
    
    let attr: any = layerTitleMap[layerInfo?.title].attribute;
    let newAttr = attr.filter((item: any) => !item.readOnly);
    this.originAttrList = newAttr;

    if (this.layerType == 1005) {
      const options: any = newAttr[0].enums;
      let arr: any = [];
      for (const key in options) {
        let type: any = '';
        let defaultValue: any = '';

        if (key == 'directional_arrow') {
          type = 'multiSelect'
        }

        if (key == 'speed_hump') {
          type = 'select'
        }

        if (['maximum_speed_limit_sign', 'minimum_speed_limit_sign', 'keep_safe_distance_sign'].includes(key)) {
          type = 'input'
          defaultValue = 0
        }
        
        arr.push({
          key: 'content',
          value: key,
          name: options[key],
          type: type,
          default: defaultValue
        })
      }
      this.attributeList = arr;
    } else {
      this.attributeList = newAttr;
    }
    const firstData: any = this.attributeList[0];
    const currentColumn: any = JSON.stringify(firstData);
    
    this.formData = {
      column: currentColumn,
      columnName: firstData.key,
      columnValue: '',
      columnName2: '',
      columnValue2: ''
    }
    
    this.attributeChange(currentColumn);
  }

  async handleOk() {
    let columnValue = this.formData.columnValue;
    if (Array.isArray(this.formData.columnValue)) {
      columnValue = JSON.stringify(this.formData.columnValue);
    }
    const params: any = {
      featureType: this.layerType,
      columnName: this.formData.columnName,
      columnValue: columnValue,
      featureIdList: this.featureIdList,
    }

    if (this.isAssociation) {
      params.columnName2 = this.formData.columnName
      params.columnValue2 = columnValue
      params.columnName = this.formData.columnName2
      params.columnValue = this.formData.columnValue2
    }
    this.confirmLoading = true;  
    
    await batchEditFeaturePropertyApi(params).then(() => {
      this.handleCancel();
      this.$message.success('修改成功');
      this.$emit('refreshMap');
    }).finally(() => {
      this.confirmLoading = false;
    })
  }

  handleCancel() {
    this.visible = false
    this.attributeList = []
    this.valueList = []
    this.confirmLoading = false;
  }

}
