class PointCloudTile {
    // 生成变量
    private readonly _title!: string;
    private readonly _key!: string;
    private readonly _selected: boolean = false;
    private readonly _readOnly: boolean = true;

    constructor(level: number, name: string = '点云图层') {
        this._title = name + ' ' + level
        this._key = this._title
    }

    get title () {
        return this._title
    }

    get key () {
        return this._key
    }

    get name () {
        return this._key
    }

    get selected() {
        return this._selected
    }

    get readOnly() {
        return this._readOnly
    }
}

const Tile_Layers = (zlevel: number, name?: string) => {
    const layerKeys = [...Array(zlevel).keys()];
    const layers= layerKeys.map((level: number) => new PointCloudTile(level, name))
    return layers
}

const Tile_layers_name = (zlevel: number, name: string = '点云图层') => {
    const layerKeys = [...Array(zlevel).keys()]
    const layerNames = layerKeys.map((level: number) => name + " " + level)
    return layerNames
}

export {
    Tile_Layers,
    Tile_layers_name
}