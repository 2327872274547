import CopyPasteBase from "ol-ext/interaction/CopyPaste";
import { Map as olMap, Feature, MapBrowserEvent } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { createEditingStyle } from "ol/style/Style";
import RenderFeature from "ol/render/Feature";


function getDefaultStyleFn() {
    const styles = createEditingStyle();
    return function (feature: Feature<any> | RenderFeature) {
        return styles[feature.getGeometry().getType()];
    }
}

export default class CopyPaste extends CopyPasteBase {
    private readonly _filter: (feature: Feature<any>) => true;
    private readonly _overlayLayer: VectorLayer<VectorSource<any>>;

    private _copySource: VectorSource<any> | undefined;

    protected currentCoordinate: number[] = [];
    protected lastCoordinate: number[] = [];

    private get anyThis(): any {
        return this;
    }

    constructor(options: any) {
        options = options || {};
        options.mapCondition = (mapBrowserEvent: MapBrowserEvent<any>) => {
            if (mapBrowserEvent.type == 'pointermove') {
                this.handleMoveEvent(mapBrowserEvent);
            }
            if (mapBrowserEvent.type == 'keydown' && mapBrowserEvent.originalEvent.key == 'Escape') {
                this.copyClear();
            }
            return true;
        }
        
        super(options);
        this._filter = options.filter || (() => true);
        this._overlayLayer = new VectorLayer({
            source: new VectorSource({
                useSpatialIndex: false
            }),
            style: options.style ? options.style : getDefaultStyleFn()
        })
    }

    public setMap(map: olMap): void {
        if (this.getMap()) {
            this.getMap()?.removeLayer(this._overlayLayer);
        }
        super.setMap(map);
        this._overlayLayer.setMap(map);
    }

    protected handleMoveEvent(e: MapBrowserEvent<any>): void {
        this.lastCoordinate = this.currentCoordinate;
        this.currentCoordinate = e.coordinate;
        this.translateFeatures(this.anyThis.features);
    }

    private translateFeatures(features: Feature<any>[]) {
        features.forEach(feature => {
            const deltaX = this.currentCoordinate[0] - this.lastCoordinate[0];
            const deltaY = this.currentCoordinate[1] - this.lastCoordinate[1];
            const geometry = feature.getGeometry();

            geometry.translate(deltaX, deltaY);
        })
    }

    public copyClear() {
        this.anyThis.features.length = 0;
        this._overlayLayer.getSource()?.clear();
        this.getCurrentMap().getTargetElement().style.cursor = '';
    }

    public copy(options: any) {
        options = options || {};
        const features = options.features || this.anyThis._featuresSource.getArray();
        if (!features || features.length == 0) {
            return;
        }
        const source = this._overlayLayer.getSource();
        super.copy(options);
        if (this.anyThis.features.length > 0) {
            source?.clear();
            this.anyThis.features.forEach((f: Feature<any>) => f.setStyle(undefined));
            source?.addFeatures(this.anyThis.features);
            this.getCurrentMap().getTargetElement().style.cursor = 'copy';
        }
        this._copySource = this.anyThis._destination;
    }

    public paste(options: any) {
        if (this._copySource !== this.anyThis._destination) {
            this.copyClear();
        } else {
            super.paste(options);
        }
    }
}