
import { Feature } from "ol";
import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import layerDefaultAttr from "@/config/layerDefaultAttr";
import { layerTypeMap } from "@/config/layerConfig";
import { FormModel } from "ant-design-vue";
import MovableViewModal from "@/components/MovableView/MovableViewModal.vue"

@Component({
    components: { MovableViewModal }
})
export default class FeatureMoveModalComponent extends Vue {
    @Prop() zLevelVal?: any;
    @Prop() selectedMapVecter?: any;
    @Prop() propKey?: any

    visible: boolean = false;
    featureProperties: any[] = [];

    properties: any = null;
    feature: any = null;
    layerType: any = null;
    layerDefaultAttr: any = null;

    @Watch('selectedMapVecter')
    change(feature: any) {
        if (this.visible) {
            let id = feature?.get('id');
            if (id) {
                this.properties[this.propKey] = id
            }
        }

    }


    showModal(feature: Feature<any>, layer: any) {
        this.layerType = layer.type;
        this.feature = feature;

        this.$nextTick(() => {
            this.properties = layerDefaultAttr[layer.title];

            this.initLane();

            this.featureProperties = layerTypeMap[this.layerType].attribute || [];

            if (this.featureProperties.length)  {
                this.visible = true;

                if (this.layerType == 1005) {
                    this.selectChange('type', this.properties['type'])
                }
            }
        })
    }

    selectChange(key: any, value: any) {
        if (key == 'type') {
            this.properties['content'] = ""
            this.featureProperties.forEach((item: any) => {
                if (item.key == 'content') {
                    if (item.relationSelect == value) {
                        item.isHide = false
                        this.properties['content'] = item.default
                    } else {
                        item.isHide = true
                    }
                }
            })
        }
    }

    initLane() {
        if (this.layerType == 1002) {
            this.properties['leftBoundaryId'] = undefined;
            this.properties['rightBoundaryId'] = undefined;
        }
    }

    validateFields() {
        let isError = false
        if (this.layerType == 1002) {
            if (this.properties['leftBoundaryId'] && this.properties['rightBoundaryId']) {
                if (this.properties['leftBoundaryId'] == this.properties['rightBoundaryId']) {
                    this.$message.warn('左右车道边界不能相同，请重新选择')
                    isError = true
                    return true
                }
            }
        }

        if (this.layerType == 1005) {
            if (this.properties['content'] == 'undefined' || this.properties['content'] == undefined) {
                this.properties['content'] = ""
            }
        }
        return isError;
    }

    handleOk() {
        if (!this.featureProperties.length) {
            return;
        }

        let isError = this.validateFields();
        if (isError) return;

        (this.$refs.formRef as FormModel).validate((boolean: any, object: any) => {
            if (boolean) {
                this.properties.layerType = this.layerType;
                this.properties.zlevel = this.zLevelVal;
                this.feature.setProperties(this.properties)
                this.feature = null;
                this.visible = false;
                this.$emit('drawActiveChange')
                if (this.layerType == 1002) {
                    this.$emit('removeSelectMap')
                }
            } else {
                return false;
            }
        })
    }

    @Emit("cancel")
    handleCancel() {
        this.visible = false;
        if (this.layerType == 1002) {
            this.$emit('removeSelectMap')
        }
        this.$emit('drawActiveChange');
        return this.feature;
    }

    // 地图选择
    selectedMap(layerKey: string, propKey: any) {
        this.$emit('selectedMap', layerKey, propKey);
    }
}
