
import {Component, Vue, Watch, Prop, InjectReactive} from "vue-property-decorator";
import {eventBus} from '@/utils/eventBus';
import {Polygon} from "ol/geom";
import {transform3857To4326,transform4326To3857} from "@/common/utils/projetion";
import {layersAerial} from "@/config/layerConfig"

@Component
export default class CollapseList extends Vue {
  indeterminate: boolean = false;
  checkAll: boolean = false;
  coordList: any = []
  zVal: number = 0
  disCorrectZ: boolean = true
  coordsListData: any[] = []
  correctZDis: boolean = false
  layersAerialArr: number[]  = layersAerial

  @InjectReactive() selectedCoordinatesInfo!: any[]
  @InjectReactive() zValue!: number

  @Watch("coordList")
  featureCodesChange(val: string[]) {
    this.indeterminate = !!val.length && val.length < this.coordsListData.length;
    this.checkAll = val.length === this.coordsListData.length;
  }

  @Watch('zValue', { deep: true, immediate: true })
  zValueChange(newVal:number){
    this.zVal = newVal
  }


  @Watch('selectedCoordinatesInfo', { immediate: true })
  dataChange(newVal: any) {
    this.resetData()
    this.coordsListData = newVal;
    let arr: any[] = [];
    this.coordsListData?.forEach((item: any) => {
      if (item.coord[2] == undefined) {
        item.coord[2] = 0
      }
      item.coord = transform3857To4326(item.coord);
      arr.push(item)
    })

    this.coordsListData = arr.sort((a: any, b: any) => {
      if (a.coord[0] === b.coord[0]) {
        return a.coord[1] - b.coord[1]
      }
      return a.coord[0] - b.coord[0]
    });
  }

  onCheckAllChange(e: any) {
    let seleArr = this.coordsListData.filter((item: any) => !layersAerial.includes(item.feature.get('layerType')))
    if (!seleArr.length) {
      this.$message.warn('当前所选顶点要素为空中要素，不可修改Z值')
    }
    this.coordList = e.target.checked ? seleArr : [];
    this.indeterminate = false;
  }
  correctZ(){
    if (this.coordList.length) {
      this.disCorrectZ = false
      eventBus.$emit('correctZ', false)
    } else {
      this.$message.warning('请先勾选要修改的坐标')
    }

  }
  okZvalue(){
    let data: any[] = []
    this.coordList.forEach((item: any, index: number) => {
      item.coord[2] = this.zVal
      let coord3857: number[] = transform4326To3857(item.coord)
      let oldCoords: any[] = []
      if (item.feature.getGeometry() instanceof Polygon) {
        oldCoords = item.feature.getGeometry().getCoordinates()[0]
      } else {
        oldCoords = item.feature.getGeometry().getCoordinates()
      }
      let newCoords: any = []
      oldCoords.forEach((coord: any) => {
        if (coord[0] == coord3857[0] && coord[1] == coord3857[1]) {
          newCoords.push(coord3857)
        } else {
          newCoords.push(coord)
        }
      })
      let obj: any = {feature: item.feature, oldCoords, newCoords}
      data.push(obj)
    })
    this.resetData()
    eventBus.$emit('correctZ',true,data)
  }

  // 重置数据
  resetData() {
    this.zVal = 0
    this.disCorrectZ = true
    this.coordList = []
  }


}
