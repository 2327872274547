
import { Vue, Component, ProvideReactive } from "vue-property-decorator";
import { Route } from "vue-router";
import HeaderTool from "@/views/headerTool/index.vue";
import Menus from "@/views/menus/Menu.vue"
import AttributeList from "@/views/attributeList/index.vue";
import {layers, layerTypeMap} from "@/config/layerConfig";
import MapServices from "@/views/olMap/service/InternalService";
import { Collection, Feature } from "ol";
import { isAnyEqual } from "@/common/utils/CommonUtil";
import PosPopup from "@/components/popup/posPopup.vue";
import BachPopup from '@/components/popup/bachPopup.vue'
import LightGroupPop from '@/components/popup/lightGroupPop.vue'
import TagPoop from '@/components/popup/tagPop.vue'
import LineOffset from "@/components/LineOffset/LineOffset.vue";

import { writeFeatureObject } from "@/common/utils/projetion";
import { setState, state} from "@/store/index";
import {
    apiTileList,
    checkList,
    saveFeature,
    saveLightGroup,
    getTagList,
    autoImportFeature,
    hasAutoImportFeature,
} from "@/config/api";
import { message } from "@/config/message";

import CreateFeatureModal from "@/components/CreateFeature/FeatureModal.vue";
import CreateFeatureMoveModal from "@/components/CreateFeature/FeatureMoveModal.vue";


import CollapseList from "@/views/attributeList/components/CollapseList.vue";
import { isEmpty } from "ol/obj";
import {eventBus} from '@/utils/eventBus';
import { menus as headerToolData } from "@/config/HeaderToolConfig"
import {center, point} from '@turf/turf'
import {featureCollection} from '@turf/helpers'
import {getCenter} from "ol/extent";
import {toLonLat} from "ol/proj";
import {unByKey} from "ol/Observable";
import LocalCoordinate from "@/views/home/components/localCoordinate.vue"
import DataDmportPop from "@/views/headerTool/components/DataDmportPop.vue";

let MAP_SERVICE: MapServices;

@Component({
    components: {
        PosPopup,
        HeaderTool,
        AttributeList,
        LineOffset,
        CreateFeatureModal,
        CreateFeatureMoveModal,
        CollapseList,
        BachPopup,
        TagPoop,
        LightGroupPop,
        LocalCoordinate,
        DataDmportPop,
        Menus
    },
})
export default class Home extends Vue {
    $route!: Route; // 添加 $route 的类型声明

    selectMenuItem: any = null;
    mapZoomLevel: number = 0;
    localCoordinate: any = [];
    selected: string = "Drag";
    selectedTool: any = null;
    selectedFeatures: any[] = [];
    permissonDelete: boolean = false; // 确认当前要素是否是编辑模式下
    vertexStatus: boolean = true; // 确认选中顶点是否在编辑模式下
    isShowPosPop: Boolean = false;
    selectedPos: any = [];
    selectedPosFeas: any = [];
    zLevel: number = 0; // 点云图层
    selectedMapVecter: any = null
    headerToolData: any = headerToolData
    propKey: any = null
    isShowTagList: boolean = false
    mapSpinning: boolean = false
    loading: boolean = false

    $refs!: {
        AttributeRef: AttributeList;
        LineOffsetRef: LineOffset;
        PosPopup: PosPopup;
        CreateFeatureModalRef: CreateFeatureModal;
        CreateFeatureMoveModalRef: CreateFeatureMoveModal;
        HeaderToolRef: HeaderTool
        BachPopup: BachPopup,
        TagPoop: TagPoop,
        LightGroupRef: LightGroupPop
        DataDmportPop: DataDmportPop
        MenuRef: Menus
    };

    @ProvideReactive() tagListData: any[] = []
    @ProvideReactive() selectedCoordinatesInfo: any[] = []
    @ProvideReactive() zValue: number = 0
    @ProvideReactive() confirmLoading: boolean = false
    @ProvideReactive() checkListData: any[] = []
    @ProvideReactive() selectedAssociationFeature: any = null

    // 获取头部数据
    get headerToolType() {
        if (this.selectMenuItem) {
            return this.selectMenuItem.title;
        }
        return null;
    }

    get isAnyEqual() {
        let select = [
            "SelectOnlyFeature",
            "SelectAllFeature",
            "SelectOnlyPoint",
            "SelectAllPoint",
        ];
        return isAnyEqual(this.selected, ...select);
    }
    mounted() {
        // 获取taskID
        setState("taskId", this.$route.query.taskId);
        setState("zlevel", Number(this.$route.query.zlevel) || 2);
        // setState("taskId",  10001);
        this.initMap();
        this.addEventListener(); // 键盘监听
        // 初始化
        this.$nextTick(() => {
            this.$refs.MenuRef.initMenuList();
        })

        eventBus.$on('onFeatureChange', this.onFeatureChange);
        eventBus.$on('onDelFeatureSelectd', this.onDelFeatureSelectd);
        eventBus.$on('featureHeightById', this.featureHeightById);
        eventBus.$on('updateTag', this.updateTag);
        eventBus.$on('lightGroupName', this.lightGroupName);
        eventBus.$on('highLightGroup', this.highLightGroup);
        eventBus.$on('correctZ', this.correctZ);
    }
    beforeDestroy() {
      eventBus.$off('onFeatureChange', this.onFeatureChange);
      eventBus.$off('onDelFeatureSelectd', this.onDelFeatureSelectd);
      eventBus.$off('featureHeightById', this.featureHeightById);
      eventBus.$off('updateTag', this.updateTag);
      eventBus.$off('lightGroupName', this.lightGroupName);
      eventBus.$off('highLightGroup', this.highLightGroup);
      eventBus.$off('correctZ', this.correctZ);
    }

    autoImport(){
        hasAutoImportFeature(state.taskId).then((res: any) => {
            if(res === false){
                autoImportFeature(state.taskId).then(res => {
                    this.refreshLayer();
                })
            }
        })
    }


    // 初始化地图
    initMap() {
        MAP_SERVICE = new MapServices({
            target: "map",
        });
        this.localCoordinate = MAP_SERVICE.olMap.getView().getCenter();
        this.mapZoomLevel = MAP_SERVICE.olMap.getView().getZoom();

        // 缓加载
        MAP_SERVICE.on('loading', (val: boolean) => {
            this.loading = val;
        })
        // 获取 tileList
        this.getTileList();
        //map添加pos点图层
        MAP_SERVICE.appendPosLayer();
        // map添加图层
        MAP_SERVICE.appendVectorLayers();
        //map添加标签图层
        MAP_SERVICE.appendTagLayer()
        // 自动导入
        this.autoImport();

        //获取点击pos点要素信息
        // 获取鼠标坐标点、缩放等级
        MAP_SERVICE.on("posSelChange", (feature: any, features: any) => {
            this.isShowPosPop = true;
            this.selectedPos = feature;
            this.selectedPosFeas = features;
        });

        // 获取鼠标坐标点
        MAP_SERVICE.on("positionChanged", (localCoordinate: any) => {
           this.localCoordinate = localCoordinate;
        });

        // 获取鼠标坐标点
        MAP_SERVICE.on("zoomLevelChanged", (mapZoomLevel: any) => {
            this.mapZoomLevel = mapZoomLevel;
        });

        // 绘画结束
        MAP_SERVICE.on("drawEnd", (feature: any) => {
            if ([1002].includes(feature.get('layerType'))) {
                (this as any).$refs.CreateFeatureMoveModalRef.showModal(feature, this.selectMenuItem);
            } else {
                (this as any).$refs.CreateFeatureModalRef.showModal(feature, this.selectMenuItem);
            }
            MAP_SERVICE.drag();
        });

        MAP_SERVICE.on("drawTagEnd", (feature: any) => {
          (this as any).$refs.TagPoop.show(feature)
        });

        // 选择监听
        MAP_SERVICE.on("onSelect", (features: Collection<Feature<any>>) => {
            this.selectedFeatures = [];
            features.forEach((f) => {
                console.log(f, '选中数据');
                console.log(writeFeatureObject(f), '选中数据');

                this.selectedFeatures.push(f)
            });
        });

        // 选中要素行点
        MAP_SERVICE.on(
            "onselectVertices",
            (selectVercesList: Feature<any>[]) => {
              this.selectedCoordinatesInfo = selectVercesList
              this.$refs.AttributeRef.isShowCoordinateInfo = selectVercesList.length ? true : false
            }
        );
        // 关联 - select
        MAP_SERVICE.on("selectMapFea",(selectVerFea: Feature<any>) => {
            if (selectVerFea) {
                this.selectedAssociationFeature = selectVerFea.get('id');
                this.selectedMapVecter = selectVerFea;
            }
        });

        MAP_SERVICE.on("onChangeSelectFeature", (features: Feature<any>[]) => {
            this.selectedFeatures = features;
        });

        // 偏移 - 测距
        MAP_SERVICE.on("MeasureLineChange", (value: any) => {
            (this as any).$refs.LineOffsetRef.offsetVal = value;
        });

        // 失败提示
        MAP_SERVICE.on("error", (message: any) => {
            if (message) {
                this.$message.warn(message);
            }
        });

        // 成功提示
        MAP_SERVICE.on("success", (message: any) => {
            if (message) {
                this.$message.success(message);
            }
        });
    //    z值变化
      MAP_SERVICE.on('zValueChange',(value:number)=>{
        this.zValue = value
      });
    }

    headerToolRefresh() {
        // headerTool重置
        const drag: any = this.headerToolData['Drag'];
        (this as any).$refs.HeaderToolRef.onSelectedIcon(drag);
    }

    // 开启关闭
    drawActiveChange() {
        this.onBarButtonClick(this.selectedTool);
    }

    // 关闭添加要素弹出框
    closeAddFeatureModal() {
        if ((this as any).$refs.CreateFeatureModalRef.visible) {
            (this as any).$refs.CreateFeatureModalRef.handleCancel()
        }
        if ((this as any).$refs.CreateFeatureMoveModalRef.visible) {
            (this as any).$refs.CreateFeatureMoveModalRef.handleCancel()
        }
    }

    // 关闭偏移弹出框
    closeLineOffsetModal() {
        if ((this as any).$refs.LineOffsetRef.visible) {
            (this as any).$refs.LineOffsetRef.handleCancel();
        }
    }

    // 切换 layer
    onSelectLayer(layer: any) {
        if (layer) {
            this.selectMenuItem = layer;
            MAP_SERVICE.switchCurrentSource(layer.name);
            MAP_SERVICE.toolUpdateSource();
        }

        this.closeLineOffsetModal();
    }

    // 操作 - layer - visible
    onLayerVisibleKeys(layerKeys: string[]) {
        if (MAP_SERVICE) {
            MAP_SERVICE.setLayerVisible(layerKeys);
            this.zLevel = state.maxZLevel
        }
    }


    // 选中
    onBarButtonClick(toolItem: any) {
        console.log("使用工具:", toolItem.name);

        MAP_SERVICE.selectedToolName = toolItem.name;

        if (
            ![
                "SimplifyElement",
                "DeletedFeature",
                "DeletedPoint",
                "Flip",
                "Merge",
                "GenerateLine",
                "GenerateLineSequence",
                "GenerateLineGlobal",
                "GenerateLineRelationship",
                "GenerateGroundSignageRelationship",
                "GenerateTrafficLightRelationship",
                "GenerateConnectedRegionRelationship",
                "Sorption",
                "Batch",
                "DataExport",
                "DataDmport",
                "UnicomInspection",
                "AerialElements",
            ].includes(toolItem.name)
        ) {
            MAP_SERVICE.clearInteraction(); // 清除指定interaction
        }

        if (toolItem.function) {
            // layers 要素图层数据
            toolItem.function(this, MAP_SERVICE, toolItem, layers);
        }

        if (toolItem.needSelected) {
            this.selected = toolItem.name;
            this.selectedTool = toolItem;
        }

        this.closeAddFeatureModal();

        if (!['Offset', 'DataExport', 'DataDmport'].includes(toolItem.name)) {
            this.closeLineOffsetModal();
        }
    }

    // 选中要素是否在编辑
    onFeaturefocusChange(isEdit: boolean) {
        this.permissonDelete = isEdit;
    }

    // 上一步
    undo() {
        MAP_SERVICE.undo();
        this.closeAddFeatureModal();
    }

    // 下一步
    redo() {
        MAP_SERVICE.redo();
        this.closeAddFeatureModal();
    }

    // 键盘监听
    addEventListener() {
        this.$nextTick(() => {
            document.addEventListener("keyup", (e: any) => {
                if (e.code == "Space" && this.selectedTool && !['AddLabel'].includes(this.selectedTool?.name)) {
                    this.onBarButtonClick(this.selectedTool);
                }
                if (
                    e.key.toLowerCase() == "delete" &&
                    this.isAnyEqual &&
                    !this.permissonDelete
                ) {
                    MAP_SERVICE.deleteSelectedFeatures();
                    // 删除顶点
                    if (this.vertexStatus) {
                        MAP_SERVICE.deleteSelectedPoint();
                    }
                    // 删除选中状态
                    MAP_SERVICE.selectTool?.clearFeatures();
                    e.preventDefault();
                }
            });

            document.addEventListener("keydown", (e: any) => {
                if (e.code == "Space" && this.selected !== "Drag"  && !['AddLabel'].includes(this.selectedTool?.name)) {
                    MAP_SERVICE.drag();
                    e.preventDefault();
                }
                if (e.ctrlKey && e.key.toLowerCase() == "s") {
                    this.saveData();
                    e.preventDefault();
                }
                if (e.ctrlKey && e.key.toLowerCase() == "z") {
                    MAP_SERVICE.undo();
                    e.preventDefault();
                }
                if (e.ctrlKey && e.key.toLowerCase() == "r") {
                    MAP_SERVICE.redo();
                    e.preventDefault();
                }
            });
            document.oncontextmenu = () => false;
        });
    }

    /****************** LineOffset 偏移 START  *********************** */
    // 打开偏移弹出框
    openLineOffset() {
        (this as any).$refs.LineOffsetRef.showModal();
        MAP_SERVICE.select(true);
        MAP_SERVICE.initDragBoxTool();
    }
    // 确认偏移
    onOffsetOk(direction: boolean, value: number) {
        MAP_SERVICE.initLineOffset(direction, value);
    }
    // 启动测距
    addLineMeasure() {
        MAP_SERVICE.initMeasureDistance("length", true);
    }
    // 关闭弹出框
    closeLineOffset(isSelfClosing: boolean) {
        MAP_SERVICE.clearMeasureLine();
        if (isSelfClosing) {
            this.headerToolRefresh();
        } else {
            this.onBarButtonClick(this.selectedTool);
        }
    }
    /****************** LineOffset 偏移 END  *********************** */
    // 对特殊字段进行处理
    saveArrToStr(data: any){
      // 对多选字段进行转字符串处理
      let properties : any = data['properties'];

      // boundary lane
      if(properties['layerType'] == "1001" || properties['layerType'] == "1002"){
        if(typeof properties["objectType"] != 'string'){
          properties["objectType"] = JSON.stringify(properties["objectType"])
        }
      }
      // roadmark
      if(properties['layerType'] == "1005"){
          if(typeof properties["content"] != 'string'){
            properties["content"] = JSON.stringify(properties["content"])
          }
      }
      // 删除多余字段
      delete properties["tag"];
    }


    // 提交数据过滤无用字段
    filterData(fea:any, type:string){
        // 获取每个图层对应的属性
        let layerKeys : any = new Map;
        layerTypeMap.forEach((item:any, index:any) =>{
          layerKeys[index] = ['taskId']; // 默认要保留的属性
          let attribute = item["attribute"];
          attribute.forEach((item1:any, index1:any)=>{
            layerKeys[index].push(item1['key']);
          })
        })

        // 删除不需要的属性
        let keys:string[] = layerKeys[fea.properties['layerType']];
        for (const key of Object.keys(fea.properties)) {
          // 添加时只保留必要属性
          if(type == "add" && !keys.includes(key)){
            delete fea.properties[key];
          }
          // 编辑时保留必要属性  同时要保留id
          if(type == "edit" && !keys.includes(key) && (!key.endsWith("id") && !key.endsWith("Id") )){
            delete fea.properties[key];
          }
        }
        return fea;
    }

    // 最后的确认
    async saveData() {
        const MAPDATA =
            MAP_SERVICE.undoTool?.getIncrementFeatures() ||
            new Map<number, any>();
        let data: any = {};
        for (let [key, value] of MAPDATA) {
            if (data[key]) {
                data[key] = Object.assign({});
            } else {
                let arr: any = [];
                if (value.add.size > 0) {
                    value.add.forEach((item: any) => {
                        let fea :any = writeFeatureObject(item);
                        fea.properties!["taskId"] = state.taskId;
                        this.filterData(fea,"add")
                        this.saveArrToStr(fea);
                        arr.push(fea);
                    });
                    value.add = { features: arr };
                } else {
                    value.add = {};
                }
                let arr2: any = [];
                if (value.edit.size > 0) {
                    value.edit.forEach((item: any) => {
                        let fea : any = writeFeatureObject(item);
                        if (item.get("id")) {
                            this.saveArrToStr(fea);
                            this.filterData(fea,"edit")
                            arr2.push(fea);
                        } else {
                            if (!value.add.features) {
                                value.add.features = [];
                            }
                            fea.properties!["taskId"] = state.taskId;
                            this.saveArrToStr(fea);
                            this.filterData(fea,"add")
                            value.add.features.push(fea);
                        }
                    });
                    if (arr2.length > 0) {
                        value.edit = { features: arr2 };
                    } else {
                        value.edit = {};
                    }
                } else {
                    value.edit = {};
                }

                let arr3: any = [];
                if (value.del.size > 0) {
                    value.del.forEach((item: any) => {
                        arr3.push(item.get("id"));
                    });
                    value.del = arr3;
                } else {
                    value.del = [];
                }

                data[key] = Object.assign({
                    add: value.add,
                    edit: value.edit,
                    del: [...new Set(value.del)],
                });
            }
        }
        // console.log(data, '最后结果');

        if (Object.keys(data).length > 0) {
            await saveFeature(data);
            this.$message.success(message.saveSuccess);
            this.refreshMap();
        }
    }

    refreshMap() {
        this.refreshLayer();
        MAP_SERVICE.selectTool?.clearFeatures();
        this.onBarButtonClick(this.selectedTool);
    }

    refreshLayer() {
        MAP_SERVICE.undoTool?.clearIncrementFeatures();
        MAP_SERVICE.refreshFeatures(layers);
    }

    closePop(isShow: Boolean) {
        this.isShowPosPop = isShow;
    }

    currentViewFea(coordinate: []) {
        MAP_SERVICE.isPointInView(coordinate);
    }
    AerialFeature(AerialFeature:number[][]){
      MAP_SERVICE.addTrafficFeature(AerialFeature,this.selectMenuItem)
    }

    async getTileList() {
        await apiTileList().then((res: any) => {
            if (!isEmpty(res)) {
                setState("tilesList", res);
            }
        }).finally(() => {
            MAP_SERVICE.addPointCloudLayer()
        })
    }

    // 绘画取消
    drawCancel(feature: any) {
        MAP_SERVICE.deleteFeature(feature);
    }

    // 地图选择
    selectedMap(layerKey?: any, propKey?: any) {
      this.propKey = propKey;
      MAP_SERVICE.drag();
      MAP_SERVICE.selectMap(layerKey);
    }

    // 清除地图选择
    removeSelectMap() {
      MAP_SERVICE.removeSelectMap();
      this.onBarButtonClick(this.selectedTool)
    }

    // 改变要素的属性
    onFeatureChange(feature:any, properties: any) {
        MAP_SERVICE.fillAttr.fill([feature], properties);
    }
    // 删除要素的选中状态
    onDelFeatureSelectd(data:any){
       MAP_SERVICE.selectTool?.removeFeature(data);
    }
    // 高亮选择要素
    featureHeightById(data: any) {
      MAP_SERVICE.featureHeightById(data)
    }

    // 批量
  bach() {
    if (!this.selectedFeatures.length) {
      this.$message.warning('请选择要素')
      return
    }

    let isSameLayer = this.selectedFeatures.find((item: any) => item.get('layerType') !== this.selectMenuItem?.type)
    if (isSameLayer) {
      this.$message.warning('请选择当前图层的要素')
      return
    }

    const featureIds: any[] = [];
    let isNotSave: boolean = true;
    this.selectedFeatures?.forEach((feature: any) => {
        if (feature.get('id')) {
            featureIds.push(feature.get('id'))
        } else {
            isNotSave = false;
        }
    });

    if (featureIds.length > 0 && isNotSave) {
        (this as any).$refs.BachPopup.show(this.selectMenuItem, featureIds);
    } else {
        MAP_SERVICE.trigger('error', '请先保存要素，再进行下一步操作');
    }
  }

  // 导出
  dataDmportShow() {
    this.$refs.DataDmportPop.show()
  }
    //取消标签
    cancelTag(feature: any) {
      MAP_SERVICE.deleteFeature(feature)
      MAP_SERVICE.removeTagFeature(feature)
    }

    // 隐藏/查看 标签
    hideOrViewTag(isShowList: boolean, type?: string) {
      MAP_SERVICE.hideOrVisTag(isShowList)
      this.isShowTagList = isShowList
      if (type == 'add') {
        MAP_SERVICE.tag()
      }
      if (isShowList) {
        getTagList().then((res: any) => {
          this.tagListData = res
          MAP_SERVICE.resetTagDataSource(this.tagListData)
        })
      }
    }

  //  删除标签和高亮标签
    updateTag(data: any) {
      MAP_SERVICE.removeOrSelTagFeature(data)
    }

  //  检查
    check(type:string){
      this.mapSpinning = true
      checkList(type).then((res: any) => {
        this.checkListData = res
      }).finally(() => this.mapSpinning = false)
      this.$refs.AttributeRef.isShowCheckList(true)
    }

    //   添加灯组
    addLightGroup() {
      MAP_SERVICE.select(true);
      MAP_SERVICE.initDragBoxTool();
      let isSameLayer = this.selectedFeatures.find((item: any) => item.get('layerType') !== this.selectMenuItem?.type)
      if (!this.selectedFeatures.length || isSameLayer) {
        this.$message.warning('请框选灯组图层的灯组再进行操作！')
        return
      }
      (this as any).$refs.LightGroupRef.visible = true

    }
    //查看灯组
    viewLightGroup(type:boolean) {
      this.$refs.AttributeRef.isShowLightList = type
      if(!type){
        this.highLightGroup()
      }
    }

    lightGroupName(data: any) {
      let coordinates: any = []
      let trafficLightIds: any = []
      this.selectedFeatures?.forEach((item: any) => {
        trafficLightIds.push(item.get('id'))
        let coord = getCenter(item.getGeometry().getExtent())
        coordinates.push(point((toLonLat(coord))))
      })
      let geom = center(featureCollection(coordinates)).geometry
      let params = Object.assign({}, data, {
        geom: JSON.stringify(geom),
        trafficLightIds: JSON.stringify(trafficLightIds),
        taskId: state.taskId
      })
      saveLightGroup(params).then((res: any) => {
        this.$message.success('添加成功')
        this.$refs.AttributeRef.getGroupList()
      }).finally(() => this.confirmLoading = false)
    }

    highLightGroup(item?: any) {
      let layerType = 1006
      let ids = item ? JSON.parse(item.trafficLightIds) : []
      let coordinate = item ? JSON.parse(item.geom).coordinates : []
      MAP_SERVICE.highLightGroup({layerType, ids, coordinate})
    }

    correctZ(type: boolean, data?: any) {
      MAP_SERVICE.selectVerticesTool?.setActive(type)
      if (!type) {
        MAP_SERVICE.correctZvalue()
      } else {
        unByKey(MAP_SERVICE.listenEvent)
        MAP_SERVICE.confirmZ(data)
      }
    }

  LocalChange(val: any) {
    MAP_SERVICE.locationCoord(val);
  }

}
