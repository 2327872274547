import { render, staticRenderFns } from "./posPopup.vue?vue&type=template&id=3281db1d&scoped=true&"
import script from "./posPopup.vue?vue&type=script&lang=ts&"
export * from "./posPopup.vue?vue&type=script&lang=ts&"
import style0 from "./posPopup.vue?vue&type=style&index=0&id=3281db1d&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3281db1d",
  null
  
)

export default component.exports