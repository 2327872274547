import { getLayerType } from "@/config/layerConfig"

export default <Record<string, any>>{
    "boundary": {
        boundaryType: '0',
        color: 'none',
        lineType: 'virtual',
        isolation: 'none',
        laneType: 'virtual',
        objectType: ['all_motor_vehicle'],
        layerType: getLayerType('boundary'),
    },
    "lane": {
        type: 'mark',
        laneType: 'virtual',
        objectType: ['all_motor_vehicle'],
        leftBoundaryId: '',
        rightBoundaryId: '',
        layerType: getLayerType('lane'),
    },
    "connectivity_area": {
        type: 'intersection',
        layerType: getLayerType('connectivity_area')
    },
    "stop_line": {
        lineType: 'solid',
        layerType: getLayerType('stop_line')
    },
    "road_mark": {
        type: 'directional_arrow',
        content: undefined,
        layerType: getLayerType('road_mark')
    },
    "traffic_light": {
        content: 'forward',
        layerType: getLayerType('traffic_light')
    },
    "traffic_sign": {
        type: 'general_caution_sign',
        num: 0,
        layerType: getLayerType('traffic_sign')
    },
    "obstacle": {
        type: 'ground_traffic_light',
        num: 0,
        layerType: getLayerType('obstacle')
    },
    "pole": {
        type: 'gantry',
        num: 0,
        layerType: getLayerType('pole')
    }
}
